import { useQuery } from "@apollo/client"
import { KeyboardArrowDown, KeyboardArrowUp, NavigateBeforeSharp, NavigateNextSharp } from "@mui/icons-material"
import { Box, Button, ButtonGroup, Card, CardContent, CircularProgress, IconButton, Menu, MenuItem, Theme, Typography, styled, useMediaQuery, useTheme } from "@mui/material"
import { AnimatePresence, motion } from "framer-motion"
import * as React from "react"
import { useNavigate } from "react-router-dom"
import { getProperties, getVideoUrls } from "../../../gql/queries"
import { SpaceType } from "../../../types/app-types"
import { SPACE_TYPES } from "../../../yardi-reference/SpaceType"
import BocaStockImg from '../../../assets/images/boca-pricing-stock.jpeg'
import BoyntonStockImg from '../../../assets/images/boynton-pricing-stock.jpeg'
import whiteStarLogo from '../../../assets/images/logo-transparency-06.png'
import StuartStockImg from '../../../assets/images/stuart-pricing-stock.jpeg'
import VistaStockImg from '../../../assets/images/vista-pricing-stock.jpeg'
import WellingtonStockImg from '../../../assets/images/wellington-pricing-stock.jpeg'
import { SplitButton } from "../../common/SplitButton"
import { Watermark } from "../../common/Watermark"
import { PRICING_INFO } from "./pricing-info"
import { Property } from "../../../gql/graphql"
import { aboutDescriptions } from "../../locations/descriptions"

type CardSpaceType = {
  spaceTypeName: string
  spaceTypeId: number
}

type CardsCarouselPropType = {
  spaceTypeId: number
}

export const GoogleReviewPages = [
  {
    name: 'Nexus Boca Raton',
    link: 'https://g.co/kgs/1sDpFsm'
  },
  {
    name: 'Nexus Boynton Beach',
    link: 'https://g.co/kgs/T2qguH3'
  },
  {
    name: 'Nexus Lake Worth',
    link: 'https://g.co/kgs/u2VqPvg'
  },
  {
    name: 'Nexus Stuart',
    link: 'https://g.co/kgs/7v9UEMH'
  },
  {
    name: 'Nexus West Palm Beach',
    link: 'https://g.co/kgs/6wJ8GwL'
  }
]

export const Pricing = () => {
  const [currentSpaceOption, setCurrentSpaceOption] = useState({ spaceTypeId: 4, spaceTypeName: 'Private Office', subtitle: 'Our flexible, fully customizable office rentals are available by the hour, day, or as long as you need.' })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const theme = useTheme() as Theme
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'))

  const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
    width: '100%',
    '& .MuiButtonGroup-outlined': {
      border: 0,
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
      },
    },
  }))
  const Div = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '60px',
    paddingBottom: isMobile ? '150px' : '150px',
    paddingRight: isMobile ? '32px' : '65.5px',
    paddingLeft: isMobile ? '32px' : '65.5px',
    backgroundColor: '#f5f5f5',
    width: '100vw',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  }))
  const RowDiv = styled(Box)(() => ({
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center',
    width: '100%'
  }))

  const handleSpaceOption = (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
    setAnchorEl(event.currentTarget as HTMLElement)
    const dataTestId = parseInt((event.currentTarget as HTMLButtonElement).getAttribute('data-testid') as string)
    const referenceSpaceTypeName = SPACE_TYPES.find(space => space.Id === dataTestId)?.Name as string
    switch (dataTestId) {
      case 3:
        setCurrentSpaceOption({ spaceTypeId: dataTestId, spaceTypeName: referenceSpaceTypeName, subtitle: 'Our flexible, fully customizable office rentals are available by the hour, day, or as long as you need.' })
        break
      case 4:
        setCurrentSpaceOption({ spaceTypeId: dataTestId, spaceTypeName: referenceSpaceTypeName, subtitle: 'Our flexible, fully customizable office rentals are available by the hour, day, or as long as you need.' })
        break
      case 7:
        setCurrentSpaceOption({ spaceTypeId: dataTestId, spaceTypeName: referenceSpaceTypeName, subtitle: 'Our flexible, fully customizable office rentals are available by the hour, day, or as long as you need.' })
        break
      case 9:
        setCurrentSpaceOption({ spaceTypeId: dataTestId, spaceTypeName: referenceSpaceTypeName, subtitle: 'Our flexible, fully customizable office rentals are available by the hour, day, or as long as you need.' })
        break
      default:
        setCurrentSpaceOption({ spaceTypeId: dataTestId, spaceTypeName: referenceSpaceTypeName, subtitle: 'Our flexible, fully customizable office rentals are available by the hour, day, or as long as you need.' })
        break
    }
  }

  const optionsArray = SPACE_TYPES?.reduce((acc: Array<CardSpaceType>, space: SpaceType) => {
    const { Name, Id, Active } = space
    if (!acc && Active) {
      acc = [{ spaceTypeName: Name, spaceTypeId: Id }]
    } else if (Active) {
      acc = [...acc, { spaceTypeName: Name, spaceTypeId: Id }]
    }
    return acc
  }, [])

  const ExternalCardMedia = ({ currentVideo, name, url }) => {
    const [isHovered, setIsHovered] = useState(false)
    const [isVideoLoaded, setIsVideoLoaded] = useState(false)
    const imageRef = useRef<HTMLImageElement | null>(null)
    const videoRef = useRef<HTMLVideoElement | null>(null)

    useEffect(() => {
      if (currentVideo) setIsVideoLoaded(true)
    }, [currentVideo])

    useEffect(() => {
      if (isHovered && isVideoLoaded) {
        (imageRef.current as HTMLImageElement).style.opacity = '0'
        const videoElement = videoRef.current as HTMLVideoElement | null
        if (videoElement) {
          videoElement.style.opacity = '1'
        }
      } else {
        (imageRef.current as HTMLImageElement).style.opacity = '1'
        const videoElement = videoRef.current as HTMLVideoElement | null
        if (videoElement) {
          videoElement.style.opacity = '0'
        }
      }
    }, [isHovered, isVideoLoaded])

    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ width: '100%', height: isMobile ? '125px' : '200px', position: 'relative', overflow: 'hidden' }}
      >
        <AnimatePresence>
          {isVideoLoaded && (
            <motion.video
              ref={videoRef}
              src={currentVideo}
              autoPlay
              loop
              muted
              playsInline
              initial={{ opacity: 0 }}
              animate={{ opacity: isHovered ? 1 : 0 }}
              exit={{ opacity: 0 }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                zIndex: 1
              }}
            />
          )}
        </AnimatePresence>
        <img
          ref={imageRef}
          src={url}
          alt={name}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'opacity 0.4s ease',
            zIndex: 0
          }}
        />
      </div>
    )
  }


  const CardsCarousel = (props: CardsCarouselPropType) => {
    const [currentPage, setCurrentPage] = React.useState(0)
    const [cards, setCards] = React.useState<React.ReactElement[]>([])
    const [direction, setDirection] = React.useState<number>(1)
    const { loading: propertiesLoading, data: properties, error: propertiesError } = useQuery(getProperties)
    const { loading: videoUrlsLoading, data: videoUrlsData, error: videoUrlsError } = useQuery(getVideoUrls)
    const navigate = useNavigate()

    const handleNextPage = () => {
      setDirection(1)
      setCurrentPage((prevPage) => Math.min(prevPage + 1, cards.length - 4))
    }

    const handlePrevPage = () => {
      setDirection(-1)
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))
    }

    useEffect(() => {
      if ((properties?.getProperties as Property[])?.length) {
        const Div = styled(Box)(() => ({
          display: 'flex',
        }))
        const formedCards = (properties?.getProperties as Property[])?.map((curr, index) => {
          const { PropertyName, City, Id } = curr
          const lowercaseCity = (City as string).toLowerCase()
          const { miniDescription } = aboutDescriptions.find(desc => desc.propertyName === PropertyName) as any
          let currentVideo

          const propertyPricingInfo = PRICING_INFO.find(info => info.propertyName === PropertyName) as any
          let pricing = propertyPricingInfo[props.spaceTypeId]

          currentVideo = (videoUrlsData?.getVideoUrls as any[])?.find((video: any) => {
            if (video.key.includes('VISTA') && lowercaseCity === 'west palm beach') return true
            else if (video.key.includes('WELLINGTON') && lowercaseCity === 'lake worth') return true
            else if (video.key.includes('BOCA RATON') && lowercaseCity === '') return true
            else if (video.key.includes('STUART') && lowercaseCity === 'stuart') return true
            else if (video.key.includes('Boynton') && lowercaseCity === 'boynton beach') return true
            else return (video.key as string).toLowerCase().includes(lowercaseCity)
          })?.url

          let stockImg
          switch (PropertyName) {
            case 'Nexus at Boca Raton':
              stockImg = BocaStockImg
              break
            case 'Nexus at Boynton Beach':
              stockImg = BoyntonStockImg
              break
            case 'Nexus at Wellington':
              stockImg = WellingtonStockImg
              break
            case 'Nexus at Stuart':
              stockImg = StuartStockImg
              break
            case 'Nexus at Vista Park':
              stockImg = VistaStockImg
              break
            default:
              stockImg = BocaStockImg
          }

          if (props.spaceTypeId === 7 && City?.includes('Boynton')) return undefined
          else if (props.spaceTypeId === 7 || props.spaceTypeId === 3) pricing = propertyPricingInfo[0]
          const card = (
            <Card
              raised
              key={`carousel-card-${index}`}
              sx={{
                width: isMobile ? '350px' : 'auto',
                borderRadius: '10px',
                position: 'relative',
                borderColor: 'transparent', // Add this line
                height: !isMobile ? '500px' : 'auto'
              }}
            >
              <ExternalCardMedia currentVideo={currentVideo} name={PropertyName} url={stockImg} />
              <CardContent>
                <Div sx={{ flexDirection: 'column' }}>
                  <Typography variant="h6" sx={{ paddingBottom: 1, alignSelf: 'center' }}><strong>{PropertyName}</strong></Typography>
                  <Typography variant="subtitle2" sx={{ lineHeight: 1.25, ...(isMobile && { fontSize: '0.75rem' }) }}>
                    {miniDescription}
                  </Typography>
                  <Typography variant='h6' sx={{ paddingTop: 1, color: theme => theme.palette.primary.light, ...(isMobile && { fontSize: '0.75rem' }) }}>
                    <strong>Starting at {pricing}</strong>
                  </Typography>
                  <Div sx={{ alignItems: 'center', paddingTop: 3.5, justifyContent: 'center', flexDirection: 'row' }}>
                    <SplitButton primary={
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: '22.5px',
                          ...(isMobile && { fontSize: '0.75rem' })
                        }}
                        size={isMobile || isTablet ? 'small' : 'medium'}
                        onClick={() => navigate(`/locations?city=${lowercaseCity}`)}>
                        Learn More
                      </Button>} secondary={
                        <Button id={`book-now-button-${index}`} startIcon={<img src={whiteStarLogo} width={29.25} height={22} alt="Nexus Logo" />}
                          variant="contained" size={isMobile || isTablet ? 'small' : 'medium'}
                          sx={{
                            borderRadius: '22.5px',
                            color: 'white',
                            'span': { display: 'flex', marginBottom: '3px' },
                            ...(isMobile && { fontSize: '0.75rem' })
                          }}
                          onClick={() => window.open(`https://93840cgimg.yardikube.com/kubecc/content/#/app/services/workspacelist?companyid=132&proid=${Id}`, '_self')}
                        >
                          Inquire Now
                        </Button>}
                    />
                  </Div>
                </Div>
              </CardContent>
            </Card>
          )

          return card
        }, []).filter(card => card !== undefined)

        setCards(formedCards)
      }
    }, [props.spaceTypeId, properties])

    const PreviousButton = styled(IconButton)(() => ({
      margin: 5
    }))
    const NextButton = styled(IconButton)(() => ({
      margin: 5
    }))
    const StyledBox = styled(Box)(() => ({
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      height: '100%',
      paddingTop: '50px',
      width: '100%'
    }))

    const variants = {
      initial: {
        zIndex: 0,
        x: direction > 0 ? 75 : -75
      },
      animate: {
        zIndex: 1,
        x: 0
      },
      exit: {
        zIndex: 0,
        x: direction < 0 ? 75 : -75
      }
    }

    const isLoading = propertiesLoading || videoUrlsLoading

    return (
      <>
        {isLoading && <CircularProgress color="primary" />}
        {!!cards.length && !isLoading && (
          <StyledBox>
            <PreviousButton disabled={currentPage === 0} onClick={handlePrevPage} sx={{ margin: 0 }}>
              <NavigateBeforeSharp />
            </PreviousButton>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', gap: 2, paddingBottom: 3 }}>
              <AnimatePresence>
                {cards.slice(currentPage, currentPage + (isMobile ? 1 : isTablet ? 2 : 4)).map((card, index) => (
                  <motion.div
                    key={index}
                    variants={variants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    style={{ width: (isMobile ? '100%' : isTablet ? '50%' : '25%'), height: '100%' }}
                  >
                    {card}
                  </motion.div>
                ))}
              </AnimatePresence>
            </Box>
            <NextButton
              onClick={handleNextPage}
              sx={{ margin: 0 }}
              disabled={currentPage >= cards.length - 4}
            >
              <NavigateNextSharp />
            </NextButton>
          </StyledBox>
        )}
      </>
    )
  }

  return (
    <Div>
      <Watermark />
      <div style={{ padding: '0 8px' }}>
        <Typography variant="h4"><strong>Find Flexible Workspaces Designed For You</strong></Typography>
      </div>
      <RowDiv>
        {isMobile && (
          <>
            <Button onClick={handleSpaceOption} data-testid={currentSpaceOption.spaceTypeId} endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}>{currentSpaceOption.spaceTypeName}</Button>
            <Menu
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              {optionsArray.map(option => {
                if (option.spaceTypeId === currentSpaceOption.spaceTypeId) return null
                else return (
                  <MenuItem key={option?.spaceTypeId} onClick={event => { handleSpaceOption(event); setAnchorEl(null) }} data-testid={option?.spaceTypeId}>{option?.spaceTypeName}</MenuItem>
                )
              })}
            </Menu>
          </>
        )}
        {!isMobile && (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Typography variant="subtitle2" sx={{ padding: '14px 8px' }}>{currentSpaceOption?.subtitle}</Typography>
            <StyledButtonGroup variant="outlined" sx={{ padding: '0 8px' }}>
              {optionsArray.map(option => (
                <div key={option?.spaceTypeName}>
                  <Button onClick={handleSpaceOption} data-testid={option?.spaceTypeId} sx={{
                    backgroundColor: `${currentSpaceOption.spaceTypeId === option?.spaceTypeId ? `${theme.palette.primary.main} !important` : 'white'}`,
                    color: `${currentSpaceOption.spaceTypeId === option?.spaceTypeId ? 'white !important' : null}`,
                    borderColor: 'transparent',
                    borderRadius: '32px',
                    padding: '14px',
                  }}>{option?.spaceTypeName}
                  </Button>
                </div>
              ))}
            </StyledButtonGroup>
          </Box>
        )}
      </RowDiv>
      <CardsCarousel spaceTypeId={currentSpaceOption.spaceTypeId} />
    </Div>
  )
}