import React, { useEffect, useState, useRef } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import { ArrowRightAlt, KeyboardBackspace } from "@mui/icons-material"
import { Button, useTheme, styled } from "@mui/material"
import useScreenSize from "../../react-hooks/screensize"

const ImageGallery = (props) => {
  const theme = useTheme()
  const [thumbnails, setThumbnails] = useState<any[]>([])
  const { width } = useScreenSize()
  const isMobile = width < theme.breakpoints.values.md

  const StyledCarousel = styled(Carousel)(({ theme }) => ({
    'li img': {
      borderRadius: '42px',
    },
    'li.thumb.selected': {
      borderRadius: '50%',
      border: `solid 2px ${theme.palette.secondary.main} !important`,
    },
    'li.thumb:hover': {
      borderRadius: '50%',
      border: `solid 2px ${theme.palette.secondary.main} !important`,
      cursor: 'pointer',
    },
    '.carousel .thumb': {
      border: 'none',
    }
  }))
  const Arrow = styled(Button)(({ theme }) => ({
    width: isMobile ? '20px' : '60px',
    height: isMobile ? '20px' : '60px',
    border: 'solid 1px #d7d7d7',
    backgroundColor: '#fff',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    top: '50%',
    position: 'absolute',
    zIndex: 2,
    ':hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  }))
  const Image = styled('img')(({ theme }) => ({
    width: '75px',
    height: '75px',
    cursor: 'pointer',
  }))

  const generateThumbnail = (videoUrl) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video')
      video.src = videoUrl
      video.crossOrigin = "anonymous"
      video.setAttribute("cross-origin", "anonymous")
      video.setAttribute("cross-origin-isolated", "")

      video.addEventListener('loadeddata', () => {
        try {
          const canvas = document.createElement('canvas')
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
          const thumbnailUrl = canvas.toDataURL('image/jpeg')
          resolve(thumbnailUrl)
        } catch (error) {
          console.error('Error generating thumbnail:', error)
          reject(error)
        }
      })

      video.addEventListener('error', (event) => {
        console.error('Video error:', event)
        reject(event)
      })

      video.addEventListener('abort', (event) => {
        console.error('Video loading aborted:', event)
        reject(event)
      })

      video.addEventListener('loadedmetadata', () => {
        console.log('Video metadata loaded:', video.videoWidth, video.videoHeight)
      })

      video.addEventListener('canplay', () => {
        console.log('Video can play')
      })

      video.load()
    })
  };



  useEffect(() => {
    const generateThumbnails = async () => {
      const images = props.children
      const thumbnails = await Promise.all(images.map(async (image, index) => {
        let videoThumbnail = null as any
        if (image.type === 'video') {
          try {
            videoThumbnail = await generateThumbnail(image.props.src)
          } catch (error) {
            console.error('Error generating thumbnail:', error)
          }
        }
        return <Image src={videoThumbnail || image.props.src} alt={image.props.alt} key={`carousel-image-${index}`} />
      }))
      setThumbnails(thumbnails)
    }

    generateThumbnails()
  }, [props.children]);


  useEffect(() => {
    const ulThumbnails = document.querySelector('ul.thumbs.animated') as HTMLUListElement
    if (ulThumbnails && thumbnails.length) {
      ulThumbnails.style.textAlign = 'center'
    }
  }, [thumbnails])

  return (
    <StyledCarousel {...props}
      renderArrowNext={(clickHandler, hasNext, label) => hasNext && <Arrow onClick={clickHandler} aria-label={label} sx={{ right: '.5%' }}><ArrowRightAlt fontSize={isMobile ? 'small' : 'medium'} /></Arrow>}
      renderArrowPrev={(clickHandler, hasPrev, label) => hasPrev && <Arrow onClick={clickHandler} aria-label={label} sx={{ left: '.5%' }}><KeyboardBackspace fontSize={isMobile ? 'small' : 'medium'} /></Arrow>}
      renderThumbs={() => isMobile ? null : thumbnails}
    >
      {props.children}
    </StyledCarousel>
  )
}

export default ImageGallery
