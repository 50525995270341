import { ApolloClient, InMemoryCache } from '@apollo/client'

const client = new ApolloClient({
  uri: 'https://4ruetseilvhebcpuawlychioui.appsync-api.us-east-1.amazonaws.com/graphql',
  cache: new InMemoryCache(),
  headers: {
    'x-api-key': 'da2-h4kbw65kk5a3vac6fa7hefdlfi',
  },
});

export default client;