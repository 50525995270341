import Navigation from "../navbar/Navigation"
import { SearchBarBanner } from "./SearchBarBanner"
import { Solutions } from "./Solutions"
import { Pricing } from "./pricing/Pricing"
import ComingSoon from "./coming-soon/ComingSoon"
import { TrustedPartners } from "./TrustedPartners"
import { ContactUs } from "./ContactUs"
import Footer from "../footer/Footer"
import AboutNexus from "./AboutNexus"
import Testimonials from "./coming-soon/Testimonials"
import { Box } from "@mui/material"

const Home = () => {
  const StyledDiv = styled(Box)(() => ({
    padding: '40px 0'
  }))

  return (
    <StyledDiv id="home" sx={{ paddingTop: 1, paddingBottom: 0 }}>
      <Navigation />
      <StyledDiv id="section-1">
        <SearchBarBanner />
      </StyledDiv>
      <StyledDiv sx={{ padding: 0, marginTop: '-40px' }} id="section-2">
        <Solutions />
      </StyledDiv>
      <StyledDiv id="section-3" sx={{ paddingBottom: 0 }}>
        <Pricing />
      </StyledDiv>
      <StyledDiv id="section-4" sx={{ padding: 0 }}>
        {/* Commented out TrustedPartners section until finalized (DF 6/14/24) */}
        {/* <TrustedPartners /> */}
      </StyledDiv>
      <StyledDiv id="section-5" sx={{ padding: 0 }}>
        <ComingSoon />
      </StyledDiv>
      <StyledDiv id="section-6">
        <AboutNexus />
      </StyledDiv>
      <StyledDiv id="section-7">
        <Testimonials />
      </StyledDiv>
      <StyledDiv id="section-8" sx={{ paddingBottom: 13 }}>
        <ContactUs />
      </StyledDiv>
      <Footer />
    </StyledDiv>
  )
}

export default Home