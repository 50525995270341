import { Box, CircularProgress, Grid, Typography, styled } from "@mui/material"
import { Link, useLocation } from "react-router-dom"
import nexusLogo from '../../assets/images/nexus-svg-logo-3.svg'
import Footer from "../footer/Footer"
import BookingStepper from "./BookingStepper"
import Step1 from "./booking-steps/Step1"
import Step2 from "./booking-steps/Step2"
import Step3 from "./booking-steps/Step3"
import { Cache } from "aws-amplify/utils"

export const steps = [
  { label: 'Start Booking', stepNo: 1 },
  { label: 'Choose A Space', stepNo: 2 },
  { label: 'Checkout', stepNo: 3 }
]

export const BookingNavigation = () => {
  const Image = styled('img')(() => ({
    width: '100px',
    height: '150px'
  }))

  return (
    <Grid container sx={{ paddingTop: theme => theme.spacing(2), paddingBottom: theme => theme.spacing(2) }}>
      <Grid xs={11} item sx={{ flexDirection: 'row', display: 'flex' }}>
        <Link to='/'>
          <Image src={nexusLogo} alt='Nexus Logo' sx={{ alignSelf: 'center' }} />
        </Link>
        <Typography variant="h5" sx={{ alignSelf: "center" }}>Book Online</Typography>
      </Grid>
      <Grid item xs={1} sx={{ alignContent: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle1">Need Assistance Booking?</Typography>
        <Typography variant="subtitle1">Call: 561-123-4567</Typography>
      </Grid>
    </Grid>
  )
}

const Booking = () => {
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [criteria, setCriteria] = useState<string>('')
  const [currentStepNo, setCurrentStepNo] = useState(1)
  const [currentStepComponent, setCurrentStepComponent] = useState(<Step1 />)
  const [spaceToBeReserved, setSpaceToBeReserved] = useState<object>({})
  const [timeToChangeSteps, setTimeToChangeSteps] = useState(false)

  useEffect(() => {
    const checkAndLoadCache = async () => {
      const currentCachedStepNo = await Cache.getItem('stepNo')
      if (currentCachedStepNo && currentCachedStepNo !== currentStepNo) setCurrentStepNo(currentCachedStepNo)
      else if ((location.state && (!currentCachedStepNo) || currentCachedStepNo > currentStepNo || currentCachedStepNo === currentStepNo)) {
        setCurrentStepNo(2)
        setCriteria(location.state.locationCriteria)
      }
      else if (location.search.includes('login=true')) {
        setCurrentStepNo(3)
      }
    }


    checkAndLoadCache()
  }, [])

  useEffect(() => {
    const checkAndLoadSpaceCache = async () => {
      const cachedReservedSpace = await Cache.getItem('spaceToBeReserved')
      const currentCachedStepNo = await Cache.getItem('stepNo')
      if (currentStepNo === 3 || currentCachedStepNo === 3) {
        if (!cachedReservedSpace && Object.entries(spaceToBeReserved).length) await Cache.setItem('spaceToBeReserved', spaceToBeReserved)
        else if (!!cachedReservedSpace && Object.entries(cachedReservedSpace).length && !Object.entries(spaceToBeReserved).length) setSpaceToBeReserved(cachedReservedSpace)
      }
    }
    checkAndLoadSpaceCache()
  }, [spaceToBeReserved, currentStepNo])


  useEffect(() => {
    const checkStepNo = async () => {
      const currentStashedStepNo = await Cache.getItem('stepNo')
      if ((criteria.length && currentStepNo >= 1) || currentStepNo !== currentStashedStepNo) setTimeout(() => {
        setLoading(false)
      }, 2500)
    }

    checkStepNo()
  }, [criteria, currentStepNo])

  useEffect(() => {
    const checkToChange = async () => {
      const currentCachedStepNo = await Cache.getItem('stepNo')
      if (currentCachedStepNo !== currentStepNo) await Cache.setItem('stepNo', currentStepNo)
      if (timeToChangeSteps) {
        setLoading(true)
        setTimeout(() => setLoading(false), 1300)
        setTimeToChangeSteps(false)
      }
    }
    checkToChange()
    switch (currentStepNo) {
      case 1:
        setCurrentStepComponent(<Step1 />)
        break
      case 2:
        setCurrentStepComponent(<Step2 spaceInfo={[{ propertyInfo: location.state.propertyInfo, spaceDetails: location.state.spaceInfo, price: location.state.price }]} setCurrentStep={setCurrentStepNo} setSpaceToBeReserved={setSpaceToBeReserved} setTimeToChangeSteps={setTimeToChangeSteps} />)
        break
      case 3:
        setCurrentStepComponent(<Step3 reservedSpace={{ bookingDate: location.state.bookingDate, ...spaceToBeReserved }} setCurrentStep={setCurrentStepNo} />)
        break
      default:
        break;
    }
  }, [currentStepNo])


  return (
    <>
      <BookingNavigation />
      {loading && <Box sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center', justifyContent: 'space-around', height: '50vh' }}><CircularProgress variant="indeterminate" size={175} color="primary" /></Box>}
      {!loading &&
        <>
          <BookingStepper criteria={{ spaceType: { id: location.state.spaceInfo.spaceType }, locationCriteria: criteria, bookingDate: location.state.bookingDate }} activeStep={{ stepNo: currentStepNo }} setSearchCriteria={() => { }} />
          {currentStepComponent}
        </>
      }
      <Footer />
    </>
  )
}

export default Booking