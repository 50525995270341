import { CalendarMonth, LocationOnSharp, MeetingRoom, Person } from "@mui/icons-material"
import { Box, Button, Card, CardContent, Grid, SxProps, Typography, styled, useTheme, Theme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { ExpandableSidePanel } from "../utility-components/ExpandableSidePanel"
import useScreenSize from "../../react-hooks/screensize"

const LocationsCard = ({ property, cardNo, locationCriteria }) => {
  const [bookingInfo, setBookingInfo] = useState<object | null>(null)
  const [expandPanel, setExpandPanel] = useState(false)
  const navigate = useNavigate()

  const handleOpenPanel = (e: React.MouseEvent<HTMLButtonElement>) => {
    setExpandPanel(true)
  }

  const StyledDiv = styled(Box)(() => ({
    display: 'flex'
  }))
  const StyledHR = styled('hr')(() => ({
    height: '1px',
    borderRadius: '12px',
    borderColor: '#959595',
    backgroundColor: '#fff',
    opacity: 0.3,
    width: '100%'
  }))
  const Image = styled('img')(() => ({
    width: '100%',
    height: '100%'
  }))
  const CardSx: SxProps = {
    width: '100%',
    border: 'solid 1px #d7d7d7',
    borderRadius: '12px',
    ':hover': {
      boxShadow: 'rgba(0, 0, 0, 0.08) 10px 10px 80px',
    },
    padding: theme => (theme as any).spacing(2),
  }

  return (
    <Box sx={{ display: "flex", flexDirection: 'column' }}>
      <Card sx={CardSx} elevation={0}>
        <ExpandableSidePanel isExpanded={expandPanel} triggerId={`card-panel-${cardNo}`} spaceName={property.PropertyName} onClose={() => setExpandPanel(false)} city={property.City} />
        <Grid container>
          <Grid item sm={3}>
            <Image src={property?.PropertyGallery[0]?.Url} alt="" />
          </Grid>
          <Grid item sm={9}>
            <CardContent sx={{ height: '100%' }}>
              <StyledDiv sx={{ flexDirection: 'column', gap: '1rem' }}>
                <StyledDiv sx={{ height: 'fit-content' }}>
                  <Typography variant="h6"><strong>{property.PropertyName}</strong></Typography>
                </StyledDiv>
                <Typography variant="body2">A range of ready-to-use, fully equipped offices with everything you need to get started</Typography>
                <Typography variant="body2">Dog Friendly  |  Parking  |  Bike Storage  |  Event Space  |  Wellness Room</Typography>
                <StyledHR />
                <StyledDiv sx={{ gap: '0.5rem' }}>
                  <Button variant="outlined" sx={{ borderRadius: '16.4px' }} onClick={e => navigate(`/locations?city=${property.City.toLowerCase()}`)}>Learn More</Button>
                </StyledDiv>
              </StyledDiv>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}

const SpacesSelections = ({ propertiesToDisplay, locationCriteria }) => {
  const StyledDiv = styled(Box)(() => ({
    display: 'flex'
  }))
  const theme = useTheme() as Theme
  const { values } = theme.breakpoints
  const { width } = useScreenSize()
  const isMobile = width < values.md

  return (
    <StyledDiv sx={{ flexDirection: 'column', gap: '2rem', overflow: 'unset' }}>
      {propertiesToDisplay.map((property, index) => <LocationsCard property={property} key={`locations-card-${index}`} cardNo={index} locationCriteria={locationCriteria} />)}
    </StyledDiv>
  )
}

export default SpacesSelections