import { Box, Button, Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton, Theme, Typography, useTheme } from '@mui/material'
import { animate, motion, useMotionValue, useTransform } from 'framer-motion'
import atlantaGraphics from '../../../assets/images/atlanta-nexus-background.jpeg'
import useScreenSize from '../../../react-hooks/screensize'

import { Close, CorporateFare, DirectionsCar, LocationOn, MeetingRoom, Summarize } from '@mui/icons-material'
import { SplitButton } from '../../common/SplitButton'

const ComingSoon: React.FC = () => {
  const [isAnimate, setIsAnimate] = useState(false)
  const infoBlockRef = useRef<HTMLDivElement>(null)
  const theme = useTheme() as Theme
  const { values } = theme.breakpoints
  const { width: currentWidth } = useScreenSize()
  const isMobile = currentWidth <= values.md, isLg = currentWidth <= values.lg
  const suitesCount = useMotionValue(0), parkingCount = useMotionValue(0), sqFtCount = useMotionValue(0), classLetter = useMotionValue(25)
  const suitesRounded = useTransform(suitesCount, (latest) => Math.round(latest))
  const parkingRounded = useTransform(parkingCount, (latest) => Math.round(latest))
  const sqFtRounded = useTransform(sqFtCount, (latest) => Math.round(latest))
  const classLetterChanged = useTransform(classLetter, (latest) => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const index = Math.round(latest)
    return alphabet[index % alphabet.length]
  })
  const [modal, setModal] = useState({ whichModal: 'reserve', open: false })

  const slideOutPanel = {
    hidden: { x: '-20vw', opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 2, type: 'spring' } },
    exit: { x: '-20vw', opacity: 0, transition: { duration: 2, type: 'spring' } },
  }

  const slideOutInfo = {
    hidden: { x: '100%', opacity: 0 },
    visible: { x: -10, opacity: 1, rotate: '-12.25deg', transition: { duration: 2, type: 'spring' } },
    exit: { x: '100%', opacity: 0, transition: { duration: 2, type: 'spring' } },
  }

  const titleSlideDown = {
    hidden: { y: '-100%', opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 2, type: 'spring' } },
    exit: { y: '-100%', opacity: 0, transition: { duration: 2, type: 'spring' } },
  }

  const countdownSlideUp = {
    hidden: { y: '-100%', opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 2, type: 'spring' } },
    exit: { y: '-100%', opacity: 0, transition: { duration: 2, type: 'spring' } },
  }

  const handleScroll = () => {
    const scrollPosition = window.scrollY
    const infoBlockTop = infoBlockRef.current?.getBoundingClientRect().top || 0
    const isInView = scrollPosition > infoBlockTop - window.innerHeight
    setIsAnimate(isInView)
  }

  const CountdownTimer: React.FC = () => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)

      return () => clearInterval(timer)
    }, [])

    return (
      <Box sx={{
        display: 'flex',
        fontSize: '2em',
        gap: 2
      }}>
        <Typography variant={isMobile ? 'h6' : 'h5'} sx={{ fontWeight: 'bold', color: 'white' }}>{timeLeft.days} Days,</Typography>
        <Typography variant={isMobile ? 'h6' : 'h5'} sx={{ fontWeight: 'bold', color: 'white' }}>{timeLeft.hours} Hours</Typography>
      </Box>
    )
  }

  type TimeLeftType = {
    days: number
    hours: number
    minutes: number
    seconds: number
  }

  const calculateTimeLeft = () => {
    let year = new Date().getFullYear()
    const difference = +new Date(`${year}-08-31`) - +new Date() // change this to your desired date
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft as TimeLeftType
  }

  const Countdown: React.FC = () => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)

      return () => clearInterval(timer)
    })

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <motion.div initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CountdownTimer />
        </motion.div>
      </Box>
    )
  }


  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (isAnimate) {
      suitesCount.set(0)
      parkingCount.set(0)
      sqFtCount.set(0)
      classLetter.set(25)

      // Start animations
      const animation1 = animate(suitesCount, 100, { duration: 3 })
      const animation2 = animate(parkingCount, 400, { duration: 3 })
      const animation3 = animate(sqFtCount, 44500, { duration: 3 })
      const animation4 = animate(classLetter, 0, { duration: 3 })

      return () => {
        animation1.stop()
        animation2.stop()
        animation3.stop()
        animation4.stop()
      }
    }
  }, [isAnimate])

  return (
    <Box
      sx={{
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
        minHeight: '70vh',
        width: '100vw',
        left: '50%',
        transform: 'translateX(-50%)',
        overflow: 'hidden',
        backgroundImage: `url(${atlantaGraphics})`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <motion.div
        style={{
          position: 'absolute',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
          alignSelf: 'center',
          marginTop: '3rem',
        }}
        variants={titleSlideDown}
        initial="hidden"
        animate={isAnimate ? 'visible' : 'hidden'}
        exit="exit"
      >
        <Typography variant="h6" sx={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>Introducing</Typography>
        <Typography variant={isMobile ? 'h4' : 'h3'} sx={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>Nexus Atlanta</Typography>
      </motion.div>
      {!(isMobile || isLg) && (
        <motion.div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '20vw',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          variants={slideOutPanel}
          initial="hidden"
          animate={isAnimate ? 'visible' : 'hidden'}
          exit="exit"
        >
          <Card sx={{ height: '100%', background: 'rgba(51, 83, 139, 0.7)', width: '100%' }}>
            <CardContent sx={{ height: '100%', padding: theme => theme.spacing(4), display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', '& > *': { color: 'white' }, textAlign: 'center' }}>
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', flexDirection: 'column' }}>
                <MeetingRoom sx={{ color: 'white', fontSize: '2.5rem' }} />
                <Box>
                  <Typography variant='h5' sx={{}}><strong><motion.span>{suitesRounded}</motion.span>+</strong></Typography>
                  <Typography variant='h5' sx={{}}><strong>Executive Suites</strong></Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', flexDirection: 'column' }}>
                <Summarize sx={{ color: 'white', fontSize: '2.5rem' }} />
                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', flexDirection: 'column' }}>
                  <Typography variant='h5' sx={{}}><strong><motion.span>{classLetterChanged}</motion.span></strong></Typography>
                  <Typography variant='h5' sx={{}}><strong>CLASS</strong></Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', flexDirection: 'column' }}>
                <CorporateFare sx={{ color: 'white', fontSize: '2.5rem' }} />
                <Box>
                  <Typography variant='h5' sx={{}}><strong><motion.span>{sqFtRounded}</motion.span></strong></Typography>
                  <Typography variant='h5' sx={{}}><strong>sq. ft</strong></Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', flexDirection: 'column' }}>
                <DirectionsCar sx={{ color: 'white', fontSize: '2.5rem' }} />
                <Box>
                  <Typography variant='h5' sx={{}}><strong><motion.span>{parkingRounded}</motion.span>+</strong></Typography>
                  <Typography variant='h5' sx={{}}><strong>Parking Spaces</strong></Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </motion.div>
      )}
      <motion.div
        style={{
          position: 'absolute',
          bottom: 0,
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          textAlign: 'center',
          marginBottom: '1rem',
          alignSelf: 'center',
        }}
        variants={countdownSlideUp}
        initial="hidden"
        animate={isAnimate ? 'visible' : 'hidden'}
        exit="exit"
      >
        <Countdown />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <LocationOn sx={{ color: 'white', alignSelf: 'center' }} />
          <Typography variant={isMobile ? 'h6' : 'h5'} sx={{ fontWeight: 'bold', color: 'white' }}>
            1389 Peachtree NE, Atlanta, GA 30309
          </Typography>
        </Box>
        <SplitButton
          sx={{ alignSelf: 'center', paddingTop: 1 }}
          primary={<Button variant="outlined" sx={{ borderRadius: '4px', backgroundColor: 'white', color: 'black', ':hover': { color: 'white', borderColor: 'white' } }} onClick={() => setModal({ whichModal: 'reserve', open: true })}>Reserve</Button>}
          secondary={<Button variant="contained" color="primary" sx={{ borderRadius: '4px' }} onClick={() => setModal({ whichModal: 'info', open: true })}>Request Info</Button>}
        />
        <Dialog
          open={modal.open}
          onClose={() => setModal({ ...modal, open: false })}
          sx={{
            '& .MuiDialogContent-root': {
              padding: theme.spacing(2),
            },
            '& .MuiDialogActions-root': {
              padding: theme.spacing(1),
            }
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            <strong>{modal.whichModal === 'reserve' ? 'Reserve Your Spot' : 'Request Info'}</strong>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setModal({ ...modal, open: false })}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          <DialogContent sx={{ p: 2 }}>
            {modal.whichModal === 'reserve' && (
              <>
                <Typography variant='body1'>
                  At the moment, we are not accepting any reservations. However, we are working hard to open up our reservation system as soon as possible. Once it's ready, you'll be able to return to this page and begin the reservation process. We appreciate your patience and understanding.
                </Typography>
              </>
            )}
            {modal.whichModal === 'info' && (
              <>
                <Typography variant='body1'>
                  Currently, we are not accepting any requests for information. We are in the process of setting up our system to handle such requests. As soon as it's ready, you'll be able to come back to this page and start the request process. Thank you for your patience and understanding.
                </Typography>
              </>
            )}
          </DialogContent>
        </Dialog>
      </motion.div>
    </Box>
  )
}

export default ComingSoon;


