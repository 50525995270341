import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Grid, SxProps, Theme, Typography, duration, useTheme } from '@mui/material'
import { useMotionValue, useTransform, animate, motion, MotionStyle, AnimatePresence, Variants, Variant } from 'framer-motion'
import { ArrowForward, Business, BusinessCenter } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'

import aboutImg1 from '../../assets/images/arlington-research-Kz8nHVg_tGI-unsplash.jpg'
import aboutImg2 from '../../assets/images/antonio-janeski-KpIpjje1Mxk-unsplash.jpg'
import aboutImg3 from '../../assets/images/jud-mackrill-Of_m3hMsoAA-unsplash.jpg'
import nexusStar from '../../assets/images/logo-transparency-05.png'
import nexusGrayStar from '../../assets/images/logo-transparency-04.png'
import { Watermark } from '../common/Watermark'

const Section = ({ title, stat, fullText, isLeft, isVisible, imgUrl }) => {
  const isRankTtile = title === 'Executive Suites Company in South Florida!', isMembershipTitle = title === 'Membership Counts so far!', isRenewalTitle = title === 'Renewals Rate at it\'s highest!'
  const theme = useTheme() as Theme
  const count = useMotionValue(isRankTtile ? 100 : 0)
  const roundedUp = useTransform(count, (latest) => Math.round(latest))
  const roundedDown = useTransform(count, (latest) => Math.floor(latest))
  const { para1, para2 } = fullText
  const rounded = isRankTtile ? roundedDown : roundedUp
  const navigate = useNavigate()

  useEffect(() => {
    if (isVisible) {
      const animation = animate(count, stat, { duration: 2.75 })
      return () => animation.stop()
    }
  }, [isVisible])

  const containerSx = {
    display: 'flex',
    justifyContent: isLeft ? 'flex-start' : 'flex-end',
    alignItems: 'center',
    height: '100%',
    padding: '1rem',
  }

  const textSx = {
    color: theme.palette.primary.main,
    borderRadius: '5px',
    width: 'fit-content',
    alignSelf: 'flex-start',
    display: 'flex',
    alignItems: 'center',
  } as SxProps

  const imgSx = {
    width: '100%',
    height: 'auto',
    minHeight: '250px',
    minWidth: '250px',
  }

  const textContainerSx = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    gap: '.75rem',
  }

  const imgContainerSx = {
    padding: theme => theme.spacing(4),
  }

  const nexusStarSx = {
    width: '100%',
    height: 'auto',
    maxHeight: '55px',
    maxWidth: '55px',
  }

  const leftVariants = {
    initial: { opacity: 0 },
    animate: { opacity: isVisible ? 1 : 0 },
    exit: { opacity: 0 },
  } as Variants, rightVariants = {
    initial: { x: isLeft ? -100 : 100},
    animate: { x: isVisible ? 0 : isLeft ? -100 : 100 },
    exit: { x: isLeft ? -100 : 100 },
  } as Variants

  return (
    <Grid container sx={containerSx} spacing={4}>
      {isLeft ? (
        <Grid item xs={12} sm={6}>
          <AnimatePresence>
            <motion.div
              initial="initial"
              animate="animate"
              variants={leftVariants}
              exit='exit'
              transition={{ duration: 2 }}
            >
              <Box sx={textContainerSx}>
                <Typography variant='h3' sx={textSx}>
                  {isRankTtile && '#'}
                  <motion.h3 style={{ margin: 0 }}>{rounded}</motion.h3>
                  {isMembershipTitle && '+'}
                  {isRenewalTitle && '%'}
                </Typography>
                <Typography variant="h4" fontWeight="bold">
                  {title}
                </Typography>
                <Typography variant="subtitle1" sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                  <img src={nexusStar} style={nexusStarSx} />
                  {para1}
                </Typography>
                <Typography variant="subtitle1" sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                  <img src={nexusStar} style={nexusStarSx} />
                  {para2}
                </Typography>
                <Box sx={{}}>
                  <Button variant="outlined" style={{ borderRadius: '22.5px' }} endIcon={<ArrowForward />} onClick={() => navigate('/contact?form=contact-form')}>Contact a Specialist</Button>
                </Box>
              </Box>
            </motion.div>
          </AnimatePresence>
        </Grid>
      ) : (
        <Grid item xs={12} sm={6}>
          <AnimatePresence>
            <motion.div
              initial="initial"
              animate="animate"
              variants={rightVariants}
              exit='exit'
              transition={{ duration: 2 }}
            >
              <Box sx={imgContainerSx}>
                <img src={imgUrl} alt={title} style={{ ...imgSx }} />
              </Box>
            </motion.div>
          </AnimatePresence>
        </Grid>
      )}
      {!isLeft ? (
        <Grid item xs={12} sm={6}>
          <AnimatePresence>
            <motion.div
              initial="initial"
              animate="animate"
              variants={leftVariants}
              exit='exit'
              transition={{ duration: 1.5 }}
            >
              <Box sx={textContainerSx}>
                <Typography variant='h3' sx={textSx}>
                  {isRankTtile && '#'}
                  <motion.h3 style={{ margin: 0 }}>{rounded}</motion.h3>
                  {isMembershipTitle && '+'}
                  {isRenewalTitle && '%'}
                </Typography>
                <Typography variant="h4" fontWeight="bold">
                  {title}
                </Typography>
                <Typography variant="subtitle1" sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                  <img src={nexusStar} style={nexusStarSx} />
                  {para1}
                </Typography>
                <Typography variant="subtitle1" sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                  <img src={nexusStar} style={nexusStarSx} />
                  {para2}
                </Typography>
                <Box sx={{ padding: theme => theme.spacing(0, 0) }}>
                  <Button variant="outlined" style={{ borderRadius: '22.5px' }} endIcon={<ArrowForward />} onClick={() => navigate('/contact?form=contact-form')}>Contact a Specialist</Button>
                </Box>
              </Box>
            </motion.div>
          </AnimatePresence>
        </Grid>
      ) : (
        <Grid item xs={12} sm={6}>
          <AnimatePresence>
            <motion.div
              initial="initial"
              animate="animate"
              variants={rightVariants}
              exit='exit'
              transition={{ duration: 2 }}
            >
              <Box sx={imgContainerSx}>
                <img src={imgUrl} alt={title} style={{ ...imgSx }} />
              </Box>
            </motion.div>
          </AnimatePresence>
        </Grid>
      )}
    </Grid>
  )
}

const AboutNexus = () => {
  const [isVisible, setIsVisible] = useState(false)
  const mainRef = useRef<HTMLDivElement | null>(null)
  const sections = [
    {
      title: 'Membership Counts so far!',
      stat: 800,
      fullText: {
        para1: 'Nexus Workspaces proudly serves a vibrant community of over 800 members, a number that continues to grow as more businesses recognize the unparalleled benefits of their executive workspaces. This thriving membership base is a testament to Nexus Workspaces\' ability to deliver exceptional, flexible office solutions that meet the diverse needs of modern professionals.',
        para2: 'By fostering a dynamic and collaborative environment, Nexus Workspaces attracts a wide array of industries, providing the perfect setting for innovation and growth. With a commitment to excellence and a keen understanding of client needs, Nexus Workspaces is poised for continued expansion, solidifying its reputation as a leader in the executive workspace market'
      },
      ref: useRef(null),
      img: aboutImg1
    },
    {
      title: 'Renewals Rate at it\'s highest!',
      stat: 90,
      fullText: {
        para1: 'Nexus Workspaces stands out in the executive workspaces industry, boasting an impressive 90% renewal rate year after year. This remarkable statistic reflects the company\'s unwavering dedication to client satisfaction and the superior quality of its services.',
        para2: ' By consistently providing flexible, high- end office environments that cater to the evolving needs of businesses, Nexus Workspaces has earned the loyalty and trust of its clients.This exceptional renewal rate underscores the company\'s commitment to fostering long-term relationships and creating spaces where businesses can thrive, making Nexus Workspaces a trusted partner for success.'
      },
      ref: useRef(null),
      img: aboutImg2
    },
    {
      title: 'Executive Suites Company in South Florida!',
      stat: 2,
      fullText: {
        para1: 'Nexus Workspaces has firmly established itself as the second largest executive workspaces company in South Florida, a testament to its commitment to excellence and innovation in the flexible office space industry. With a strategic focus on meeting the evolving needs of modern businesses, Nexus Workspaces offers premium, adaptable environments that foster productivity and collaboration.',
        para2: 'This impressive growth trajectory is fueled by an unwavering dedication to client satisfaction and a forward-thinking approach, positioning Nexus Workspaces as a leading force in the region\'s competitive market.As they continue to expand, Nexus Workspaces is set to redefine the standards of executive workspaces in South Florida, ensuring businesses thrive in dynamic and inspiring settings'
      },
      ref: useRef(null),
      img: aboutImg3
    },
  ]

  useEffect(() => {
    const handleScroll = () => {
      const { top, bottom } = (mainRef?.current as HTMLElement).getBoundingClientRect()
      const isVisible = top < window.innerHeight && bottom >= 0
      setIsVisible(isVisible)
    }

    const handleResize = () => handleScroll()

    window.addEventListener("scroll", handleScroll)
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("scroll", handleScroll)
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const boxSx = {
    display: 'flex',
    flexDirection: 'column',
    padding: theme => theme.spacing(2, 0),
    position: 'relative'
  }

  return (
    <Box sx={boxSx} ref={mainRef}>
      <Watermark sx={{ right: '-270px', top: '40%', opacity: 0.8, backgroundImage: `url(${nexusGrayStar})` }}/>
      <Box sx={{ ...boxSx, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? -40 : -100 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ duration: 2 }}
          >
            <Typography variant="h3" fontWeight="bold" textAlign="center" sx={{ padding: theme => theme.spacing(0, 0, 1.5) }}>Excellence in Numbers</Typography>
            <Typography variant="h6" textAlign="center">Our numbers speak for themselves</Typography>
          </motion.div>
        </AnimatePresence>
      </Box>
      <Grid container spacing={6}>
        {sections.map(({ title, stat, fullText, ref, img }, index) => (
          <Grid item xs={12} key={index} ref={ref}>
            <Section title={title} stat={stat} fullText={fullText} isLeft={index % 2 === 0} isVisible={isVisible} imgUrl={img} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default AboutNexus
