import { Loader } from '@googlemaps/js-api-loader'
import { Container, CssBaseline, ThemeProvider, createTheme, useMediaQuery } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import '../assets/css/App.css'
import PTSerifBold from '../assets/fonts/pt-serif/PTSerif-Bold.ttf'
import PTSerifRegular from '../assets/fonts/pt-serif/PTSerif-Regular.ttf'
import Routes from './Routes'
import { useParentProperties } from '../AppContext'
import { useLocation } from 'react-router-dom'

const App = () => {
  const [mapsApiKey, setMapsApiKey] = useState<object>({})
  const { updateParentHeight } = useParentProperties()
  const location = useLocation()

  let nexusTheme = createTheme({
    typography: {
      fontFamily: '"Arial", sans-serif', // Default font for the body
      h1: {
        fontFamily: '"PT Serif", serif', // Specific font for h1
      },
      h2: {
        fontFamily: '"PT Serif", serif', // Specific font for h2
      },
      h3: {
        fontFamily: '"PT Serif", serif', // Specific font for h3
      },
      h4: {
        fontFamily: '"PT Serif", serif', // Specific font for h4
      },
      h5: {
        fontFamily: '"PT Serif", serif', // Specific font for h5
      },
      h6: {
        fontFamily: '"PT Serif", serif', // Specific font for h6
      },
      subtitle1: {
        fontFamily: '"Arial", sans-serif', // Specific font for subtitle1
      },
      subtitle2: {
        fontFamily: '"Arial", sans-serif', // Specific font for subtitle2
      },
      caption: {
        fontFamily: '"Arial", sans-serif', // Specific font for caption
      },
      body1: {
        fontFamily: '"Arial", sans-serif', // Specific font for body1
      },
      body2: {
        fontFamily: '"Arial", sans-serif', // Specific font for body2
      },
      // Add other typography elements as needed
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'PT Serif';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('PTSerif-Regular'), url(${PTSerifRegular}) format('ttf');
          }
          @font-face {
            font-family: 'PT Serif';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: local('PTSerif-Bold'), url(${PTSerifBold}) format('ttf');
          }
        `
      }
    }
  })

  nexusTheme = createTheme(nexusTheme, {
    palette: {
      primary: nexusTheme.palette.augmentColor({
        color: {
          main: '#00296e'
        },
        name: 'primary'
      }),
      secondary: nexusTheme.palette.augmentColor({
        color: {
          main: '#f1bc5e'
        },
        name: 'secondary'
      })
    }
  })

  const loadMaps = async () => {
    if (typeof window !== 'undefined') {
      const loader = new Loader({
        apiKey: mapsApiKey['api-key'],
        libraries: ['places', 'geocoding', 'maps', 'marker']
      })

      await loader.importLibrary('maps')
    }
  }
  const isMobile = useMediaQuery(nexusTheme.breakpoints.down('md'))

  useEffect(() => {
    const getMapsKey = async () => {
      setMapsApiKey({ 'api-key': 'AIzaSyD5c4tM-2Y4Gy9ZrXqc-Bw14f_99ITcj8Y' })
    }

    getMapsKey()

    if (isMobile) {
      const navbarHeight = document.getElementById('nexus-nav-mobile')?.children[0]?.clientHeight as number
      updateParentHeight('nexus-nav-mobile', navbarHeight)
    }
  }, [])

  useEffect(() => {
    if (Object.entries(mapsApiKey).length) {
      global.mapsApiKey = mapsApiKey['api-key']
      global.google = window.google
      loadMaps()
    }
  }, [mapsApiKey])

  // Change title for each page
  useEffect(() => {
    const title = document.title
    const path = window.location.pathname
    const pathArray = path.split('/')
    const lastPath = pathArray[pathArray.length - 1]
    let newTitle = ''

    switch (lastPath) {
      case 'locations':
        newTitle = 'Nexus Workspaces | Locations'
        break
      case 'solutions':
        newTitle = 'Nexus Workspaces | Solutions'
        break
      case 'contact':
        newTitle = 'Nexus Workspaces | Contact'
        break
      case 'about':
        newTitle = 'Nexus Workspaces | About'
        break
      case 'locations/search':
        newTitle = 'Nexus Workspaces | Find a Location'
        break
      default:
        newTitle = 'Nexus Workspaces | Executive Office Suites'
    }

    if (title !== newTitle) {
      document.title = newTitle
    }
  }, [location.pathname])

  const containerProps = {
    paddingRight: isMobile ? '32px !important' : '64px !important',
    paddingLeft: isMobile ? '32px !important' : '64px !important',
    overflow: isMobile ? 'hidden' : 'auto'
  }

  return (
    <div className="app">
      <ThemeProvider theme={nexusTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <Container maxWidth={false} sx={{ fontFamily: 'Poppins', ...containerProps }}>
            <Routes />
          </Container>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  )
}

export default App
