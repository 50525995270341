import { Box, Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material"
import { useMapsLibrary, useApiIsLoaded, Map, AdvancedMarker, APIProvider } from "@vis.gl/react-google-maps"
"react"
import { getSecrets } from "../../../aws/get-secrets"

const GoogleMap = ({ spaces }) => {
  const [mapId, setMapId] = useState<string>('')
  const [finalCoordinates, setFinalCoordinates] = useState<any[]>([])
  const [markerPositions, setMarkerPositions] = useState<google.maps.LatLng[]>([])
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null)
  const geocodingLibrary = useMapsLibrary('geocoding')
  const mapLoaded = useApiIsLoaded()

  const getMapId = async (secretName) => {
    // const results = await getSecrets(secretName)
    setMapId('429cfae7c5a55818')
  }

  const retrieveCoordinatesFromGoogle = async (allCoordinates: any[]) => {
    const final = await Promise.all(allCoordinates.map(async coord => {
      let newLat, newLong, fullAddress = `${coord.address1}, ${coord.city}, ${coord.state}, ${coord.zip}`
      const response = await (geocoder as google.maps.Geocoder).geocode({ address: fullAddress })
      if (response?.results.length) {
        response.results.forEach(({ geometry }) => {
          newLat = geometry.location.lat()
          newLong = geometry.location.lng()
        })
        return ({
          ...coord,
          latitude: newLat,
          longitude: newLong,
        })
      }
    }))
    setFinalCoordinates(final)
  }

  useEffect(() => {
    getMapId('map-id-locations-page')
  }, [])

  useEffect(() => {
    if (!geocodingLibrary) return
    else setGeocoder(new geocodingLibrary.Geocoder())
  }, [geocodingLibrary])

  useEffect(() => {
    if (!geocoder) return
    else if (spaces.length && geocoder) {
      const properties = spaces.map(space => ({ ...space.propertyInfo }))
      retrieveCoordinatesFromGoogle(properties)
    }
  }, [spaces, geocoder])

  useEffect(() => {
    if (mapId && finalCoordinates.length > 0 && finalCoordinates.some(coord => coord.latitude > 0)) {
      setMarkerPositions(finalCoordinates.map(coord => ({ lat: () => coord.latitude, lng: () => coord.longitude }) as google.maps.LatLng))
    }
  }, [mapId, finalCoordinates])

  return (
    <>
      {!!markerPositions.length && 
        <Map
          mapId={mapId}
          zoom={14}
          center={{ lat: markerPositions[0]?.lat(), lng: markerPositions[0]?.lng() }}
          onTilesLoaded={event => {
            if (!mapLoaded) {
              const bounds = new google.maps.LatLngBounds()
              markerPositions?.forEach(({ lat, lng }) => bounds.extend({ lat: lat(), lng: lng() }))
              event.map.fitBounds(bounds)
            }
          }}
        >
          {markerPositions.map(({ lat, lng }, index) => <AdvancedMarker position={{ lat: lat(), lng: lng() }} collisionBehavior={google.maps.CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL} key={`nexus-location-marker-${index}`} />)}
        </Map>
      }
    </>
  )
}


const Step2 = ({ spaceInfo, setCurrentStep, setSpaceToBeReserved, setTimeToChangeSteps }) => {
  const [cards, setCards] = useState<[]>([])

  useEffect(() => {
    if (spaceInfo.length) {
      const resultCards = spaceInfo.map((space, index) => {
        const { propertyInfo, spaceDetails, price } = space
        const spaceImg = spaceDetails.gallery[0].Url
        return (
          <Card key={`space-booking-card-${index}`}>
            <CardContent>
              <Grid container sx={{ gap: '1.5rem', flexWrap: 'nowrap' }}>
                <Grid item sm={2}>
                  <CardMedia
                    sx={{ height: '100%' }}
                    component='img'
                    image={spaceImg}
                    alt='Space Interior' />
                </Grid>
                <Grid item sm={10}>
                  <Box>
                    <Box sx={{ borderBottom: '0.5px solid gray' }}>
                      <Typography variant='h5' sx={{ paddingTop: theme => theme.spacing(2), paddingBottom: theme => theme.spacing(2) }}>{spaceDetails.spaceName}</Typography>
                    </Box>
                    <Typography variant='subtitle1'>{propertyInfo.propertyName}</Typography>
                    <Typography variant='subtitle2'>{propertyInfo.address1}</Typography>
                    <Typography variant='subtitle2'>{propertyInfo.city}, {propertyInfo.state}</Typography>
                    <Typography variant='subtitle2'>{propertyInfo.zip}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <Button variant='text'>About this Nexus</Button>
                    <Box sx={{ alignSelf: 'flex-end' }}>
                      <Typography>{price}</Typography>
                      <Button variant='contained' onClick={() => { setSpaceToBeReserved({ propertyInfo, spaceDetails, price }); setCurrentStep(3); setTimeToChangeSteps(true) }}>Select</Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )
      })
      setCards(resultCards)
    }
  }, [])

  if (!cards.length) return <></>
  else return (
    <Box >
      <Grid container sx={{ height: '100vh' }}>
        <Grid item sm={8} sx={{ height: '100%', paddingRight: theme => theme.spacing(7) }}>
          {cards}
        </Grid>
        <Grid item sm={4} sx={{ height: '100%' }}>
          <APIProvider apiKey={global.mapsApiKey} libraries={['marker']}>
            <GoogleMap spaces={spaceInfo} />
          </APIProvider>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Step2