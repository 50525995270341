import { Box } from "@mui/material"
import Navigation from "./navbar/Navigation"
import Footer from "./footer/Footer"

const Layout = (props) => {

  return (
    <Box>
      <Navigation />
      <Box sx={{ ...props.sx, padding: theme => theme.spacing(4, 0) }}>
        {props.children}
      </Box>
      <Footer />
    </Box>
  )
}

export default Layout