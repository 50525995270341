export const aboutDescriptions = [
  {
    propertyName: 'Nexus at Boca Raton',
    description: `Located near FL-7 (US-441) and Yamato Road in Boca Raton, our space offers easy access to
      major highways and local amenities.Enjoy nearby shops, restaurants, and parks, along with
      flexible private offices tailored to your business needs.Small and medium- sized businesses can
      leverage our private offices with flexible terms, providing 24 / 7 access to workspace.We offer
      essential services such as receptionist support, mail handling, printing, kitchen facilities,
        high - speed fiber optic internet, and phone lines to support daily operations and growth.
          Additionally, our virtual office plans offer a prestigious business address, business phone
      number, access to conference rooms, meeting rooms, and notary services, catering to diverse
      business needs without the requirement for physical office space.`,
      miniDescription: `Located near FL-7 (US-441) and Yamato Road in Boca Raton, our space offers easy access to major highways and local amenities. Enjoy nearby shops, restaurants, and parks, along with flexible private offices tailored to your business needs.`
  },
  {
    propertyName: 'Nexus at Stuart',
    description: `Located in Stuart’s bustling district near US-1 and NW Dixie Hwy, our shared working spaces
      offer convenience and connectivity. Enjoy easy access to local shops, restaurants, and major
      highways, alongside flexible workspace options and modern amenities. Join a vibrant
      community of professionals and elevate your workday with us. Small- and medium-sized
      businesses can take advantage of private offices offering flexible terms to accommodate teams of
      any size. Our workspaces provide 24/7 access and include receptionist services, mail and
      package handling, printing facilities, kitchens, high-speed fiber optic internet, and phone lines.
      These amenities are designed to support businesses in their growth and daily operations.`,
      miniDescription: `Located in Stuart’s bustling district near US-1 and NW Dixie Hwy, our shared working spaces offer convenience and connectivity. Enjoy easy access to local shops, restaurants, and major highways, alongside flexible workspace options and modern amenities.`
  },
  {
    propertyName: 'Nexus at Wellington',
    description: `Located in Lake Worth, our space offers easy access to Florida's Turnpike and State Road 7
      (US-441), connecting you to nearby cities and business districts. Enjoy nearby shops, restaurants,
      and recreational facilities, along with flexible workspace solutions including private offices and
      coworking spaces. Benefit from comprehensive amenities such as reception services, high-speed
      internet, and a professional environment designed to support your business needs. Experience
      flexible private offices tailored for small and medium-sized businesses, providing 24/7 access
      and essential amenities such as reception services, mail handling, printing, kitchens, fiber optic
      internet, and reliable phone lines. Explore virtual office plans offering a prestigious business
      address, business phone number, and access to conference and meeting rooms, perfect for
      businesses seeking flexibility without physical office space.`,
      miniDescription: `Located in Lake Worth, our space offers easy access to Florida's Turnpike and State Road 7 (US-441), connecting you to nearby cities and business districts. Enjoy nearby shops, restaurants, and recreational facilities, along with flexible workspace solutions including private offices and coworking spaces.`
  },
  {
    propertyName: 'Nexus at Boynton Beach',
    description: `Located in Boynton Beach, our space offers easy access to Interstate 95 (I-95) and Boynton
      Beach Blvd, connecting you to nearby cities and attractions. Enjoy nearby shops, restaurants, and
      recreational facilities, along with flexible workspace solutions including private offices and
      coworking spaces. Benefit from comprehensive amenities such as reception services, high-speed
      internet, and a professional environment designed to support your business needs. Small and
      medium-sized businesses can benefit from our flexible private office solutions, offering 24/7
      access to workspace. Essential services like receptionist support, mail handling, printing, kitchen
      facilities, high-speed fiber optic internet, and reliable phone lines are available to support daily
      operations. Additionally, our virtual office plans provide a prestigious business address, business
      phone number, and access to conference rooms, meeting rooms, and notary services, without the
      requirement for physical office space.`,
      miniDescription: `Located in Boynton Beach, our space offers easy access to Interstate 95 (I-95) and Boynton Beach Blvd, connecting you to nearby cities and attractions. Enjoy nearby shops, restaurants, and recreational facilities, along with flexible workspace solutions including private offices and coworking spaces.`
  },
  {
    propertyName: 'Nexus at Vista Park',
    description: `Located in West Palm Beach, our space offers easy access to Florida's Turnpike and Okeechobee
      Blvd, connecting you to nearby communities and downtown. Enjoy nearby shops, restaurants,
      and entertainment options, along with flexible workspace solutions including private offices and
      coworking spaces. Benefit from comprehensive amenities such as reception services, high-speed
      internet, and a professional environment designed to enhance your business operations. Small
      and medium-sized businesses have the option to use private offices with flexible terms,
      providing round-the-clock access to workspace.We offer essential business services including
      receptionist support, mail handling, printing, kitchen facilities, high-speed fiber optic internet,
      and phone lines to assist with daily operations and growth. Additionally, our virtual office plans
      provide the prestige of a business address without the necessity of physical office space.
      Members also benefit from a dedicated business phone number, access to conference and
      meeting rooms, and notary services.`,
      miniDescription: `Located in West Palm Beach, our space offers easy access to Florida's Turnpike and Okeechobee Blvd, connecting you to nearby communities and downtown. Enjoy nearby shops, restaurants, and entertainment options, along with flexible workspace solutions including private offices and coworking spaces.`
  }
]