import { LocationOnOutlined, SearchSharp } from "@mui/icons-material"
import { Autocomplete, Box, Button, CircularProgress, Grid, InputAdornment, SxProps, TextField, Typography, debounce, styled } from "@mui/material"
import parse from 'autosuggest-highlight/parse'
import { Navigate } from "react-router-dom"
import nexusStarWhite from '../../assets/images/nexus-star-logo-white.png'
import { PlaceType } from "../../interfaces/google-maps-api-interface"

type PropType = {
  showSearch?: boolean
  sx?: SxProps,
  type?: string
}

const autocompleteRef: any = { current: null }, geocoderRef: any = { current: null }

const MUIAutoComplete = props => {
  const [currentLocation, setCurrentLocation] = useState<PlaceType | null>(null)
  const [value, setValue] = useState<PlaceType | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const [options, setOptions] = useState<readonly PlaceType[]>([])
  const [coordinates, setCoordinates] = useState<[]>([])

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteRef.current as any).getPlacePredictions(
            request,
            callback,
          )
        },
        400,
      ),
    [],
  )

  const getAutocompleteService = async () => {
    if (!autocompleteRef.current && window.google) {
      const { AutocompleteService } = await window.google.maps.importLibrary('places') as google.maps.PlacesLibrary
      autocompleteRef.current = new AutocompleteService()
    }
    if (!autocompleteRef.current) {
      return undefined
    }
  }

  const getGeocoder = async () => {
    if (!geocoderRef.current && window.google) {
      const { Geocoder } = await window.google.maps.importLibrary('geocoding') as google.maps.GeocodingLibrary
      geocoderRef.current = new Geocoder()
    }
  }

  const getPhysicalCoordinates = async (placeId: string) => {
    const response = await geocoderRef.current?.geocode({ placeId })
    setCoordinates(response.results.map(({ geometry }) => ({ lat: geometry.location.lat(), lng: geometry.location.lng() })))
  }

  useEffect(() => {
    getAutocompleteService()
    getGeocoder()

    if (props.location?.description) setValue(props.location?.description)
  }, [props.location?.description])

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  useEffect(() => {
    if (value !== null && (value.description !== undefined || value.description !== '' || value.description !== null) && (value as any).place_id) {
      const placeId = (value as any).place_id

      getPhysicalCoordinates(placeId)
      setCurrentLocation(value)
    }
  }, [value])

  useEffect(() => {
    if (coordinates.length > 0 && currentLocation !== null) {
      props.setLocationCoordinates(coordinates)
      props.setLocation(currentLocation)
    }
  }, [coordinates, currentLocation, props])

  return (
    <Autocomplete
      id="google-maps-autocomplete"
      sx={{ width: !props.showSearch ? '100%' : '85%', backgroundColor: 'white', borderRadius: '11px', ...props.sx }}
      getOptionLabel={(option) => typeof option === 'string' ? option : option.description}
      filterOptions={x => x}
      forcePopupIcon={false}
      options={options}
      clearOnBlur={false}
      includeInputInList
      filterSelectedOptions
      value={value || currentLocation}
      noOptionsText="No Locations"
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
      }}
      onInputChange={(event: any, newInputValue, reason) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField {...params} label="Search By City, State, or Zip" fullWidth InputProps={{
          ...params.InputProps,
          startAdornment: (
            <InputAdornment position='start'>
              <SearchSharp />
            </InputAdornment>
          )
        }} />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || []

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        )

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnOutlined sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}

export const SearchBar = (props: PropType) => {
  const [location, setLocation] = useState<PlaceType | null>(null)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [locationCoordinates, setLocationCoordinates] = useState<[]>([])
  const [timeToRedirect, setTimeToRedirect] = useState(false)

  const SearchButton = styled(Button)(() => ({}))
  const StyledDiv = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between'
  }))

  useEffect(() => {
    if (location !== null && locationCoordinates.length && !timeToRedirect) {
      setButtonLoading(true)
      setTimeout(() => {
        setTimeToRedirect(true)
        setButtonLoading(false)
      }, 2450)
    }
  }, [location, locationCoordinates])

  if (timeToRedirect) return <Navigate to='/locations/search' state={{ locationCriteria: location?.description, spaceType: props.type || 'Suites', locationCoordinates }} />

  return (
    <StyledDiv sx={{ padding: theme => theme.spacing(1.5), backgroundColor: !props.showSearch ? 'inherit' : '#e1eaee', display: 'flex', ...props.sx }}>
      <MUIAutoComplete setLocation={setLocation} setLocationCoordinates={setLocationCoordinates} location={location} sx={{ width: props.showSearch ? '75%' : '100%' }} />
      {props.showSearch &&
        <SearchButton startIcon={buttonLoading ? null : <img src={nexusStarWhite} alt='Nexus Star in Search Button' height={15} width={15} />} sx={{ padding: theme => theme.spacing(2),  borderRadius: '22.5px', display: 'flex', justifyContent: 'space-around' }} variant='contained'>
          {buttonLoading ? <CircularProgress variant='indeterminate' color='secondary' size={22.5} /> : 'Search'}
        </SearchButton>
      }
    </StyledDiv>
  )
}