import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Step, StepLabel, Stepper, TextField } from "@mui/material"
"react"
import { SPACE_TYPES } from "../../yardi-reference/SpaceType"
import { steps } from "./Bookings"
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const BookingStepper = ({ activeStep, criteria, setSearchCriteria }) => {
  const [inputValues, setInputValues] = useState<object>({ spaceType: '', date: '', location: '' })
  const spaceTypeOptions = SPACE_TYPES.map(space => ({ label: space.Name, value: space.Id }))

  const onSpaceChange = (event: SelectChangeEvent) => {
    setInputValues({ ...inputValues, spaceType: (event?.target as any).value })
  }

  const onDateChange = (newValue) => {
    setInputValues({ ...inputValues, date: newValue })
    setSearchCriteria(prev => ({ ...prev, bookingDate: newValue }))
  }

  const stepCompleted = (currentStepNo, mappedStepNo) => {
    if (currentStepNo > mappedStepNo) return true
    else return false
  }
  

  useEffect(() => {
    if (Object.entries(criteria).length) {
      setInputValues({ ...inputValues, spaceType: criteria.spaceType.id, location: criteria.locationCriteria, date: criteria.bookingDate })
    }
  }, [])

  return (
    <Box sx={{}}>
      <Stepper activeStep={activeStep.stepNo} sx={{ paddingTop: theme => theme.spacing(2), paddingBottom: theme => theme.spacing(2) }}>
        {steps.map(step => (
          <Step key={step.label} completed={stepCompleted(activeStep.stepNo, step.stepNo)} active={activeStep.stepNo === step.stepNo}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {Object.entries(inputValues).some(field => field.length) && activeStep.stepNo !== 3 &&
        <Box sx={{ display: 'flex', gap: '2rem', justifyContent: 'space-between' }}>
          <FormControl sx={{ paddingTop: theme => theme.spacing(2), paddingBottom: theme => theme.spacing(2), width: '100%' }}>
            <Select onChange={onSpaceChange} value={(inputValues as any).spaceType} required labelId='space-type-field' label="Space Type Required *" autoWidth>
              <InputLabel id='space-type-field'>Space Type Required *</InputLabel>
              {spaceTypeOptions.map(option => <MenuItem key={option.label} value={option.value}>{option.label}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl sx={{ paddingTop: theme => theme.spacing(2), paddingBottom: theme => theme.spacing(2), width: '100%' }}>
            <DatePicker label='Booking Date' value={(inputValues as any).date} onChange={onDateChange} />
          </FormControl>
          <FormControl sx={{ paddingTop: theme => theme.spacing(2), paddingBottom: theme => theme.spacing(2), width: '100%' }}>
            <TextField label='Location' required value={(inputValues as any).location} id='location-field' />
          </FormControl>
          <Button variant='outlined'>Search</Button>
        </Box>
      }
    </Box>
  )
}

export default BookingStepper