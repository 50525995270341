import { ReactElement, createContext } from 'react';

interface ParentProps {
  [key: string]: number;
}

interface ParentPropertiesContext {
  parentHeights: ParentProps;
  parentWidths: ParentProps;
  updateParentHeight: (parentId: string, height: number) => void;
  updateParentWidth: (parentId: string, width: number) => void;
}

const AppContext = createContext<ParentPropertiesContext | null>(null);

export const AppProvider: React.FC<any> = ({ children }) => {
  const [parentHeights, setParentHeights] = useState<ParentProps>({});
  const [parentWidths, setParentWidths] = useState<ParentProps>({});

  const updateParentHeight = (parentId: string, height: number) => {
    setParentHeights((prevHeights) => ({
      ...prevHeights,
      [parentId]: height,
    }));
  };

  const updateParentWidth = (parentId: string, width: number) => { 
    setParentWidths((prevHeights) => ({
      ...prevHeights,
      [parentId]: width,
    }));
  }

  return (
    <AppContext.Provider value={{ parentHeights, parentWidths, updateParentHeight, updateParentWidth }}>
      {children}
    </AppContext.Provider>
  );
};

export const useParentProperties = (): ParentPropertiesContext => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('useParentHeight must be used within a ParentHeightProvider')
  }
  return context;
};
