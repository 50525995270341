import { Button, Card, CardContent, Grid, IconButton, Popover, SxProps, Typography, Box, Dialog, useMediaQuery, Theme, DialogProps } from '@mui/material'
import { Star, Close, KeyboardDoubleArrowUp, Person, Wifi, Coffee, Style } from '@mui/icons-material'

import ImageGallery from './ImageGallery'
// import space1 from '../../assets/images/property-space-1.jpg'
// import space2 from '../../assets/images/property-space-2.jpg'
// import space3 from '../../assets/images/property-space-3.jpg'
// import space4 from '../../assets/images/property-space-4.jpg'
// import space5 from '../../assets/images/property-space-5.jpg'
import { useTheme } from '@emotion/react'
import useScreenSize from '../../react-hooks/screensize'
import { useNavigate } from 'react-router-dom'
import { SplitButton } from '../common/SplitButton'

interface ExpandedSidePanelProps {
  triggerId: string
  isExpanded: boolean
  onClose: () => void
  spaceName: string,
  city: string
}

export const ExpandableSidePanel: React.FC<ExpandedSidePanelProps> = ({ triggerId, isExpanded, onClose, spaceName, city }) => {
  const theme = useTheme() as Theme
  const fullscreen = useMediaQuery(theme.breakpoints.down('md'))
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('xl')
  const { width } = useScreenSize()
  const navigate = useNavigate()

  useEffect(() => {
    if (width < 600) {
      setMaxWidth('sm')
    } else if (width < 960) {
      setMaxWidth('md')
    } else if (width < 1280) {
      setMaxWidth('lg')
    } else {
      setMaxWidth('xl')
    }
  }, [width])

  const StyledHR = styled('hr')(() => ({
    height: '1px',
    borderRadius: '12px',
    borderColor: '#959595',
    backgroundColor: '#fff',
    opacity: 0.3,
    width: '100%'
  }))
  const Image = styled('img')(() => ({
    width: '100%',
    height: '500px'
  }))

  const handleOpenBooking = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if ((e.currentTarget as HTMLButtonElement).textContent === 'Get a Quote') {
      // Navigate to Contact Page (or whichever page form will be on)
      navigate('/contact')
    } else {
      // navigate('/booking', { state: { spaceInfo, propertyInfo, locationCriteria, price: (bookingInfo as any).price, bookingType: (bookingInfo as any).spaceBookingType, bookingDate: format(new Date(), 'MM/dd/yyyy') } })
      // const propertyId = property.Id
      // window.location.assign(`https://93840cgimg.yardikube.com/kubecc/content/#/app/space?companyid=132&proid=${propertyId}&spaceid=4`)
    }
  }

  // const spaceImages = [space1, space2, space3, space4, space5].map((src, index) => <Image src={src} key={`nexus-prop-image-${index}`} />)

  const PanelContent = React.forwardRef((props, ref) => {
    const PanelHeader = () => (
      <Box sx={{ padding: theme.spacing(2, 0) }}>
        <Grid container>
          <Grid item sm={9}>
            <Typography variant='h4'><strong>{spaceName}</strong></Typography>
            <Box sx={{ padding: theme => theme.spacing(3, 0), display: 'flex', alignItems: 'center', gap: theme => theme.spacing(1) }}>
              <Box sx={{ display: 'flex', alignSelf: 'center', gap: theme => theme.spacing(0.25) }}>
                <Star sx={{ color: 'gold' }} /> 4.9
              </Box>
              23 Reviews
            </Box>
          </Grid>
          <Grid item sm={3} sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', gap: theme => theme.spacing(1) }}>
            <SplitButton primary={<Button variant="contained" onClick={() => navigate('/contact')}>Get in Touch</Button>}
              secondary={<Button variant="outlined" onClick={() => navigate(`/locations?city=${city.toLowerCase()}`)}>Learn More</Button>}
            />
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    )

    const cardSx: SxProps = {
      border: 'solid 1px #d7d7d7',
      borderRadius: '12px',
      padding: '12px',
      marginBottom: '2rem',
      height: '100%'
    }

    const PanelCard = ({ workspaceName, description, price, features, buttonLabel }) => {
      return (
        <Card sx={cardSx} {...props} elevation={0}>
          <CardContent>
            <Box>
              <Grid container>
                <Grid item sm={12}>
                  <Typography variant="h5">{workspaceName}</Typography>
                  <Typography variant="subtitle2">{description} <Button variant='text'>{buttonLabel}</Button></Typography>
                </Grid>
              </Grid>
              <StyledHR />
              <Grid container>
                <Grid item sm={10}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {features.map((feature, index) => (
                      <Box key={`feature-${index}`} sx={{ display: 'flex', padding: theme => theme.spacing(.75, 0), gap: '1rem' }}>
                        {feature.icon}
                        <Typography>{feature.text}</Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box sx={{ padding: theme => theme.spacing(2, 0) }}>
                    {workspaceName === 'Event Spaces' ? <Typography variant='h6'>Price will vary, click on the 'Get a Quote' button to find out more!</Typography> : <Typography variant='h6'>From {price}</Typography>}
                    <Typography variant='body2'>Plus applicable taxes</Typography>
                  </Box>
                </Grid>
                <Grid item sm={2} sx={{ alignContent: 'center', justifyContent: 'center' }}>
                  <Button variant="contained" onClick={handleOpenBooking} sx={{ borderRadius: '22.5px' }}>Get a Quote</Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      )
    }

    const StyledCard = styled(PanelCard)(() => ({}))

    const PanelBody = () => (
      <Box>
        <ImageGallery centerMode centerSlidePercentage={100} showStatus={false} sx={{}}>
          {/* {spaceImages} */}
        </ImageGallery>
        <Box>
          <Typography variant="h5"><strong>Available Spaces and Solutions</strong></Typography>
          <Box sx={{ padding: theme => theme.spacing(2, 0) }}>
            <Grid container spacing={3} direction="row">
              <Grid item xs={12} sm={6}>
                <StyledCard
                  workspaceName="Workspace for month or year"
                  description="Book access to coworking space or a private office for the day"
                  price="$100 / Month"
                  buttonLabel="Learn More"
                  features={[
                    { icon: <Person />, text: 'For individuals and small teams' },
                    { icon: <Wifi />, text: 'Fast Wifi' },
                    { icon: <Coffee />, text: 'Unlimited coffee and tea' }
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledCard
                  workspaceName="Meeting Rooms"
                  description="Book conference rooms by the hour for groups of all sizes"
                  price="$100 / Person / Month"
                  buttonLabel="Learn More"
                  features={[
                    { icon: <Person />, text: 'For small - medium sized teams' },
                    { icon: <Wifi />, text: 'Fast Wifi' },
                    { icon: <Coffee />, text: 'Unlimited coffee and tea' }
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledCard
                  workspaceName="Event Spaces"
                  description="Book a space for your next event, meeting or presentation"
                  price="Price will vary, get a quote for more information"
                  buttonLabel="Learn More"
                  features={[
                    { icon: <Person />, text: 'For groups and events with more than 20 people' },
                    { icon: <Wifi />, text: 'Fast Wifi' },
                    { icon: <Coffee />, text: 'Unlimited coffee and tea' }
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledCard
                  workspaceName="Wellness Rooms"
                  description="Book a private space for wellness, meditation or relaxation"
                  price="$100 / Person / Month"
                  buttonLabel="Learn More"
                  features={[
                    { icon: <Person />, text: 'For individuals and small teams' },
                    { icon: <Wifi />, text: 'Fast Wifi' },
                    { icon: <Coffee />, text: 'Unlimited coffee and tea' }
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    )

    let sxProps: SxProps = {
      padding: '2rem',
      margin: '1rem'
    }

    return (
      <Box ref={ref} sx={sxProps}>
        <PanelHeader />
        <PanelBody />
      </Box>
    )
  })



  return (
    <Dialog
      id={triggerId}
      open={isExpanded}
      onClose={onClose}
      fullScreen={fullscreen}
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      maxWidth={maxWidth}
    >
      <PanelContent />
    </Dialog>
  )
}