import { FormatQuoteRounded } from '@mui/icons-material'
import { Box, Divider, Grid, LinearProgress, Rating, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import { AnimatePresence, Variants, motion } from 'framer-motion'
import React, { useState } from 'react'
import tileImg3 from '../../../assets/images/dedicated-desk-img2.jpg'
import tileImg2 from '../../../assets/images/divers-group-people-is-working-office.jpg'
import nexusBlue from '../../../assets/images/logo-transparency-05.png'
import tileImg1 from '../../../assets/images/two-male-colleague-working-office.jpg'
import { Watermark } from '../../common/Watermark'

interface Testimonial {
  name: string
  testimonial: string
  img?: string,
  stars: number,
  dateOfReview: string,
  location: string
}

const testimonials: Testimonial[] = [
  {
    name: "Dana Smith",
    stars: 5,
    dateOfReview: "2 years ago",
    testimonial: "This is a beautiful professional building in Stuart, FL which I am happy to work at. Front desk staff is courteous and always helpful. Upkeep of the building maintained by Mr. Lewis Bennett must be a big job but he always does every task handed to him with a smile and always lends a helping hand when needed even if you didn't ask him too. Leanna puts great events together for all the tenants, which is something to look forward to. If you have to go to an office to work, this is the place to be.",
    location: "Nexus at Stuart"
  },
  {
    name: "MIchael Koval",
    stars: 5,
    dateOfReview: "3 years ago",
    testimonial: "Living in Boca, having my office just a few minutes away is such a blessing.  I used to travel 90 minutes each way and now it's just 10.  I picked my space with the professional staff and they toured me on my schedule.  After I found the perfect suite, it was a breeze to move in.  I park in the huge lot just 50 feet from my office.  On my in to my space there is a FedEx and UPS box which is perfect for all of my Amazon returns.  Saves me so much time.  My space is perfect, very quiet, and the staff is fantastic.  Been with Nexus since Covid started and won't ever leave. Thanks Nexus team for making my day and my work environment a great experience.",
    location: "Nexus at Boca Raton",
  },
  {
    name: "SailCamp USA",
    stars: 4,
    dateOfReview: "3 months ago",
    testimonial: "I just wanted to take a moment and thank the staff at the Stuart Nexis Location.  Ingrid is always up front with a smile and helpful when our clients come in to see us for an appointment. My Main Man Louis helps us all the time with maintaining the space and helping set up office space as we have grown and require larger space within the building Julliette has helped us with getting proposals and helps to let us know when opportunities for office space open up in the building. We at Gornitzky Sage Wealth Management really like the building and the staff and the tenants.  The Building is always clean and the site is well maintains and I promise myself I will start using the amazing Gym.",
    location: "Nexus at Stuart"
  },
  {
    name: "Patricia Olsen",
    stars: 5,
    location: "Nexus at Vista Park",
    testimonial: "Have office space and conference rooms available to me to hold business events. This place is beautiful and very clean. A pleasure to invite my clients to meet here.",
    dateOfReview: "5 years ago"
  },
  {
    name: "Paul Abbott",
    location: "Nexus at Boynton Beach",
    dateOfReview: "2 months ago",
    testimonial: "This location is absolutely perfect for a private workspace, meeting areas, and conference room space.The staff are amazingly helpful and supportive to all tenants.The facility is spotless and kept up extremely well.If you are looking for private office/ meeting space and a friendly and efficient support team this is the place to go! They also have great amenities, and everything needed to run an office.If you are looking for office space, then make sure to take a tour and meet the Nexus Boynton Beach team!",
    stars: 5
  },
]

const Testimonials: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [progress, setProgress] = useState(0)
  const theme = useTheme() as Theme
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'))

  useEffect(() => {
    const testimonialInterval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length)
      setProgress(0)
    }, 10000)

    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1))
    }, 100)

    return () => {
      clearInterval(testimonialInterval)
      clearInterval(progressInterval)
    }
  }, [])

  const variants = {
    enter: {
      x: 1000,
      opacity: 0,
      scale: 0.8,
    },
    center: {
      x: 0,
      opacity: 1,
      scale: 1,
    },
    exit: {
      x: -1000,
      opacity: 0,
      scale: 0.8,
    },
  } as Variants

  const padding = isMobile ? theme.spacing(2, 2) : theme.spacing(6, 12, 6, 9)

  return (
    <Box
      sx={{
        gap: '1.5rem',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
        width: '100vw',
        padding: '0px 24px',
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
        '.MuiGrid-item': {
          paddingTop: '0px !important',
        }
      }}>
      {!isMobile && <Watermark sx={{ backgroundImage: `url(${nexusBlue})`, top: '10%', left: '-250px', opacity: 1 }} />}
      <Grid container spacing={10}>
        <Grid item xs={12} lg={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '100%', minHeight: '400px', padding }}>
            <Typography variant="h3">
              <strong>Testimonials</strong>
            </Typography>
            <Typography variant="h5">
              <strong>What Our Members Are Saying</strong>
            </Typography>
            <Divider sx={{ color: theme.palette.primary.light }} />
            <Typography variant="subtitle1">
              We place huge value on strong relationships and have seen the benefit they bring to our business. Hear from our
              members about their experience at Nexus Workspaces.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: theme.palette.primary.light,
              // borderRadius: '16px',
              padding: theme.spacing(3.5, 3.5, 7),
              flex: '1',
              width: '100%',
              minHeight: '200px',
              height: '100%',
              flexWrap: 'wrap',
              gap: '1rem',
            }}
          >
            <AnimatePresence mode='wait'>
              <motion.div
                key={`${testimonials[activeIndex].name}-${activeIndex}`}
                variants={variants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{
                  x: { type: 'spring', stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                  scale: { duration: 0.2 },
                }}
                style={{
                  display: 'flex',
                  gap: '1rem',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3} md={3}>
                    <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {testimonials[activeIndex].img ? (
                        <img
                          src={testimonials[activeIndex].img}
                          alt="Avatar"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain'
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            width: '100px',
                            height: '100px',
                            backgroundColor: '#ccc',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '50px',
                          }}
                        >
                          {testimonials[activeIndex].name.charAt(0).toUpperCase()}
                        </div>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9}>
                    <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', '& > *': { color: 'white' }, justifyContent: 'center' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row', gap: 2 }}>
                        <Box>
                          <Typography variant="h6">{testimonials[activeIndex].name}</Typography>
                          <Typography variant="subtitle2" sx={{}}>
                            {testimonials[activeIndex].location}
                          </Typography>
                          <Typography variant="subtitle2" sx={{}}>
                            {testimonials[activeIndex].dateOfReview}
                          </Typography>
                        </Box>
                        <Rating value={testimonials[activeIndex].stars} readOnly />
                      </Box>
                      <Typography variant="subtitle1" sx={{ marginTop: '16px' }}>
                        <FormatQuoteRounded
                          sx={{ color: theme => theme.palette.secondary.main, transform: 'rotateY(180deg)' }}
                        />
                        {testimonials[activeIndex].testimonial}
                        <FormatQuoteRounded sx={{ color: theme => theme.palette.secondary.main }} />
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </motion.div>
            </AnimatePresence>
            <Box sx={{ display: 'flex', position: 'relative', flexGrow: 1 }}>
              {testimonials.map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    width: `${100 / testimonials.length}%`,
                    position: 'relative',
                    '&:after': {
                      content: '""',
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      bottom: 0,
                      width: '2px',
                      height: '4px',
                      backgroundColor: 'black',
                      display: index === testimonials.length - 1 ? 'none' : 'block',
                    },
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={activeIndex > index ? 100 : activeIndex === index ? progress : 0}
                    color='secondary'
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Testimonials
