import { Route, Routes as ReactRoutes } from 'react-router-dom'
import AboutNexus from './about/About'
import Bookings from './bookings/Bookings'
import ContactUs from './contact-us/ContactUs'
import Home from "./home/Home"
import Locations from './locations/Locations'
import LocationsSearch from "./locations/LocationsSearch"
import MainSolutions from './solutions/MainSolutions'

const Routes = () => {
  const ROUTES = [
    { path: '/', component: <Home />, key: 'home' },
    { path: '/locations', component: <Locations />, key: 'locations' },
    { path: '/locations/search', component: <LocationsSearch />, key: 'locations-search' },
    { path: '/booking', component: <Bookings />, key: 'booking' },
    { path: '/solutions', component: <MainSolutions />, key: 'solutions' },
    { path: '/contact', component: <ContactUs />, key: 'contact' },
    { path: '/about', component: <AboutNexus />, key: 'about' }
  ]

  return (
    <ReactRoutes>
      {ROUTES.map(r => <Route {...r} path={r.path} element={r.component} />)}
    </ReactRoutes>
  )
}

export default Routes