import { Box, Button, CircularProgress, FormControl, FormHelperText, Grid, TextField, Typography, styled } from "@mui/material"
import nexusStar from '../../../assets/images/nexus-star-logo-white.png'
import { ArrowBackIos, Troubleshoot } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
"react"
import { isEmpty } from "lodash"

const Image = styled('img')(() => ({
  // width: '100%'
}))

const ReservedSpace = ({ reservedSpace, setCurrentStep, setFlowPath }) => {
  const navigate = useNavigate()
  const isComponentReady = reservedSpace.bookingDate && reservedSpace.propertyInfo && reservedSpace.spaceDetails

  return isComponentReady ? (
    <Box sx={{ height: '100vh', padding: theme => theme.spacing(2.25) }}>
      <Button variant='outlined' size='medium' startIcon={<ArrowBackIos />} onClick={() => setCurrentStep(2)}>Back to Results</Button>
      <Grid container sx={{ paddingTop: theme => theme.spacing(2.5) }} spacing={5}>
        <Grid item sm={6}>
          <Box sx={{ backgroundColor: theme => theme.palette.primary.main, padding: theme => theme.spacing(4) }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: theme => theme.spacing(3) }}>
              <Typography variant="h5" color={"white"}><strong>Reserved Space</strong></Typography>
              <Image src={nexusStar} width='auto' />
            </Box>
            <Box sx={{ backgroundColor: '#f3f3f3', padding: theme => theme.spacing(2) }}>
              <Grid container sx={{ overflow: 'hidden', borderBottom: '0.5px solid #c9c9c9', padding: theme => theme.spacing(2, 0) }}>
                <Grid item xs={5} sx={{}}>
                  <Image src={reservedSpace.spaceDetails.gallery[0].Url} width='100%' />
                </Grid>
                <Grid item xs={7} sx={{}}>
                  <Box sx={{ borderBottom: '0.5px solid #c9c9c9', paddingBottom: theme => theme.spacing(1) }}>
                    <Typography variant="subtitle1">{reservedSpace.propertyInfo.city}, {reservedSpace.propertyInfo.state} - {reservedSpace.propertyInfo.propertyName}</Typography>
                    <Typography variant="subtitle2">{reservedSpace.spaceDetails.spaceName}</Typography>
                    <Typography variant="body2">{reservedSpace.bookingDate}</Typography>
                  </Box>
                  <Box sx={{ paddingTop: theme => theme.spacing(1) }}>
                    <Typography variant='body2'>{reservedSpace.propertyInfo.propertyName}</Typography>
                    <Typography variant='body2'>{reservedSpace.propertyInfo.address1}</Typography>
                    <Typography variant='body2'>{reservedSpace.propertyInfo.city}, {reservedSpace.propertyInfo.state}</Typography>
                    <Typography variant='body2'>{reservedSpace.propertyInfo.zip}</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container sx={{ borderBottom: '0.5px solid #c9c9c9', padding: theme => theme.spacing(2, 0) }}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">{reservedSpace.spaceDetails.spaceName}</Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'end' }}>
                  <Typography variant="subtitle2">{reservedSpace.price}</Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ padding: theme => theme.spacing(2, 0) }}>
                <Grid item xs={9}>
                  <Typography variant="subtitle1">Total:</Typography>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: 'end' }}>
                  {(reservedSpace.price as string).replace(' / Day', '')}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', borderBottom: '0.5px solid #c9c9c9', paddingBottom: theme => theme.spacing(2) }}>
            <Typography variant="h5">Already a Nexus Member? Sign In Below</Typography>
            <Button variant="contained" color="primary" onClick={() => { navigate('/sign-in', { state: { flowPath: 'login' } }) }} sx={{ maxWidth: '16rem' }}>Nexus Member Login</Button>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', paddingTop: theme => theme.spacing(2) }}>
            <Typography variant="h6">Create a new account with Nexus</Typography>
            <Button variant="outlined" size="medium" onClick={() => { setFlowPath('create') }} sx={{ maxWidth: '16rem' }}>Create Account</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  ) : <></>
}

const CheckoutFlow = ({ flowPath, reservedSpace }) => {
  const [loading, setLoading] = useState(false)
  const [newAccountInfo, setNewAccountInfo] = useState<object | null>(null)
  const [currentFlowStep, setCurrentFlowStep] = useState(1)

  const FlowStep1 = () => {
    const [email, setEmail] = useState('')
    const [disabled, setDisabled] = useState(true)
    const onChange = event => setEmail(event.target.value)

    useEffect(() => {
      if (email.length) setDisabled(false)
    }, [email])

    return (
      <Box sx={{ display: "flex", flexDirection: 'column', gap: '1rem', justifyContent: 'space-between'  }}>
        <Typography variant="h4">Please enter your email address</Typography>
        <Box sx={{display: 'flex', gap: theme => theme.spacing(5), maxWidth: '70%'  }}>
          <TextField label='Email Address' variant="outlined" required onChange={onChange} fullWidth />
          <Button onClick={() => { setLoading(true); setNewAccountInfo({ email }); setCurrentFlowStep(2); }} variant="contained" disabled={disabled}>Continue</Button>
        </Box>
      </Box>
    )
  }

  const FlowStep2 = () => {
    const [fieldValues, setFieldValues] = useState({})
    const [fieldProps, setFieldProps] = useState({})
    const [disabled, setDisabled] = useState(true)

    const validate = (key, value, label) => {
      let error, helperText
      if (key === 'address2' && !value) {}
      else if (!value) {
        error = true
        helperText = `${label} can't be empty`
      }
      else {
        const badChars = '!@#$%^&*()<>?":;+=_~`'
        switch (key) {
          case 'firstName':
          case 'lastName':
            const badNameChars = '1234567890/*+!@#$%^*()_='
            let hasBadName = false
            for (const char of badNameChars) { if (value.includes(char)) hasBadName = true }
            if (hasBadName) {
              error = true
              helperText = `${label} should not have any of these characters - ${badNameChars}`
            }
            break
          case 'address1':
          case'address2':
            const badAddressChars = '!@$%^&*+="?><`~_'
            let hasBadAddress = false
            for (const char of badAddressChars) {
              if (value.includes(char)) hasBadAddress = true
            }
            if (hasBadAddress) {
              error = true
              helperText = `${label} should not have any of these characters - ${badAddressChars}`
            }
            break
          case 'city':
            let hasBadCity = false
            for (const char of badChars) {
              if (value.includes(char)) hasBadCity = true
            }
            if (hasBadCity) {
              error = true
              helperText = `${label} should not have any of these characters - ${badChars}`
            }
            break
          case 'state':
            let hasBadState = false
            for (const char of badChars) {
              if (value.includes(char)) hasBadState = true
            }
            if (hasBadState) {
              error = true
              helperText = `${label} should not have any of these characters - ${badChars}`
            }
            break
          case 'zip':
            if ((value as number).toString().length === 0 || (value as number).toString().length !== 5) {
              error = true
              helperText = `${label} is either not a 5-digit number or has more than 5 digits`
            }
            break
          default:
            break
        }
      }

      const allFieldsErrors = Object.entries(fieldProps)
      let sanitizedFieldProps = {}
      if (allFieldsErrors.length && !error && !helperText) {
        sanitizedFieldProps = allFieldsErrors.reduce((acc, curr) => {
          const [fieldKey, fieldValue] = curr

          if (fieldKey === key && !error && !helperText && (fieldValue as any).error && (fieldValue as any).helperText) {}
          else acc = { [fieldKey]: fieldValue }
          return acc
        }, {})
        setFieldProps(sanitizedFieldProps)
      }
      else if (error && helperText) {
        setFieldProps({
          [key]: {
            error,
            helperText
          }
        })
      }
    }

    const onFieldChange = event => {
      const key = event.target.id, value = event.target.value
      const label = (event.target as HTMLElement).parentElement?.previousElementSibling?.textContent?.replace('*', '')
      validate(key, value, label)
      setFieldValues(prev => ({ ...prev, [key]: value }))
    }

    const onSubmit = event => {
      event.preventDefault()
      event.stopPropogation()
    }

    useEffect(() => {
      if (isEmpty(fieldProps)) {
        let readyToSubmit = !!(fieldValues as any)?.firstName && !!(fieldValues as any)?.lastName && !!(fieldValues as any)?.city && !!(fieldValues as any)?.state && !!(fieldValues as any)?.zip && !!(fieldValues as any)?.address1
        if (readyToSubmit) setDisabled(false)
      } else setDisabled(true)
    }, [fieldProps, fieldValues])

    return (
      <Box sx={{ display: "flex", flexDirection: 'column', gap: theme => theme.spacing(4) }}>
        <Typography variant="h4">Enter Your Details</Typography>
        <FormHelperText>Below, please enter the corresponding information for your new Nexus Account.</FormHelperText>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme => theme.spacing(4), paddingTop: theme => theme.spacing(2) }}>
          <Box sx={{ display: 'flex', gap: theme => theme.spacing(2) }}>
            <FormControl fullWidth>
              <TextField label='First Name' variant="outlined" required onChange={onFieldChange} id="firstName" error={(fieldProps as any)?.firstName?.error} helperText={(fieldProps as any)?.firstName?.error && (fieldProps as any)?.firstName?.helperText} />
            </FormControl>
            <FormControl fullWidth>
              <TextField label='Last Name' variant="outlined" required onChange={onFieldChange} id="lastName" error={(fieldProps as any)?.lastName?.error} helperText={(fieldProps as any)?.lastName?.error && (fieldProps as any)?.lastName?.helperText} />
            </FormControl>
          </Box>
          <Box>
            <TextField label='Email Address' variant="outlined" value={(newAccountInfo as any).email} fullWidth disabled />
          </Box>
          <Box sx={{ display: 'flex', gap: theme => theme.spacing(2) }}>
            <FormControl fullWidth>
              <TextField label='Address 1' variant="outlined" required onChange={onFieldChange} id='address1' error={(fieldProps as any)?.address1?.error} helperText={(fieldProps as any)?.address1?.error && (fieldProps as any)?.address1?.helperText} />
            </FormControl>
            <FormControl fullWidth>
              <TextField label='Address 2' variant="outlined" onChange={onFieldChange} id='address2' error={(fieldProps as any)?.address2?.error} helperText={(fieldProps as any)?.address2?.error && (fieldProps as any)?.address2?.helperText} />
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', gap: theme => theme.spacing(2) }}>
            <FormControl fullWidth>
              <TextField label='City' variant="outlined" required onChange={onFieldChange} id='city' error={(fieldProps as any)?.city?.error} helperText={(fieldProps as any)?.city?.error && (fieldProps as any)?.city?.helperText} />
            </FormControl>
            <FormControl fullWidth>
              <TextField label='State' variant="outlined" required onChange={onFieldChange} id='state' error={(fieldProps as any)?.state?.error} helperText={(fieldProps as any)?.state?.error && (fieldProps as any)?.state?.helperText} />
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth>
              <TextField label='Zip Code' variant="outlined" required onChange={onFieldChange} id="zip" error={(fieldProps as any)?.zip?.error} helperText={(fieldProps as any)?.zip?.error && (fieldProps as any)?.zip?.helperText} />
            </FormControl>
          </Box>
          <Button variant="contained" onChange={onSubmit} disabled={disabled}>Next</Button>
        </Box>
      </Box>
    )
  }

  const FlowStep3 = () => {
    return (
      <></>
    )
  }

  useEffect(() => {
    if (loading) setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [loading])

  const Summary = () => {
    return (
      <Box sx={{ backgroundColor: theme => theme.palette.primary.main, padding: theme => theme.spacing(2.5), display: 'flex', gap: '1rem', flexDirection: 'column' }}>
        <Typography variant="h5" color='white'>Booking Summary</Typography>
        <Box sx={{ backgroundColor: '#f3f3f3', padding: theme => theme.spacing(2) }}>
          <Box sx={{ borderBottom: '0.5px solid #c9c9c9' }}>
            <Box sx={{ overflow: 'hidden', margin: 'auto' }}><Image src={reservedSpace.spaceDetails.gallery[0].Url} width='auto' /></Box>
            <Typography variant="subtitle1">{reservedSpace.spaceDetails.spaceName}</Typography>
            <Typography variant="subtitle2">{reservedSpace.bookingDate}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1">{reservedSpace.propertyInfo.city}, {reservedSpace.propertyInfo.state} - {reservedSpace.propertyInfo.propertyName}</Typography>
            <Typography variant="subtitle2">{reservedSpace.propertyInfo.address1}</Typography>
            {reservedSpace.propertyInfo.address2 ? <Typography variant="subtitle2">{reservedSpace.propertyInfo.address2}</Typography> : null}
            <Typography variant="subtitle2">{reservedSpace.propertyInfo.city}</Typography>
            <Typography variant="subtitle2">{reservedSpace.propertyInfo.zip}</Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <>
      {loading && <Box sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center', justifyContent: 'space-around', height: '50vh' }}><CircularProgress variant="indeterminate" size={125} color="primary" /></Box>}
      {!loading &&
        <Box sx={{ paddingTop: theme => theme.spacing(3) }}>
          <Grid container spacing={10}>
            <Grid item xs={9}>
              <Box sx={{ borderBottom: '0.5px solid #c9c9c9', paddingBottom: theme => theme.spacing(3) }}>
                <Typography variant="h5">Checkout Process: {flowPath === 'create' ? 'Create Account' : ''}</Typography>
              </Box>
              <Box sx={{ paddingTop: theme => theme.spacing(3), maxWidth: '60%' }}>
                {flowPath === 'login' && <FlowStep3 />}
                {flowPath === 'create' ?
                  <>
                    {currentFlowStep === 1 && <FlowStep1 />}
                    {currentFlowStep === 2 && <FlowStep2 />}
                    {currentFlowStep === 3 && <FlowStep3 />}
                  </>
                  : null}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Summary />
            </Grid>
          </Grid>
        </Box>
      }
    </>
  )
}


const Step3 = ({ reservedSpace, setCurrentStep, sx = {} }) => {
  const [currentFlowPath, setCurrentFlowPath] = useState('')

  if (currentFlowPath.length) return <CheckoutFlow reservedSpace={reservedSpace} flowPath={currentFlowPath} />
  else return <ReservedSpace reservedSpace={reservedSpace} setCurrentStep={setCurrentStep} setFlowPath={setCurrentFlowPath} />
}

export default Step3