import { Box, Typography } from "@mui/material"

// First step in Booking Process (will be skipped if coming from locations page)
const Step1 = () => {

  return (
    <Box>
      <Typography variant="h4">Please fill out search above to find available Nexus Spaces near you!</Typography>
    </Box>
  )
}

export default Step1