import { useTheme } from '@emotion/react'
import { Box, Button, List, ListItem, ListItemButton, Menu, MenuItem, Paper, Theme, Typography, styled } from '@mui/material'

import { useQuery } from '@apollo/client'
import { KeyboardArrowDown, Speaker, VolumeOff, VolumeUp } from '@mui/icons-material'
import { animate, motion, useMotionValue, useTransform } from 'framer-motion'
import bannerVideo from '../../assets/videos/temp-banner-vid.mp4'
import NexusStarWhite from '../../assets/images/nexus-star-allwhite.png'
import MeetingRoomIcon from '../../assets/images/meeting-room.svg?url'
import OfficeBuildingIcon from '../../assets/images/office-building-1.svg?url'
import VirtualIcon from '../../assets/images/video-conference.svg?url'
import CoworkingIcon from '../../assets/images/working.svg?url'
import { VideoUrl } from '../../gql/graphql'
import { getVideoUrls } from '../../gql/queries'
import useScreenSize from '../../react-hooks/screensize'
import { SearchBar } from '../location-search/SearchBar'

const spaceOptionsData = [
  {
    type: 'Suites',
    title: 'Executive Suites',
    subtitle: 'Your own personal desk or drop-in & hotdesk. We\'re flexible.',
    icon: OfficeBuildingIcon
  },
  {
    type: 'Dedicated',
    title: 'Dedicated Desk',
    subtitle: 'Flexible shared workspaces for collaboration and networking.',
    icon: CoworkingIcon
  },
  {
    type: 'Virtual',
    title: 'Virtual Offices',
    subtitle: 'Professional business address and virtual office services.',
    icon: VirtualIcon
  },
  {
    type: 'Meeting',
    title: 'Meeting Room',
    subtitle: 'Book meeting rooms for presentations, interviews, and more.',
    icon: MeetingRoomIcon
  }
]

const SpaceOptions = (props) => {
  const specificSpaceType = spaceOptionsData.find(option => option.type === props.spaceType)
  const [optionSelected, setOptionSelected] = useState({ type: props.spaceType, ...specificSpaceType })
  const [iconImageProps, setIconImageProps] = useState({ height: '24px', width: '24px' })
  const [menuWidth, setMenuWidth] = useState(0)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const mobileButtonRef = useRef<HTMLButtonElement>(null)
  const selectedButtonRef = useRef<HTMLDivElement>(null)
  const open = Boolean(anchorEl)
  const theme = useTheme() as Theme
  const { values } = theme.breakpoints
  const { width, height } = useScreenSize()
  const isXs = width <= values.xs, isSm = width <= values.sm, isMd = width <= values.md, isLg = width <= values.lg, isXl = width <= values.xl
  const isMobile = isXs || isSm || isMd, isNonMobile = isLg || isXl

  const handleOptionItemSelected = (type) => {
    setOptionSelected({ type })
    props.setSpaceType({ type })
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    const spaceInfo = spaceOptionsData?.find(option => option.title === event.currentTarget.textContent)
    setOptionSelected({ type: spaceInfo?.type as string, ...spaceInfo })
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const OptionItem = ({ icon, onClick, title }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <ListItem onClick={onClick}>
        <ListItemButton sx={{ display: 'flex', gap: '10px', alignItems: 'center', padding: 2 }}>
          <img src={icon} alt={icon} height={iconImageProps.height} width={iconImageProps.width} />
          <span style={{ paddingTop: '5px', fontSize: '12.5px', fontWeight: '800', whiteSpace: 'nowrap' }}>{title}</span>
        </ListItemButton>
      </ListItem>
    </Box>
  )

  useEffect(() => {
    setMenuWidth(mobileButtonRef.current?.offsetWidth as number)
  }, [width])

  if (isMobile) {
    return (
      <>
        <Button variant='outlined' sx={{ width: '100%', borderRadius: '0px', marginBottom: '8px', display: 'flex', justifyContent: 'space-between' }} onClick={handleClick} endIcon={<KeyboardArrowDown />} startIcon={<img src={optionSelected.icon} width={28} height={28} />} ref={mobileButtonRef}><span style={{ fontSize: '12.5px' }}>{optionSelected.title}</span></Button>
        <Menu open={open} onClose={handleClose} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} anchorEl={anchorEl} sx={{ [`& .MuiPopover-paper`]: { width: `${menuWidth}px` } }}>
          {spaceOptionsData.map(option => {
            if (option.type === optionSelected.type) return null
            else return (
              <MenuItem key={option.type} onClick={event => { handleClick(event); handleClose() }}>
                {option.title}
              </MenuItem>
            )
          })}
        </Menu>
      </>
    )
  }

  return (
    <List sx={{ display: 'flex', justifyContent: 'center' }}>
      {spaceOptionsData.map(option => (
        <OptionItem key={option.type} icon={option.icon} title={option.title} onClick={() => handleOptionItemSelected(option.type)} />
      ))}
    </List>
  )
}

const TypedText = () => {
  const [done, setDone] = useState(false);
  const [baseTexts, setBaseTexts] = useState([
    'Exceling in your work begins by thriving in your space.'
  ]);
  const count1 = useMotionValue(0);
  const count2 = useMotionValue(0);
  const rounded1 = useTransform(count1, latest => Math.round(latest));
  const rounded2 = useTransform(count2, latest => Math.round(latest));
  const displayedText1 = useTransform(rounded1, latest => baseTexts[0].slice(0, latest));
  const displayedText2 = useTransform(rounded2, latest => {
  // Ensure baseTexts[1] is defined before attempting to access it
  if (baseTexts.length > 1 && baseTexts[1]) {
    return baseTexts[1].slice(0, latest);
  }
  return '';
});

const runAnimation = (duration: number = 4) => {
  const animation1 = animate(count1, baseTexts[0].length, {
    duration,
    ease: 'linear',
    onComplete: () => setDone(true)
  });

  // Calculate delay for the second animation based on the duration of the first
  const delayForSecondAnimation = duration; // This ensures the second animation waits for the first to complete

  // Check if baseTexts[1] is defined before creating animation2
  const animation2 = baseTexts[1] ? animate(count2, baseTexts[1].length || 0, {
    duration,
    ease: 'linear',
    onComplete: () => setDone(true),
    delay: delayForSecondAnimation // Use calculated delay here
  }) : null;

  if (!animation2) return { stop: () => animation1.stop() };
  return { stop: () => { animation1.stop(); animation2?.stop(); } };
};

  useEffect(() => {
    count1.set(0);
    count2.set(0);
    const animation = runAnimation();

    return () => animation.stop();
  }, [baseTexts]);

  useEffect(() => {
    if (done) setTimeout(() => setBaseTexts([
      'When you\'re located at the Nexus of something,',
      'you\'re at the innovative center of everything.'
    ]), 1000)
  }, [done])

  return (
    <Typography variant='h6' sx={{ color: 'white', textAlign: 'center', fontWeight: '800', fontSize: '24px' }}>
      <div>
        <motion.span>{displayedText1}</motion.span>
        {baseTexts[1] && (
          <>
            <br />
            <motion.span>{displayedText2}</motion.span>
          </>
        )}
      </div>
    </Typography>
  );
}
export const SearchBarBanner = () => {
  const [imageWidth, setImageWidth] = useState(0)
  const [imageHeight, setImageHeight] = useState(850)
  const [isSelected, setIsSelected] = useState({ type: 'Suites' })
  const [bannerSrc, setBannerSrc] = useState('')
  const [bannerInfo, setBannerInfo] = useState({ title: '', subtitle: '' })
  const [loaded, setLoaded] = useState(false)
  const theme = useTheme() as Theme
  const { width, height } = useScreenSize()
  const { values } = theme.breakpoints
  const { data, loading, error } = useQuery(getVideoUrls)
  const searchFieldRef = useRef<HTMLDivElement>(null)
  const divRef = useRef<HTMLDivElement>(null)
  const [muted, setMuted] = useState(true)

  const isXs = width <= values.xs, isSm = width <= values.sm, isMd = width <= values.md, isLg = width <= values.lg, isXl = width <= values.xl
  const isMobile = isXs || isSm || isMd, isNonMobile = isLg || isXl

  useEffect(() => {
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (isSelected.type) {
      const selected = spaceOptionsData.find(option => option.type === isSelected.type)
      setBannerInfo({ title: selected?.title as string, subtitle: selected?.subtitle as string })
    }
  }, [isSelected.type])

  useEffect(() => {
    if (data?.getVideoUrls) setBannerSrc((data.getVideoUrls as VideoUrl[]).find(video => video.key === 'homepage-hero.mov')?.url as string)
  }, [data])

  useEffect(() => {
    if (isMobile) setImageWidth(width)
    else if (isNonMobile) setImageWidth(width - 300)
  }, [width])

  const SearchField = styled(Box)(({ theme }) => ({
    position: 'relative',
    bottom: `${(searchFieldRef.current as HTMLElement)?.clientHeight / 2}px`,
    height: '30%',
    width: isMobile ? width * .8 : '760px',
    maxWidth: isMobile ? 'none' : '880px',
    borderRadius: '16px'
    
  }))
  const videoStyle = {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: '50% 50%',
    maxWidth: '100%',
    display: loaded ? 'block' : 'none',
    maxHeight: '530px'
  } as React.CSSProperties

  const divStyle = {
    width: '100vw',
    height: '530px',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)'
  } as React.CSSProperties

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 41, 110, 0.5)',
  } as React.CSSProperties

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 2 } },
    exit: { opacity: 0, transition: { duration: 2 } },
  }

  return (
    <>
      <div style={divStyle} ref={divRef}>
        <div style={overlayStyle}>
          <motion.div variants={variants} initial='hidden' animate='visible' style={{ display: 'flex', flexDirection: 'column' }}>
            <img src={NexusStarWhite} alt='Nexus Star White' style={{ width: '135px', height: '215px', display: 'block', alignSelf: 'center', opacity: 0.6, }} />
            <TypedText />
          </motion.div>
        </div>
        <Box sx={{ position: 'absolute', top: '2%', left: '1%', opacity: 0.6, '& > *': { color: 'white' }, ':hover': { cursor: 'pointer' }, zIndex: 100 }} onClick={() => setMuted(!muted)}>{muted ? <VolumeOff /> : <VolumeUp />}</Box>
        <video src={bannerSrc} muted={muted} autoPlay loop playsInline preload='' style={{ ...videoStyle }} />
      </div>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <SearchField ref={searchFieldRef}>
          <Paper elevation={2} sx={{ borderRadius: '16px' }}>
            <SearchBar showSearch={isMobile ? false : true} type={isSelected.type} sx={{ padding: (theme: any) => theme.spacing(4), boxShadow: '0px 24px 128px 0px rgba(57, 57, 57, 0.2)', button: { borderRadius: '22.5px' }, borderRadius: '16px' }} />
          </Paper>
        </SearchField>
      </Box>
    </>
  )
}
