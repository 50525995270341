import { AssignmentInd, BusinessCenter, Check, EventNote, FitnessCenter, LocalCafe, LocalLibrary, LocalParking, LocalShipping, Person, Wifi } from "@mui/icons-material"
import { Box, Button, Card, CardContent, CardMedia, Grid, ImageList, ImageListItem, List, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableRowClasses, Theme, Typography, useTheme } from "@mui/material"
import { AnimatePresence, motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import { useParentProperties } from "../../AppContext"
import fullOfficeIcon from '../../assets/images/output-onlinepngtools (6).png'
import DedicastedDesk1 from '../../assets/images/dedicated-desk-img1.jpg'
import DedicastedDesk2 from '../../assets/images/dedicated-desk-img2.jpg'
import DayOffice1 from '../../assets/images/dayoffice-1-stock.jpg'
import DayOffice2 from '../../assets/images/dayoffice-2-stock.jpg'
import CorporateOffice1 from '../../assets/images/corporate-1-stock.jpg'
import CorporateOffice2 from '../../assets/images/corporate-2-stock.jpg'
import ExecutiveOffice1 from '../../assets/images/executive-1-stock.jpg'
import ExecutiveOffice2 from '../../assets/images/executive-2-stock.jpg'
import VirtualOffice1 from '../../assets/images/virtual-1-stock.jpg'
import VirtualOffice2 from '../../assets/images/virtual-2-stock.jpg'
import ConferenceRoom1 from '../../assets/images/conference-1-stock.jpg'
import ConferenceRoom2 from '../../assets/images/conference-2-stock.jpg'
import nexusStar from '../../assets/images/logo-transparency-06.png'
import meetingRoomIcon from '../../assets/images/output-onlinepngtools (7).png'
import AmenitiesBg from '../../assets/images/solutions-whatsincluded-bg.jpg'
import dedicatedDeskIcon from '../../assets/images/output-onlinepngtools (3).png'
import privateOfficeIcon from '../../assets/images/output-onlinepngtools (4).png'
import coworkingIcon from '../../assets/images/output-onlinepngtools (5).png'
import virtualOfficeIcon from '../../assets/images/output-onlinepngtools (8).png'
import BannerVideo from '../../assets/videos/solutions-vid.mp4'
import ctaBg from '../../assets/images/solutionscta-bg.jpg'
import useScreenSize from "../../react-hooks/screensize"
import { SplitButton } from "../common/SplitButton"
import Layout from "../Layout"

const tableData = [
  {
    amenity: "24/7 Accessibility",
    executiveOffice: true,
    dedicatedDesk: false,
    dayOffice: false,
    conferenceRoom: false,
    virtualOffice: false,
    corporateOffice: true,
    icon: <AssignmentInd />
  },
  {
    amenity: "Free Parking",
    executiveOffice: true,
    dedicatedDesk: true,
    dayOffice: true,
    conferenceRoom: true,
    virtualOffice: true,
    corporateOffice: true,
    icon: <LocalParking />
  },
  {
    amenity: "Unlimited Beverages",
    executiveOffice: true,
    dedicatedDesk: true,
    dayOffice: true,
    conferenceRoom: true,
    virtualOffice: true,
    corporateOffice: true,
    icon: <LocalCafe />
  },
  {
    amenity: "Internet",
    executiveOffice: true,
    dedicatedDesk: true,
    dayOffice: true,
    conferenceRoom: true,
    virtualOffice: false,
    corporateOffice: true,
    icon: <Wifi />
  },
  {
    amenity: "Access Fitness Center",
    executiveOffice: true,
    dedicatedDesk: true,
    dayOffice: false,
    conferenceRoom: false,
    virtualOffice: false,
    corporateOffice: true,
    icon: <FitnessCenter />
  },
  {
    amenity: "Janitorial",
    executiveOffice: true,
    dedicatedDesk: true,
    dayOffice: false,
    conferenceRoom: false,
    virtualOffice: false,
    corporateOffice: true,
    icon: <LocalShipping />
  },
  {
    amenity: "Central Mail Facility",
    executiveOffice: true,
    dedicatedDesk: true,
    dayOffice: false,
    conferenceRoom: false,
    virtualOffice: true,
    corporateOffice: true,
    icon: <LocalLibrary />
  },
  {
    amenity: "Receptionist & Answering Services",
    executiveOffice: true,
    dedicatedDesk: false,
    dayOffice: false,
    conferenceRoom: false,
    virtualOffice: true,
    corporateOffice: true,
    icon: <Person />
  },
  {
    amenity: "Conference Room Access",
    executiveOffice: true,
    dedicatedDesk: true,
    dayOffice: true,
    conferenceRoom: true,
    virtualOffice: true,
    corporateOffice: true,
    icon: <EventNote />
  },
  {
    amenity: "Office Furniture",
    executiveOffice: true,
    dedicatedDesk: true,
    dayOffice: false,
    conferenceRoom: false,
    virtualOffice: false,
    corporateOffice: true,
    icon: <BusinessCenter />
  },
  {
    amenity: "Notary Service",
    executiveOffice: true,
    dedicatedDesk: true,
    dayOffice: true,
    conferenceRoom: true,
    virtualOffice: true,
    corporateOffice: true,
    icon: <AssignmentInd />
  }
]

const workspaces = [
  {
    id: 'dedicatedDesk',
    cardTitle: 'Dedicated Desk',
    title: 'Dedicated Desk',
    description: 'A dedicated private office space for individuals or teams. This workspace provides a dedicated desk where you can work in a private and focused environment. It is ideal for individuals or teams who need a consistent and dedicated workspace.',
    caption: 'A dedicated private office space',
    workspaceInfo: {
      subtitle: 'The Optimal Plan for Your Own Space',
      amenities: tableData,
      guestAmount: 1,
    },
    price: '$1000/month',
    images: [DedicastedDesk1, DedicastedDesk2],
    icon: dedicatedDeskIcon
  },
  {
    id: 'dayOffice',
    title: 'Day Office',
    description: 'A private office space for the day. This workspace provides a private office where you can work for a day. It is perfect for individuals or teams who need a temporary workspace for meetings, presentations, or focused work.',
    caption: 'A private office space for the day',
    workspaceInfo: {
      subtitle: 'A private office space for the day.',
      amenities: tableData,
      guestAmount: 1,
    },
    price: '$100/day',
    icon: privateOfficeIcon,
    images: [DayOffice1, DayOffice2]
  },
  {
    id: 'corporateOffice',
    title: 'Corporate Office',
    description: 'A move-in ready office with shared and private amenities. This workspace provides a fully furnished office with shared and private amenities. It is suitable for businesses or teams who need a professional and ready-to-use workspace with access to common areas and private amenities.',
    caption: 'A move-in ready office',
    workspaceInfo: {
      subtitle: 'A move-in ready office with shared and private amenities.',
      amenities: tableData,
      guestAmount: 2,
    },
    price: '$2000/month',
    icon: coworkingIcon,
    images: [CorporateOffice1, CorporateOffice2]
  },
  {
    id: 'executiveOffice',
    title: 'Executive Office',
    description: 'Furnished, customizable office on private floor. This workspace provides a furnished and customizable office on a private floor. It is designed for executives or teams who need a high-end and customizable workspace with dedicated parking and private amenities.',
    caption: 'Furnished, customizable office',
    workspaceInfo: {
      subtitle: 'Furnished, customizable office on private floor.',
      amenities: tableData,
      guestAmount: 5,
    },
    price: '$3500/month',
    icon: fullOfficeIcon,
    images: [ExecutiveOffice1, ExecutiveOffice2]
  },
  {
    id: 'virutalOffice',
    title: 'Virtual Office',
    description: 'Office space and services without the physical office. This workspace provides office space and services without the need for a physical office. It is suitable for remote workers or businesses who need a professional address and access to shared amenities and community events.',
    caption: 'Office space and services',
    workspaceInfo: {
      subtitle: 'A shared workspace with flexible seating options.',
      amenities: tableData,
      guestAmount: 1,
    },
    price: '$500/month',
    icon: virtualOfficeIcon,
    images: [VirtualOffice1, VirtualOffice2]
  },
  {
    id: 'conferenceRoom',
    title: 'Conference Room',
    description: 'A professional conference room for client presentations or team meetings. This workspace provides a professional conference room for client presentations or team meetings. It is equipped with essential amenities such as a projector, screen, and whiteboard for effective collaboration and communication.',
    caption: 'A professional conference room',
    workspaceInfo: {
      subtitle: 'A professional conference room for client presentations or team meetings.',
      amenities: tableData,
      guestAmount: 10,
    },
    price: '$50/hour',
    icon: meetingRoomIcon,
    images: [ConferenceRoom1, ConferenceRoom2]
  },
]

const MainSolutions = () => {
  const [top, setTop] = useState(0)
  const [currentWorkspace, setCurrentWorkspace] = useState(workspaces[0] as any)
  const { width: currentWidth } = useScreenSize()
  const theme = useTheme() as Theme
  const { values } = theme.breakpoints
  const isMobile = currentWidth <= values.md
  const { parentHeights } = useParentProperties()
  const navigate = useNavigate()

  const handleCardClick = event => {
    const { id } = event.currentTarget as HTMLElement
    const modalContent = workspaces.find(workspace => workspace.id === id)
    setCurrentWorkspace(modalContent as any)
  }

  const variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 5, type: 'spring' }
  }, cardVariants = {
    hover: {
      scale: 1.05,
      y: -10,
      transition: { duration: 0.3 }
    }
  }

  const TitleBanner = () => (
    <Box sx={{
      width: currentWidth - 10,
      height: '530px',
      position: 'relative',
      overflow: 'hidden',
      alignItems: 'center',
      left: '50%',
      transform: 'translateX(-50%)',
      padding: '0 !important',
      margin: '0 !important'
    }}>
      <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <video src={BannerVideo} autoPlay loop muted playsInline style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        <Box sx={{
          backgroundColor: 'rgba(0, 41, 110, 0.8)', '& > *': { color: 'white' },  padding: theme => theme.spacing(2), display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: '25%', width: 'fit-content',
        }}>
          <img src={nexusStar} alt="Nexus Star" style={{ width: '120px', height: 'auto', margin: '0 auto' }} />
          <Typography variant={isMobile ? 'h4' : 'h3'} sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Solutions for Every Need</Typography>
          <Typography variant="h6">
            Explore our diverse office space solutions
          </Typography>
        </Box>
      </Box>
    </Box>
  )

  const Workspaces = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, padding: '2% 0 5%' }}>
      <Box sx={{ position: 'relative', bottom: '60px' }}>
        <Grid container spacing={2}>
          {workspaces.map((workspace, index) => (
            <Grid item xs={12} md={12 / workspaces.length} key={`workspace-card-${index}`}>
              <motion.div
                key={index}
                variants={cardVariants}
                whileHover='hover'
              >
                <Card key={`workspace-card-${index}`} id={workspace.id} sx={{ display: 'flex', flexDirection: 'column', padding: 1, cursor: 'pointer', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)', alignItems: 'center', height: '100%', minHeight: '120px', backgroundColor: theme => theme.palette.primary.main  }} onClick={handleCardClick}>
                  <CardMedia component="img" src={workspace.icon} alt={workspace.title} sx={{ width: '20.5%', height: 'auto', margin: '0 auto' }} />
                  <CardContent sx={{ ':last-child': { paddingBottom: 2 }, textAlign: 'center' }}>
                    <Typography variant="h6" sx={{ color: 'white' }}>{workspace.title}</Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
      <AnimatePresence>
        <motion.div
          key={currentWorkspace.id}
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <Grid container spacing={6}>
            <Grid item xs={12} md={5}>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', gap: 4 }}>
                <Paper sx={{ boxShadow: '10px 4px 20px 0px rgba(0, 0, 0, .1)', padding: theme => theme.spacing(2, 0), width: '75%', backgroundColor: theme.palette.secondary.main }}>
                  <Typography variant="h4" sx={{ color: theme.palette.primary.main, width: '100%', display: 'flex', justifyContent: 'center', fontSize: '2.75rem' }}><strong>{currentWorkspace.title}</strong></Typography>
                </Paper>
                <Box>
                  <Typography variant="h6">{currentWorkspace.caption}</Typography>
                  <Typography variant="h3"><strong>{currentWorkspace.workspaceInfo.subtitle}</strong></Typography>
                </Box>
                <Typography variant="subtitle1">{currentWorkspace.description}</Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <SplitButton
                    primary={<Button sx={{ borderRadius: '22.5px' }} variant="contained" color="primary" size="large" onClick={() => window.location.assign(`https://93840cgimg.yardikube.com/kubecc/content/#/app/space?companyid=132&proid=2&spaceid=4`)}>Book Now</Button>}
                    secondary={<Button sx={{ borderRadius: '22.5px' }} variant="outlined" color="primary" size="large" onClick={() => navigate('/contact?form=nexus-schedule-tour')}>Schedule a Tour</Button>}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <ImageList sx={{ height: '100%', overflow: 'auto', margin: 0 }}>
                {currentWorkspace?.images?.map((image, index) => (
                  <ImageListItem key={`workspace-image-${index}`}>
                    <img src={image} alt={`Workspace ${index + 1}`} style={{ width: '100%', height: '100%' }} />
                  </ImageListItem>
                ))}
              </ImageList>
            </Grid>
          </Grid>
        </motion.div>
      </AnimatePresence>
    </Box>
  )

  const VisualAmenities = () => {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme => theme.spacing(4),
        alignItems: 'center',
        width: '100vw',
        height: '100%',
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
        padding: '4% 64px',
        overflow: 'hidden',
        // background: `url(${AmenitiesBg})`,
        // backgroundColor: 'rgba(255, 255, 255, 0.5)',
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
      }}>
        <Box
          sx={{
            width: '100vw',
            height: '100%',
            background: `url(${AmenitiesBg})`,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            opacity: 0.5
          }} />
        <Typography variant="h3"><strong>What's Included in a {currentWorkspace.title}</strong></Typography>
        <Typography variant="h6">Our {currentWorkspace.title} spaces come with the following amenities:</Typography>
        <List>
          <Grid container spacing={2}>
            {currentWorkspace?.workspaceInfo?.amenities?.map((amenity, index) => amenity[`${currentWorkspace.id}`] && (
              <Grid item key={`amenity-card-${index}`} xs={12} sm={6} md={4} lg={3}>
                <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: theme.spacing(1, 0), minHeight: 'fit-content', backgroundColor: '#f5f5f5' }} elevation={0}>
                  <CardMedia sx={{ 'svg': { fontSize: '2rem', color: theme.palette.primary.main } }}>
                    {amenity.icon}
                  </CardMedia>
                  <CardContent sx={{ ':last-child': { paddingBottom: 1, paddingTop: 0 } }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{amenity.amenity}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </List>
      </Box>
    )
  }


  const NexusTable = () => (
    <TableContainer sx={{ maxWidth: isMobile ? '100%' : '90%' }}>
      <Table sx={{ 'thead > tr > th': { color: 'white', padding: '8px' }, 'tbody > tr > td': { color: theme.palette.primary.light, padding: '8px' }, '.MuiTableCell-root': { fontWeight: 1000, fontSize: '0.75rem' } }}>
        <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">Virtual Office</TableCell>
            <TableCell align="center">Dedicated Desk</TableCell>
            <TableCell align="center">Day Office</TableCell>
            <TableCell align="center">Corporate Office</TableCell>
            <TableCell align="center">Executive Office</TableCell>
            <TableCell align="center">Conference Room</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => (
            <TableRow key={`table-row-${index}`} sx={{
              '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
              }, 
              '> td': {
                padding: '8px'
              }
            }}>
              <TableCell align="center">{row.amenity}</TableCell>
              <TableCell align="center">{row.virtualOffice ? <Check sx={{ color: theme => theme.palette.primary.main }} /> : ''}</TableCell>
              <TableCell align="center">{row.dedicatedDesk ? <Check sx={{ color: theme => theme.palette.primary.main }} /> : ''}</TableCell>
              <TableCell align="center">{row.dayOffice ? <Check sx={{ color: theme => theme.palette.primary.main }} /> : ''}</TableCell>
              <TableCell align="center">{row.corporateOffice ? <Check sx={{ color: theme => theme.palette.primary.main }} /> : ''}</TableCell>
              <TableCell align="center">{row.executiveOffice ? <Check sx={{ color: theme => theme.palette.primary.main }} /> : ''}</TableCell>
              <TableCell align="center">{row.conferenceRoom ? <Check sx={{ color: theme => theme.palette.primary.main }} /> : ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (parentHeights['nexus-nav-mobile'] && isMobile) setTop(parentHeights['nexus-nav-mobile'] - 32)
  }, [parentHeights])

  return (
    <Layout sx={{ position: isMobile ? 'relative' : 'initial', top: isMobile ? `${top}px` : 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <TitleBanner />
        <Workspaces />
        <VisualAmenities />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme => theme.spacing(3), alignItems: 'center', width: '100%', padding: '2% 0' }}>
          <Typography variant="h3" sx={{ fontWeight: 'bold' }}>Workspace Amenities</Typography>
          <Typography variant="h6">Our office space solutions come with a variety of amenities to suit your needs.</Typography>
          <NexusTable />
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme => theme.spacing(3),
          alignItems: 'center',
          position: 'relative',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100vw',
          height: 'fit-content',
          padding: '6% 32px',
          background: `url(${ctaBg}) center 70% no-repeat`,
          backgroundSize: 'cover',
          marginBottom: '24px',
          // color: 'white',
        }}>
          <Typography variant="h3" sx={{ fontWeight: 'bold' }}>Interested in our workspace solutions?</Typography>
          <Typography variant="h6">Contact us now to book your workspace or for more information.</Typography>
          <Button variant="outlined" sx={{ borderColor: 'black', color: "black" }} onClick={() => navigate('/contact')}>Contact Us</Button>
        </Box>
      </Box>
    </Layout>
  )
}

export default MainSolutions