import { SpaceType } from "../types/app-types"

/* Types of Spaces defined by Yardi Kube */
export const SPACE_TYPES: Array<SpaceType> = [
  {
    "Id": 10,
    "Name": "Board Room"
  },
  {
    "Id": 4,
    "Name": "Private Office",
    Active: true
  },
  {
    "Id": 9,
    "Name": "Day Office",
    Active: true
  },
  {
    "Id": 7,
    "Name": "Dedicated Desk",
    Active: true
  },
  {
    "Id": 1,
    "Name": "Event Space"
  },
  {
    "Id": 12,
    "Name": "Full Floor Office"
  },
  {
    "Id": 3,
    "Name": "Meeting Room",
    Active: true
  },
  {
    "Id": 8,
    "Name": "Office Suite"
  },
  {
    "Id": 2,
    "Name": "Open Desk"
  },
  {
    "Id": 5,
    "Name": "Team Office"
  },
  {
    "Id": 6,
    "Name": "Training Room"
  },
  {
    "Id": 11,
    "Name": "Utility"
  },
  {
    "Id": 13,
    "Name": "Virtual Office",
    Active: true
  }
]