import { Box, Button, CircularProgress, FormControl, Grid, Input, InputAdornment, InputLabel, SxProps, TextareaAutosize, Theme, Typography, styled, useTheme } from "@mui/material"
import { generateClient } from "aws-amplify/api"
import { useLocation, useNavigate } from "react-router-dom"
import { getAllSpaces, getProperties } from "../../gql/queries"
import { SearchBar } from "../location-search/SearchBar"
import Navigation from "../navbar/Navigation"
import LocationsMap from "./maps/LocationsMap"
import SpacesSelections from "./SpacesSelections"
import { SpaceType } from "../../types/app-types"
import { SPACE_TYPES } from "../../yardi-reference/SpaceType"
import Footer from "../footer/Footer"
import { useParentProperties } from "../../AppContext"
import { DateRange, Group, Mail, Person, Phone } from "@mui/icons-material"
import useScreenSize from "../../react-hooks/screensize"
import { useQuery } from "@apollo/client"
import { Property, Space } from "../../gql/graphql"
import nexusStarWhite from '../../assets/images/logo-transparency-06.png'
import ctaBg from '../../assets/images/locations-search-cta.jpg'
import { motion } from 'framer-motion'


type ScrollbarProps = {
  children: React.ReactNode
}

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    teamSize: "",
    location: "",
    message: ""
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Perform form submission logic here
    console.log("Form submitted!")
    console.log("First Name:", formData.firstName)
    console.log("Last Name:", formData.lastName)
    console.log("Email:", formData.email)
    console.log("Phone Number:", formData.phoneNumber)
    console.log("Team Size:", formData.teamSize)
    console.log("Location:", formData.location)
    console.log("Message:", formData.message)
  }

  return (
    <Box id='locations-contact' sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', border: '1px solid lightgray', boxShadow: '0px 9px 28.7px 0.3px rgba(0, 0, 0, 0.09)', padding: theme => theme.spacing(5), borderRadius: '16px', width: '100%' }}>
      <Typography variant="h3" color='primary'><strong>Get in Touch</strong></Typography>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '2rem' }}>
        <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <InputLabel htmlFor="firstName">First Name</InputLabel>
          <Input id="firstName" type="text" value={formData.firstName} onChange={handleChange} name="firstName" endAdornment={<InputAdornment position="end"><Person /></InputAdornment>} />
        </FormControl>
        <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <InputLabel htmlFor="lastName">Last Name</InputLabel>
          <Input id="lastName" type="text" value={formData.lastName} onChange={handleChange} name="lastName" endAdornment={<InputAdornment position="end"><Person /></InputAdornment>} />
        </FormControl>
        <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input id="email" type="email" value={formData.email} onChange={handleChange} name="email" endAdornment={<InputAdornment position="end"><Mail /></InputAdornment>} />
        </FormControl>
        <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
          <Input id="phoneNumber" type="tel" value={formData.phoneNumber} onChange={handleChange} name="phoneNumber" endAdornment={<InputAdornment position="end"><Phone /></InputAdornment>} />
        </FormControl>
        <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <InputLabel htmlFor="teamSize">Team Size</InputLabel>
          <Input id="teamSize" type="text" value={formData.teamSize} onChange={handleChange} name="teamSize" endAdornment={<InputAdornment position="end"><Group /></InputAdornment>} />
        </FormControl>
        <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <InputLabel htmlFor="location">Location</InputLabel>
          <Input id="location" type="text" value={formData.location} onChange={handleChange} name="location" endAdornment={<InputAdornment position="end"><DateRange /></InputAdornment>} />
        </FormControl>
        <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <InputLabel htmlFor="message">Message</InputLabel>
          <TextareaAutosize id="message" value={formData.message} onChange={handleChange} minRows={4} name="message" />
        </FormControl>
      </Box>
      <Box sx={{ width: 'fit-content' }}>
        <Button variant="contained" onClick={handleSubmit} size="medium" sx={{ borderRadius: '22.5px', padding: theme => theme.spacing(2) }} endIcon={<img src={nexusStarWhite} alt="Nexus Star White" width={30} height={20} />}>Submit</Button>
      </Box>
    </Box>
  )
}

const CTA: React.FC = () => {
  const navigate = useNavigate()
  const [isInView, setIsInView] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const checkIfInView = () => {
      const rect = (ref.current as HTMLElement).getBoundingClientRect()
      setIsInView(rect.top >= 0 && rect.bottom <= window.innerHeight)
    }

    window.addEventListener('scroll', checkIfInView)
    checkIfInView() // Initial check

    return () => {
      window.removeEventListener('scroll', checkIfInView)
    }
  }, [])
  const containerVariants = {
    hidden: { opacity: 0.5 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5
      }
    }
  }
  const childVariants = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  }
  return (
    <motion.div
      ref={ref}
      variants={containerVariants}
      initial="hidden"
      animate={isInView ? 'show' : 'hidden'}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: theme => theme.spacing(2),
          backgroundImage: `url(${ctaBg})`, // Replace with your chosen image URL
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          color: theme => `${theme.palette.common.white}`,
          height: '500px',
          width: '100vw',
          position: 'relative',
          left: ' 50%',
          transform: 'translateX(-50%)',
        }}
      >
       <Box sx={{ background: 'rgba(0, 0, 0, 0.65)', display: 'flex', flexDirection: 'column', padding: theme => theme.spacing(3) }}>
          <motion.div variants={childVariants}>
            <Typography variant="h3" component="h2" gutterBottom>
              <strong>Explore our main solutions</strong>
            </Typography>
          </motion.div>
          <motion.div variants={childVariants}>
            <Typography variant="h6" gutterBottom>
              Discover the ideal workspace for your team. Check out our main solutions to find the perfect fit.
            </Typography>
          </motion.div>
          <motion.div variants={childVariants}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                navigate('/solutions')
              }}
            >
              Check them out
            </Button>
          </motion.div>
       </Box>
      </Box>
    </motion.div>
  )
}


const LocationsSearch = () => {
  const location = useLocation()
  const [searchCriteria, setSearchCriteria] = useState(location.state || {})
  const [coordinates, setCoordinates] = useState<any[]>([])
  const [spacesTypesToDisplay, setSpacesTypesToDisplay] = useState<any>([])
  const [spaceTypeId, setSpaceTypeId] = useState<number>(0)
  const [top, setTop] = useState<number>(0)
  const { parentHeights, updateParentWidth } = useParentProperties()
  const { data: properties, loading: propertiesLoading } = useQuery(getProperties, { variables: { radius: 20.00, latitude: searchCriteria.locationCoordinates[0].lat, longitude: searchCriteria.locationCoordinates[0].lng } })
  const { data: spaces, loading: spacesLoading } = useQuery(getAllSpaces, { variables: { pageSize: 30, SpaceTypeId: spaceTypeId } })
  const { width: currentWidth } = useScreenSize()
  const theme = useTheme() as Theme
  const { values } = theme.breakpoints
  const isMobile = currentWidth < values.md

  useEffect(() => {
    if (searchCriteria.spaceType) {
      const { spaceType } = searchCriteria
      const { Id } = SPACE_TYPES.find(space => {
        let finalSpace
        if (spaceType === 'Suites' && space.Name === 'Day Office') finalSpace = space
        else if (space.Name.includes(spaceType)) finalSpace = space
        return finalSpace
      }) as SpaceType

      if (Id) setSpaceTypeId(Id)
    }
  }, [searchCriteria])

  useEffect(() => {
    const retrieveSpaces = (properties: Property[], spaces: Space[]) => {
      const spaceTypeWithPropertyInfo = [] as any[]

      for (const space of spaces) {
        const spaceProperty = properties.find(prop => prop.Id === (space as any)?.PropertyId)
        if (spaceProperty) spaceTypeWithPropertyInfo.push({
          propertyInfo: {
            id: spaceProperty.Id,
            propertyName: spaceProperty.PropertyName,
            address1: spaceProperty.AddressLine1,
            address2: spaceProperty.AddressLine2,
            city: spaceProperty.City,
            state: spaceProperty.State,
            zip: spaceProperty.Zip,
          },
          spaceInfo: {
            spaceType: spaceTypeId,
            spaceAmenities: (space as any)?.Amenities,
            spaceName: (space as any)?.SpaceTypeName,
            gallery: (space as any)?.SpaceGallery,
            pricing: (space as any)?.Pricing?.reduce((acc, price) => {
              if (price.IsMemberCanBook || price.IsProspectCanBook) {
                const pricingObj = {
                  memberCanBook: price.IsMemberCanBook,
                  prospectCanBook: price.IsProspectCanBook,
                  bookingType: price.BookingType,
                  retailPeakPrice: price.RetailPeakPricing,
                  retailOffPeakPrice: price.RetailOffPeakPricing,
                  memberPeakPrice: price.MemberPeakPricing,
                  memberOffPeakPrice: price.MemberOffPeakPricing
                }

                if (!acc) acc = [{ ...pricingObj }]
                else {
                  acc = [...acc, { ...pricingObj }]
                }

                return acc
              }
              return acc
            }, [])
          }
        })
      }

      setSpacesTypesToDisplay([...spaceTypeWithPropertyInfo, ...spaceTypeWithPropertyInfo, ...spaceTypeWithPropertyInfo, ...spaceTypeWithPropertyInfo, ...spaceTypeWithPropertyInfo, ...spaceTypeWithPropertyInfo])
    }

    if (spaceTypeId && (properties?.getProperties as any[])?.length && spaces?.getAllSpaces?.length) retrieveSpaces((properties?.getProperties as Property[]), (spaces?.getAllSpaces as Space[]))
  }, [spaceTypeId, properties])

  useEffect(() => {
    if (parentHeights['nexus-nav-mobile'] && isMobile) {
      setTop(parentHeights['nexus-nav-mobile'])
    }
  }, [parentHeights])

  useEffect(() => {
    if (properties?.getProperties?.length) {
      setCoordinates((properties?.getProperties as Property[]).map(prop => {
        const finalPropObj = { latitude: prop.Latitude, longitude: prop.Longitude, propertyName: prop.PropertyName, address1: prop.AddressLine1, address2: prop.AddressLine2, city: prop.City, state: prop.State, zip: prop.Zip }
        return { ...finalPropObj }
      }))
    }
  }, [properties])

  const StyledBox = styled(Box)(() => ({}))

  const isLoading = propertiesLoading || spacesLoading
  const isResultsReady = !isLoading && !!coordinates?.length && !!(properties?.getProperties as Property[])?.length
  const isNoResults = !isLoading && !isResultsReady
  return (
    <StyledBox sx={{ display: 'flex', flexDirection: "column", gap: '2rem', justifyContent: 'space-evenly', position: 'relative', top: `${top}px` }}>
      <Box sx={{ padding: theme => theme.spacing(0, 0, 2) }}>
        <Navigation sx={{ flex: 1 }} />
        {isLoading && <Box sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center', justifyContent: 'space-around', height: '50vh' }}><CircularProgress variant="indeterminate" size={175} color="primary" /></Box>}
        {isResultsReady && (
          <Box sx={{ marginTop: 3 }}>
            <StyledBox
              sx={{
                width: '100vw',
                height: '650px',
                position: 'relative',
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <LocationsMap coordinates={coordinates} />
              <SearchBar showSearch sx={{ width: isMobile ? '80%' : '50%', alignSelf: 'center', borderRadius: '16px', padding: 4, position: 'relative', bottom: '60px' }} />
            </StyledBox>
            <Box>
              <Typography variant={isMobile ? 'h4' : 'h3'} color="primary" sx={{ fontWeight: 600 }} noWrap>{(properties?.getProperties as Property[]).length} Nexus locations.</Typography>
              <Grid container spacing={2} sx={{ flex: 1, margin: theme => theme.spacing(2, 0) }}>
                <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', paddingLeft: '0 !important' }}>
                  <SpacesSelections propertiesToDisplay={properties?.getProperties} locationCriteria={searchCriteria.locationCriteria} />
                </Grid>
                <Grid item xs={12} md={6} sx={{ ...(isMobile && { paddingLeft: '0 !important' }) }}>
                  <ContactForm />
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
        {isNoResults &&
          <>
            <Box sx={{ alignItems: 'center', justifyItems: 'center', height: '50%' }}>
              <Box>
                <Typography variant="h3" sx={{ fontWeight: 600 }}>{spacesTypesToDisplay.length} locations.</Typography>
                <Typography variant="subtitle1">Please refine search criteria and try again.</Typography>
              </Box>
            </Box>
          </>
        }
      </Box>
      <CTA />
      <Footer />
    </StyledBox>
  )
}

export default LocationsSearch