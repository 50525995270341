import { Facebook, Instagram, LinkedIn, LocationOnSharp, Mail, MailSharp, Person, Phone, PhoneSharp, Twitter } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, FormControl, Grid, InputAdornment, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { ExpandMore } from "@mui/icons-material"
import { Link } from "react-router-dom"

import NexusLogoFooter from '../../assets/images/nexus-white-transparent-animation.gif'
import { sendEmail } from "../../gql/queries"
import { useLazyQuery } from "@apollo/client"

const Footer = ({ sx = {} }) => {
  const theme = useTheme()
  const [fieldValues, setFieldValues] = useState<{ fullName: string, email: string, phone: string }>({ fullName: '', email: '', phone: '' })
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')), isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const [sendEmailQuery, { data, loading, error }] = useLazyQuery(sendEmail)

  const fieldChange = event => {
    const key = event.target.id, value = event.target.value
    setFieldValues({ ...fieldValues, [key]: value })
  }

  const inputSx = {
    "& .MuiOutlinedInput-root": {
      "fieldset": { borderColor: 'white' }
    },
    input: { color: "white" },
    "& .MuiInputLabel-root, & .MuiInputAdornment-root": {
      color: 'white'
    }
  }

  const renderContactInfo = (icon, subtext, text) => (
    <Box sx={{ display: 'flex', gap: theme => theme.spacing(2) }}>
      <Box sx={{ border: 'solid 0.5px #959595', borderRadius: '50%', width: 'fit-content', height: 'fit-content', padding: theme => theme.spacing(1), display: 'flex', justifyContent: 'center' }}>
        {icon}
      </Box>
      <Box>
        <Typography variant="body2">{subtext}</Typography>
        <Box>
          <Typography variant="body1">{text}</Typography>
        </Box>
      </Box>
    </Box>
  )

  const renderLink = (to, text) => (
    <Link to={to}>
      <Typography variant="body2">{text}</Typography>
    </Link>
  )

  const renderSocialIcon = (icon, url) => (
    <a href={url}>{icon}</a>
  )

  const handleSubmit = event => {
    event.preventDefault()
    sendEmailQuery({
      variables: {
        fullName: fieldValues.fullName,
        email: fieldValues.email,
        phone: fieldValues.phone
      }
    })
  }

  useEffect(() => {
    if (data) {
      setFieldValues({ fullName: '', email: '', phone: '' })
    }
  }, [data])

  return (
    <Grid container spacing={4} sx={{
      ...sx,
      backgroundColor: 'black',
      '& > *, a': { color: 'white !important' },
      paddingRight: isMobile ? '32px !important' : '64px !important',
      paddingLeft: isMobile ? '32px !important' : '64px !important',
      paddingBottom: theme => theme.spacing(4),
      transform: 'translateX(-50%)',
      left: '50%',
      position: 'relative',
      width: '100vw',
      marginLeft: 0
    }}>
      <Grid item xs={12} sm={6} md={3} sx={{ width: '100%', borderBottom: isMobile || isTablet ? 'solid 1px #fff' : 'none', paddingBottom: theme => theme.spacing(isMobile || isTablet ? 3 : 0), paddingLeft: '0 !important' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme => theme.spacing(4) }}>
          <Box sx={{ width: '250px', height: '250px' }}>
            <img src={NexusLogoFooter} alt="Nexus Logo" style={{ width: '100%', height: '100%' }} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={3} sx={{ width: '100%', borderBottom: isMobile || isTablet ? 'solid 1px #fff' : 'none', paddingBottom: theme => theme.spacing(isMobile || isTablet ? 3 : 0), paddingLeft: isMobile ? '0 !important' : 'initial' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme => theme.spacing(4) }}>
          <Box sx={{ borderLeft: 'solid 2px #fff', padding: theme => theme.spacing(0, 2), height: 'fit-content' }}>
            <Typography variant='body1'>Quick Links</Typography>
          </Box>
          <Box id='quick-links' sx={{ display: 'flex', flexDirection: 'column', gap: theme => theme.spacing(1), padding: theme => theme.spacing(0, 2) }}>
            {renderLink('/about', 'About')}
            {renderLink('/solutions', 'Solutions')}
            {renderLink('/locations', 'Locations')}
            {renderLink('/contact', 'Contact')}
          </Box>
          <Box sx={{ borderLeft: 'solid 2px #fff', padding: theme => theme.spacing(0, 2), height: 'fit-content' }}>
            <Typography variant="body1">Social Connect</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: theme => theme.spacing(0.5) }}>
            {renderSocialIcon(<Facebook />, 'https://www.facebook.com/nexusworkspaces')}
            {renderSocialIcon(<Instagram />, 'https://instagram.com/nexusworkspaces')}
            {renderSocialIcon(<LinkedIn />, 'https://www.linkedin.com/company/nexusworkspaces')}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={3} sx={{ width: '100%', borderBottom: isMobile ? 'solid 1px #fff' : 'none', paddingBottom: theme => theme.spacing(isMobile ? 3 : 0), paddingLeft: isMobile ? '0 !important' : 'initial' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme => theme.spacing(4) }}>
          {/* <Box sx={{ borderLeft: 'solid 2px #fff', padding: theme => theme.spacing(0, 2), height: 'fit-content' }}>
            <Typography variant="body1">Company</Typography>
          </Box>
          {isMobile && (
            <Accordion sx={{ backgroundColor: 'black' }}>
              <AccordionSummary expandIcon={<ExpandMore sx={{ color: 'white' }} />}>
                <Typography variant='body1' sx={{ color: 'white' }}>Company Links</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box id='company-links' sx={{ display: 'flex', flexDirection: 'column', gap: theme => theme.spacing(1), padding: theme => theme.spacing(0, 2) }}>
                  {renderLink('/privacy-policy', 'Privacy Policy')}
                  {renderLink('/terms', 'Terms and Conditions')}
                  {renderLink('/support', 'Support')}
                  {renderLink('/faq', 'FAQ')}
                </Box>
              </AccordionDetails>
            </Accordion>
          )} */}
          {/* {!isMobile && (
            <Box id='company-links' sx={{ display: 'flex', flexDirection: 'column', gap: theme => theme.spacing(1), padding: theme => theme.spacing(0, 2) }}>
              {renderLink('/privacy-policy', 'Privacy Policy')}
              {renderLink('/terms', 'Terms and Conditions')}
              {renderLink('/support', 'Support')}
              {renderLink('/faq', 'FAQ')}
            </Box>
          )} */}
          <Box sx={{ borderLeft: 'solid 2px #fff', padding: theme => theme.spacing(0, 2), height: 'fit-content' }}>
            <Typography variant="body1">Location</Typography>
          </Box>
          {isMobile && (
            <Accordion sx={{ backgroundColor: 'black' }}>
              <AccordionSummary expandIcon={<ExpandMore sx={{ color: 'white' }} />}>
                <Typography variant='body1' sx={{ color: 'white' }}>Location Links</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box id='location-links' sx={{ display: 'flex', flexDirection: 'column', gap: theme => theme.spacing(1), padding: theme => theme.spacing(0, 2) }}>
                  {renderLink(`/locations?city=`, 'Boca Raton')}
                  {renderLink(`/locations?city=${encodeURI('Lake Worth'.toLowerCase())}`, 'Wellington')}
                  {renderLink(`/locations?city=${encodeURI('Stuart'.toLowerCase())}`, 'Stuart')}
                  {renderLink(`/locations?city=${encodeURI('West Palm Beach'.toLowerCase())}`, 'Vista Park')}
                  {renderLink(`/locations?city=${encodeURI('Boynton Beach'.toLowerCase())}`, 'Boynton Beach')}
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
          {!isMobile && (
            <Box id='location-links' sx={{ display: 'flex', flexDirection: 'column', gap: theme => theme.spacing(1), padding: theme => theme.spacing(0, 2) }}>
              {renderLink(`/locations?city=`, 'Boca Raton')}
              {renderLink(`/locations?city=${encodeURI('Lake Worth'.toLowerCase())}`, 'Wellington')}
              {renderLink(`/locations?city=${encodeURI('Stuart'.toLowerCase())}`, 'Stuart')}
              {renderLink(`/locations?city=${encodeURI('West Palm Beach'.toLowerCase())}`, 'Vista Park')}
              {renderLink(`/locations?city=${encodeURI('Boynton Beach'.toLowerCase())}`, 'Boynton Beach')}
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={3} sx={{ width: '100%', paddingBottom: theme => theme.spacing(isMobile ? 4 : 0), paddingLeft: isMobile ? '0 !important' : 'initial' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme => theme.spacing(4) }}>
          <Box sx={{ borderLeft: 'solid 2px #fff', padding: theme => theme.spacing(0, 2), height: 'fit-content' }}>
            <Typography variant="body1">Contact Us</Typography>
          </Box>
          <Box component='form' onSubmit={handleSubmit} id='contact-form-footer' sx={{ display: 'flex', flexDirection: 'column', gap: theme => theme.spacing(3), padding: theme => theme.spacing(0, 2) }}>
            <FormControl fullWidth>
              <TextField id='name' sx={inputSx} label="Name" variant="outlined" InputProps={{ endAdornment: <InputAdornment position="end"><Person fontSize="small" /></InputAdornment> }} onChange={fieldChange} />
            </FormControl>
            <FormControl fullWidth>
              <TextField id='email' sx={inputSx} label="Email Address" variant="outlined" InputProps={{ endAdornment: <InputAdornment position="end"><Mail fontSize="small" /></InputAdornment> }} onChange={fieldChange} />
            </FormControl>
            <FormControl fullWidth>
              <TextField id='phone' sx={inputSx} label="Phone Number" variant="outlined" InputProps={{ endAdornment: <InputAdornment position="end"><Phone fontSize="small" /></InputAdornment> }} onChange={fieldChange} />
            </FormControl>
            <Button type="submit" variant='contained' sx={{ width: '50%' }}>{loading ? <CircularProgress size={16} /> : 'Submit'}</Button>
            {data && <Typography variant='caption'>We've received your inquiry and will be reaching out shortly.</Typography>}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Footer