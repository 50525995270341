export const PRICING_INFO = [
  {
    propertyName: 'Nexus at Boca Raton',
    4: '$729/month',
    0: '$299/month',
    13: '$99/month',
    9: '$50/hour'
  },
  {
    propertyName: 'Nexus at Wellington',
    4: '$699/month',
    0: '$299/month',
    13: '$99/month',
    9: '$50/hour'
  },
  {
    propertyName: 'Nexus at Boynton Beach',
    4: '$699/month',
    0: '$299/month',
    13: '$99/month',
    9: '$50/hour',
    7: 'N/A'
  },
  {
    propertyName: 'Nexus at Vista Park',
    4: '$599/month',
    0: '$299/month',
    13: '$99/month',
    9: '$50/hour'
  },
  {
    propertyName: 'Nexus at Stuart',
    4: '$499/month',
    0: '$299/month',
    13: '$99/month',
    9: '$50/hour'
  },

]