import { APIProvider, AdvancedMarker, Map, useApiIsLoaded, useMapsLibrary } from '@vis.gl/react-google-maps'
import { useEffect, useState } from "react"
import nexusStarBlue from '../../../assets/images/logo-transparency-05.png'

const LocationsMap = props => {
  const LoadedMap = () => {
    const [mapId, setMapId] = useState<string>('')
    const [finalCoordinates, setFinalCoordinates] = useState<any[]>([])
    const [markerPositions, setMarkerPositions] = useState<google.maps.LatLng[]>([])
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null)
    const [zoom, setZoom] = useState<number>(11)
    const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({ lat: 0, lng: 0 })
    const geocodingLibrary = useMapsLibrary('geocoding')
    const mapLoaded = useApiIsLoaded()

    const getMapId = async (secretName) => {
      // const results = await getSecrets(secretName)
      // const parsedResults = JSON.parse(results)
      setMapId('429cfae7c5a55818')
    }

    const retrieveCoordinatesFromGoogle = async (allCoordinates: any[]) => {
      const final = await Promise.all(allCoordinates.map(async coord => {
        let newLat, newLong, fullAddress = `${coord.address1}, ${coord.city}, ${coord.state}, ${coord.zip}`
        const response = await (geocoder as google.maps.Geocoder).geocode({ address: fullAddress })
        if (response?.results.length) {
          response.results.forEach(({ geometry }) => {
            newLat = geometry.location.lat()
            newLong = geometry.location.lng()
          })
          return ({
            ...coord,
            latitude: newLat,
            longitude: newLong,
          })
        }
      }))
      setFinalCoordinates(final)
    }

    useEffect(() => {
      getMapId('map-id-locations-page')
    }, [])

    useEffect(() => {
      if (!geocodingLibrary) return
      else setGeocoder(new geocodingLibrary.Geocoder())
    }, [geocodingLibrary])

    useEffect(() => {
      if (!geocoder) return
      else if (props.coordinates && geocoder) retrieveCoordinatesFromGoogle(props.coordinates)
    }, [props, geocoder])

    useEffect(() => {
      if (mapId && finalCoordinates.length > 0 && finalCoordinates.some(coord => coord.latitude > 0)) {
        setMarkerPositions(finalCoordinates.map(coord => ({ lat: () => coord.latitude, lng: () => coord.longitude }) as google.maps.LatLng))
      }
    }, [mapId, finalCoordinates])

    useEffect(() => {
      if (markerPositions.length) {
        const centerLat = markerPositions.reduce((total, pos) => total + pos.lat(), 0) / markerPositions.length
        const centerLng = markerPositions.reduce((total, pos) => total + pos.lng(), 0) / markerPositions.length
        setMapCenter({ lat: centerLat, lng: centerLng })
      }
    }, [markerPositions])

    const darkModeStyle = [
      { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
      {
        elementType: "labels.text.stroke",
        stylers: [{ color: "#242f3e" }],
      },
      { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
      },
      {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
      },
    ]

    return (
      <>
        {!!markerPositions.length &&
          <Map
            mapId={mapId}
            zoom={zoom}
            center={{ lat: mapCenter.lat, lng: mapCenter.lng }}
            mapTypeId={google.maps.MapTypeId.ROADMAP}
            styles={darkModeStyle}
          >
            {markerPositions.map(({ lat, lng }, index) => <AdvancedMarker position={{ lat: lat(), lng: lng() }} collisionBehavior={google.maps.CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL} key={`nexus-location-marker-${index}`}>
              <img src={nexusStarBlue} alt="Nexus Star Gold" width={60} height={50} />
            </AdvancedMarker>)}
          </Map>
        }
      </>
    )
  }

  return (
    <APIProvider apiKey={global.mapsApiKey} libraries={['marker']}>
      <LoadedMap />
    </APIProvider>
  )
}

export default LocationsMap