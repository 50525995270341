import { Box, Card, CardContent, CardMedia, Grid, Typography, styled, useMediaQuery, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import bookWorkspaceIcon from '../../assets/images/coworking-blue.png'
import ourSolutionsIcon from '../../assets/images/ideas-newblue2.png'
import talkToUsIcon from '../../assets/images/service-blue.png'
import virtualIcon from '../../assets/images/virtual-blue.png'
import blueVirtualIcon from '../../assets/images/virtualnew-blue.png'
import dedicatedIcon from '../../assets/images/dedicated-blue.png'
import meetingIcon from '../../assets/images/meetingroom-blue.png'
import executiveIcon from '../../assets/images/executive-blue.png'

export const Solutions = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const cardsContent = [
    { title: 'Our Solutions', path: '/solutions', description: 'Browse our full range of products and services', icon: ourSolutionsIcon, link: '/solutions' },
    { title: 'Talk to Us', path: '/contact', description: 'Get advice from one of our experts 24/7', icon: talkToUsIcon },
    { title: 'Book a workspace', path: 'https://93840cgimg.yardikube.com/kubecc/content/#/app/services/workspacelist', description: 'Book meeting rooms and day offices now', icon: bookWorkspaceIcon },
    { title: 'Set up a virtual office', path: 'https://93840cgimg.yardikube.com/kubecc/content/#/app/acquireamembership?proid=1', description: 'Start building a real presence today', icon: virtualIcon },
    { title: 'Executive', path: '/', description: 'Furnished, customizable, private office space', icon: executiveIcon },
    { title: 'Virtual', path: '/', description: 'Flexible seating with business adress and services', icon: blueVirtualIcon },
    { title: 'Meeting', path: '/', description: 'Professional conference space for meetings and teams', icon: meetingIcon },
    { title: 'Dedicated', path: '/', description: 'Private workspace for individuals or teams' , icon: dedicatedIcon },
  ]

  const StyledCard = styled(Card)({
    display: 'flex',
    borderRadius: '12px',
    border: '1px solid #ccc',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    transition: 'transform 0.2s',
    '&:hover': {
      boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
    },
    '& > *': {
      color: 'white',
    },
    margin: '10px 0',
    height: '100%',
  })

  const Heading = styled(Box)(() => ({
    margin: 0,
    textAlign: 'center',
  }))

  const Subtitle = styled(Box)(() => ({
    margin: '2px 0',
    textAlign: 'center',
  }))

  const StyledCardContent = styled(CardContent)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > *': {
      color: `black !important`
    },
  }))


  const handleCardClick = (path) => {
    if (path.includes('http')) {
      window.open(path, '_self')
    } else {
      navigate(`${path}`)
    }
  }

  return (
    <>
      <Subtitle>
        <Typography variant="h5" sx={{ lineHeight: 1.44, fontWeight: 'normal' }}>
          Our Solutions
        </Typography>
      </Subtitle>
      <Heading>
        <Typography variant="h3" sx={{ fontWeight: 600, letterSpacing: '-0.84px', lineHeight: 1.64, color: ' #011434', fontStretch: 'normal', fontStyle: 'normal' }}>
          How Can We Help?
        </Typography>
      </Heading>
      <Grid container spacing={isMobile ? 1 : 2} sx={{ marginTop: '6px' }}>
        {cardsContent.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StyledCard onClick={() => handleCardClick(card.path)}>
              <Box>
                <CardMedia
                  component="img"
                  sx={{ height: isMobile ? 50 : 65, objectFit: 'contain', padding: theme.spacing(1, 2, 0), color: "white", width: 'fit-content' }}
                  image={card.icon}
                  alt={card.title}
                />
              </Box>
              <StyledCardContent>
                <Typography gutterBottom variant="subtitle2">
                  <strong>{card.title}</strong>
                </Typography>
                <Typography variant="body2">
                  {card.description}
                </Typography>
              </StyledCardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
