import { gql } from 'graphql-tag'
import { graphql } from './gql'

// export const getGuest = gql`
//   query getGuest($Id: Int!) {
//     getGuest(Id: $Id) {
//       Id
//       FirstName
//       LastName
//       Email
//   }
// }
// `

export const getAvailableSpaces = graphql(/* GraphQL */ `
  query getAvailableSpaces($StartDatetime: AWSDateTime!, $EndDatetime: AWSDateTime!, $BookingType: String!, $PropertyId: Int, $SpaceTypeId: Int, $pageSize: Int, $pageNo: Int) {
    getAvailableSpaces(StartDatetime: $StartDatetime, EndDatetime: $EndDatetime, BookingType: $BookingType, PropertyId: $PropertyId, SpaceTypeId: $SpaceTypeId, pageSize: $pageSize, pageNo: $pageNo) {
      SpaceId
      SpaceName
      FloorId
      FloorName
      FloorNumber
      Type
      StartDatetime
      EndDatetime
      BookedSeats
      MaximumSeats
      Availability {
        StartDatetime
        EndDatetime
        IsAvailable
        Type
      }
    }
  }
`)

export const getProperties = graphql(/* GraphQL */ `
  query getProperties($radius: Float, $latitude: Float, $longitude: Float) {
    getProperties(radius: $radius, latitude: $latitude, longitude: $longitude) {
      Id
      AboutDescription
      PropertyName
      AddressLine1
      AddressLine2
      City
      State
      Zip
      Phone
      Country
      PropertyGallery {
        Id
        Primary
        Url
      }
      PropertyOperatingHours {
        Day
        PeakStartTime
        PeakEndTime
        OffPeakStartTime
        OffPeakEndTime
        Is247ORClosed
      }
    }
  }
`)

export const getVideoUrls = graphql(/* GraphQL */ `
  query getVideoUrls {
    getVideoUrls {
      url
      key
    }
  }
`)

export const getAllSpaces = graphql(/* GraphQL */ `
  query getAllSpaces($pageSize: Int, $pageNo: Int, $PropertyId: Int, $SpaceTypeId: Int, $SpaceTypeIds: [String], $MemberId: Int) {
    getAllSpaces {
      Id
      SpaceTypeId
      SpaceTypeName
      PropertyName
      SpaceGallery {
        Id
        Primary
        Url
      }
      Pricing {
        IsMemberCanBook
        IsAdminCanBook
        IsProspectCanBook
        RetailPeakPricing
        RetailOffPeakPricing
        MemberPeakPricing
        MemberOffPeakPricing
        BookingType
        
      }
    }
  }
`)

export const getAmenities = graphql(/* GraphQL */ `
  query getAmenities($PropertyId: Int!) {
    getAmenities(PropertyId: $PropertyId) {
      Id
      Name
      Type
      PricingType
      UnitPrice
      TotalQuantity
      AmenityDiscountRate
      TotalPrice
      TotalSetupPrice
    }
  }
`)

export const sendEmail = graphql(/* GraphQL */ `
  query sendEmail($fullName: String!, $email: String!, $phone: String!, $message: String, $companyName: String, $location: String, $preferredTourDate: String, $preferredTourTime: String, $interestedWorkspace: String) {
    sendEmail(fullName: $fullName, email: $email, phone: $phone, message: $message, companyName: $companyName, location: $location, preferredTourDate: $preferredTourDate, preferredTourTime: $preferredTourTime, interestedWorkspace: $interestedWorkspace) {
      messageId
    }
  }
`)