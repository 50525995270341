import { Box, Button, Card, CardContent, Grid, Theme, Typography, styled, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import useScreenSize from '../../react-hooks/screensize'
import Layout from '../Layout'

import aboutSectionImg2 from '../../assets/images/atlanta-nexus-5.jpg'
import SignatureSvg from '../../assets/images/genelify-66550c1ed1bf2-signature-generator (1).svg'
import nexusStarWhite from '../../assets/images/logo-transparency-06.png'
import aboutSectionImg1 from '../../assets/images/lycs-architecture-U2BI3GMnSSE-unsplash.jpg'
import aboutMe from '../../assets/images/rt-about-me.jpg'
import aboutVideo from '../../assets/videos/aboutpage-hero.mp4'

import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import cupIcon from '../../assets/images/cup.png'
import gameIcon from '../../assets/images/game.png'
import globeIcon from '../../assets/images/globe.png'
import handshakeIcon from '../../assets/images/hand.png'
import medicalIcon from '../../assets/images/medical.png'
import meetingIcon from '../../assets/images/meeting.png'
import thumbIcon from '../../assets/images/thumb.png'
import toolIcon from '../../assets/images/tool.png'
import workIcon from '../../assets/images/work.png'

type Section = {
  left: { title?: string, subtitle?: string, content?: string, img?: { src: string, alt: string, style: object } },
  right: { title?: string, subtitle?: string, content?: string, img?: { src: string, alt: string, style: object } }
}

const AboutNexus = () => {
  const screensize = useScreenSize()
  const [currentWidth, setCurrentWidth] = useState(screensize.width)
  const theme = useTheme() as Theme
  const isMobile = screensize.width <= theme.breakpoints.values.md
  const navigate = useNavigate()

  const createSection = (index: number, section: Section) => {
    const leftOrRight = index % 2 === 0
    const styles = {
      section: {
        padding: theme => theme.spacing(index === 0 ? 1 : 4, 0),
        marginBottom: theme => theme.spacing(4, 0),
        backgroundColor: index % 2 === 1 ? 'rgba(250, 250, 250)' : 'white',
        width: '100vw',
        position: 'relative',
        left: '50%',
        right: '50%',
        marginLeft: '-50vw',
        marginRight: '-50vw',
        marginTop: 0,
        paddingRight: isMobile ? '32px !important' : '64px !important',
        paddingLeft: isMobile ? '32px !important' : '64px !important',
      },
      title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme => theme.spacing(2),
      },
      image: {
        width: '100%',
        height: 'auto',
      },
    }

    const leftGridItemProps = {
      xs: 12,
      md: index === 0 && leftOrRight ? 3 : leftOrRight ? 5 : 7,
      sx: {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }
    }
    const rightGridItemProps = {
      xs: 12,
      md: index === 0 && leftOrRight ? 9 : leftOrRight ? 7 : 5,
      sx: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }
    }

    const { left, right } = section

    return (
      <Grid container sx={{ ...styles.section }} spacing={6} key={index}>
        <Grid item {...leftGridItemProps}>
          {left.img && <img src={left.img.src} alt={left.img.alt} style={styles.image} />}
          {left.subtitle && <Typography variant="h6">{left.subtitle}</Typography>}
          {left.title && <Typography variant="h3"><strong>{left.title}</strong></Typography>}
          {left.content && <Typography variant="body1">{left.content}</Typography>}
          {!leftOrRight && left.title !== 'Mission and Vision' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', paddding: theme.spacing(2, 0), gap: 1, marginTop: theme.spacing(2) }}>
              <Typography variant='h6'>If you have any questions, please feel free to contact us.</Typography>
              <Box>
                <Button variant="outlined" onClick={() => navigate('/contact?form=contact-form')} sx={{ borderRadius: '22.5px' }}>Contact a Specialist</Button>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item {...rightGridItemProps}>
          {right.img && <img src={right.img.src} alt={right.img.alt} style={styles.image} />}
          {right.subtitle && <Typography variant="h6">{right.subtitle}</Typography>}
          {right.title && <Typography variant="h3"><strong>{right.title}</strong></Typography>}
          {right.content && <Typography variant="body1">{right.content}</Typography>}
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
    setCurrentWidth(screensize.width)
  }, [screensize.width])

  const sections = [
    {
      left: {
        title: 'Mission and Vision',
      },
      right: {
        content: `When we started The Nexus Workspaces in 2013, we wanted our workspace to be a positive difference in the way people work. We wanted to create a place that feels right, a place where you have the freedom to work the way you want. 
                  At The Nexus Workspaces, we measure a good day by your ability to achieve more; not only within your business but in every aspect of your life.Our mission is simple: To create a workspace where professionals can thrive in all aspects of their life while doing what they love.`
      }
    },
    {
      left: {
        title: 'How It Started',
        subtitle: 'Humble Beginnings',
        content: `Nexus began in southwest Florida in 2012, with the first location in Wellington. Opening its doors to meet the needs of small businesses, freelancers, and tech startups, Nexus set out to develop a vibrant community populated with business professionals from many industries.
                  With a diverse and varied membership, Nexus hosts successful businesses that can expand in place and large companies and corporations that like the availability of optional workspaces for their remote teams.`,
      },
      right: {
        img: {
          src: aboutSectionImg1 as string,
          alt: 'Office',
          style: {
            width: '100%',
            height: 'auto',
            maxHeight: '500px',
          },
        }
      }
    },
    {
      left: {
        img: {
          src: aboutSectionImg2 as string,
          alt: 'Atlanta Nexus',
          style: {
            width: '100%',
            height: 'auto',
            maxHeight: '500px',
          },
        }
      },
      right: {
        title: 'Our Expansion Plans',
        subtitle: 'How We\'re Growing',
        content: `The Nexus Workspaces is thrilled to announce its ambitious expansion plans, set to unfold starting in 2025. Among the highlights of this expansion is the eagerly anticipated opening of a brand-new 3-building suite in Atlanta, GA. This new location is poised to redefine the concept of workspace, offering a dynamic environment designed to foster creativity, collaboration, and productivity.
                  This expansion signifies The Nexus Workspaces' commitment to meeting the evolving needs of professionals and businesses. By providing innovative workspace solutions and a comprehensive suite of services, we aim to empower our clients to achieve their goals and drive success.
                As we embark on this exciting journey of growth and expansion, we invite you to join us and be a part of the future of workspace. Stay tuned for more updates as we continue to expand our footprint and bring The Nexus Workspaces experience to new locations across the country.`
      }
    }
  ]

  const leadersSections = [
    {
      img: {
        src: aboutMe as string,
        alt: 'Raoul Thomas',
        style: {
          width: '100%',
          height: 'auto',
          maxHeight: '300px',
          maxWidth: '300px',
        },
      },
      title: 'Raoul Thomas',
      subtitle: 'Founder and CEO',
      quote: '“Our mission is to create a workspace where professionals can thrive in all aspects of their life while doing what they love.”',
      content: `Raoul Thomas is the Founder and CEO of The Nexus Workspaces, a leading provider of flexible workspace solutions for professionals and businesses. With a passion for entrepreneurship and innovation, Raoul has built The Nexus Workspaces into a dynamic and diverse community of professionals, offering a range of workspace options and services to meet the needs of today's workforce.`
    }
  ]

  const Leaders = () => {
    const MotionSignatureSvg = motion(SignatureSvg)

    // TODO: Fix the signature animation
    const Signature: React.FC = () => {

      const svgVariants = {
        hidden: { opacity: 0 },
        visible: {
          opacity: 1,
          transition: {
            delay: 0.5,
            when: "afterChildren",
            staggerChildren: 0.25
          }
        }
      }

      return (
        <>
          <style>
            {`
          @keyframes line-anim {
            to {
              stroke-dashoffset: 0;
            }
          }
        `}
          </style>
          {/* <motion.div variants={svgVariants} initial="hidden" animate="visible"> */}
          <SignatureSvg />
          {/* </motion.div> */}
        </>
      )
    }

    return (
      <Box sx={{ padding: theme => theme.spacing(4, 6), backgroundColor: theme => theme.palette.primary.light, '& > *': { color: 'white' }, position: 'relative', left: '50%', transform: 'translateX(-50%)', width: '100vw' }}>
        {leadersSections.map((section, index) => (
          <Grid container spacing={{ xs: 0, md: 5 }} rowSpacing={{ xs: 2 }} key={index}>
            <Grid item xs={12} md={6} sx={{}}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '24px', padding: 2 }}>
                <img src={section.img.src} alt={section.img.alt} style={section.img.style} />
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '4px' }}>
                    <Typography variant="h4"><strong>{section.title}</strong></Typography>
                    <Typography variant="h6" sx={{ marginBottom: '1rem', fontStyle: 'italic' }}>{section.subtitle}</Typography>
                  </Box>
                  <Typography variant="subtitle1" sx={{ marginBottom: '1rem' }}>{section.quote}</Typography>
                  <Signature />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6">{section.content}</Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    )
  }

  const OurValues = () => {
    const cardsContent = [
      {
        title: 'Integrity',
        icon: meetingIcon,
        content: 'Honesty, trustworthiness, and doing the right thing at all times, through responsible and accountable actions.'
      },
      {
        title: 'Innovation',
        icon: toolIcon,
        content: 'We are committed to continuous improvement, creativity, and the pursuit of new ideas and technologies.'
      },
      {
        title: 'Community',
        icon: globeIcon,
        content: 'We are dedicated to creating a supportive and inclusive environment where everyone can thrive and succeed.'
      },
      {
        title: 'Excellence',
        icon: cupIcon,
        content: 'We strive for the highest quality in everything we do, delivering exceptional service and results.'
      },
      {
        'title': 'Authenticity',
        icon: thumbIcon,
        content: 'Transparent, genuine, and honest… allowing you to feel comfortable doing you.'
      },
      {
        title: 'Sustainability',
        icon: '',
        content: 'We are committed to reducing our environmental impact and promoting sustainable practices.'
      },
      {
        title: 'Inclusivity',
        icon: '',
        content: 'We welcome and respect everyone, regardless of background, beliefs, or identity.'
      },
      {
        title: 'Wellness',
        icon: medicalIcon,
        content: 'We prioritize the health and well-being of our members, employees, and community.'
      },
      {
        title: 'Collaboration',
        icon: gameIcon,
        content: 'We believe in the power of teamwork and partnership, working together to achieve shared goals.'
      },
      {
        title: 'Flexibility',
        icon: '',
        content: 'We adapt to the changing needs of our members, offering flexible solutions and support.'
      },
      {
        title: 'Empowerment',
        icon: '',
        content: 'We provide the tools, resources, and opportunities to help you succeed and grow.'
      },
      {
        title: 'Diversity',
        icon: '',
        content: 'We celebrate and embrace differences, recognizing the value of diverse perspectives.'
      },
      {
        title: 'Respect',
        icon: '',
        content: 'We treat everyone with dignity and respect, fostering a culture of kindness and understanding.'
      },
      {
        title: 'Fun',
        icon: '',
        content: 'We believe in the importance of enjoying what you do, creating a positive and engaging atmosphere.'
      },
      {
        title: 'Balance',
        icon: '',
        content: 'We support work-life balance, encouraging you to prioritize your well-being and happiness.'
      },
      {
        title: 'Growth',
        icon: handshakeIcon,
        content: 'We are committed to your personal and professional development, helping you reach your full potential.'
      },
      {
        title: 'Quality',
        icon: workIcon,
        content: 'We are dedicated to delivering the highest standard of service and excellence in all that we do.'
      },
      {
        title: 'Creativity',
        icon: '',
        content: 'We encourage innovative thinking and creative expression, inspiring you to explore new ideas.'
      },
      {
        title: 'Passion',
        icon: '',
        content: 'We are driven by our love for what we do, bringing enthusiasm and energy to everything we do.'
      },
    ]

    const sx = {
      padding: theme => theme.spacing(3, 0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'rgba(250, 250, 250)',
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      paddingRight: isMobile ? '32px !important' : '64px !important',
      paddingLeft: isMobile ? '32px !important' : '64px !important',
    }

    const AnimatedCard = styled(Card)({
      transition: 'all 0.5s',
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      },
      height: '100%',
    })

    return (
      <Box sx={sx}>
        <Typography variant="h3" sx={{ marginBottom: '2rem' }}><strong>Our Values</strong></Typography>
        <Grid container spacing={4}>
          {cardsContent.map((card, index) => (
            !!card.icon.length && (
              <Grid item xs={12} md={4} key={index}>
                <AnimatedCard sx={{ padding: '1rem' }}>
                  <CardContent sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={card.icon} alt={card.title} width={48} height={48} />
                    </Box>
                    <Box>
                      <Typography variant="h5" sx={{ marginBottom: '1rem' }}>{card.title}</Typography>
                      <Typography variant="body1">{card.content}</Typography>
                    </Box>
                  </CardContent>
                </AnimatedCard>
              </Grid>
            )
          ))}
        </Grid>
      </Box>
    )
  }

  const videoStyle = {
    width: '100%',
    height: '100%',
    maxHeight: '530px',
    objectFit: 'cover',
    objectPosition: 'top'
  } as React.CSSProperties, divStyle = {
    marginBottom: theme => theme.spacing(4, 0),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '530px',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  }

  return (
    <Layout>
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={divStyle}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 41, 110, 0.8)', '& > *': { color: 'white' }, width: 'fit-content', padding: theme.spacing(4), position: 'absolute', zIndex: 10 }}>
            <Box>
              <img src={nexusStarWhite} alt="Nexus Star" width={125} height='auto' />
            </Box>
            <Typography variant="h3" sx={{ textAlign: 'center' }}><strong>Discover Nexus</strong></Typography>
            <Typography variant="h6" sx={{ textAlign: 'center' }}>A workspace that feels right</Typography>
            <Box sx={{ display: 'flex', padding: theme.spacing(1.5, 0, 0), marginBottom: 1 }}>
              <Button sx={{ alignSelf: 'center', color: 'white', borderColor: 'white', ':hover': { color: 'white', borderColor: 'white' } }} variant="outlined" onClick={() => navigate('/contact?form=schedule-tour-form')}>
                Schedule a Tour
              </Button>
            </Box>
          </Box>
          <video src={aboutVideo} muted playsInline autoPlay loop style={videoStyle} />
        </Box>
        {sections.map((section, index) => createSection(index, section as Section))}
        <OurValues />
        <Leaders />
      </Box>
    </Layout>
  )
}

export default AboutNexus