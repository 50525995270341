import { useLazyQuery, useQuery } from "@apollo/client"
import { Close, ExpandMore, Facebook, Instagram, MailSharp, YouTube } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, SxProps, TextField, Typography, styled, useTheme } from "@mui/material"
import nexusStarWhite from '../../assets/images/nexus-star-logo-white.png'
import { Property } from "../../gql/graphql"
import { getProperties, sendEmail } from "../../gql/queries"

export const ContactUs = () => {
  const theme = useTheme()
  const { data, error } = useQuery(getProperties)
  const properties = data?.getProperties as Property[] || []
  const [currentProperty, setCurrentProperty] = useState<Property>({})
  const [showModal, setShowModal] = useState(false)

  const StyledDiv = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column'
  }))

  const handleClick = event => {
    const id = event.target.getAttribute('data-testid')

    if (id === 'email') {
      window.location.href = 'mailto:info@thenexusfl.com'
    } else if (id === 'phone') {
      window.location.href = `tel:${currentProperty?.Phone}`
    } else window.open('https://goo.gl/maps/4Y4Z2m5jyX2vz5KQ9', '_blank')
  }

  const ContactForm = () => {
    const [sendEmailQuery, { data: emailData, loading, error: emailError }] = useLazyQuery(sendEmail)
    const [contactInfo, setContactInfo] = useState({
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      message: ''
    })
    const [errors, setErrors] = useState({
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      message: ''
    })
    const [readyToSubmit, setReadyToSubmit] = useState(false)

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { id, value } = event.target
      setContactInfo(prev => ({ ...prev, [id]: value }))
      validateField(id, value)
    }

    const validateField = (field: string, value: string) => {
      let error = ''
      switch (field) {
        case 'firstName':
        case 'lastName':
          if (!value) error = 'This field is required'
          break
        case 'emailAddress':
          if (!value) error = 'This field is required'
          else if (!/\S+@\S+\.\S+/.test(value)) error = 'Email is invalid'
          break
        case 'phoneNumber':
          if (!value) error = 'This field is required'
          else if (!/^\d{10}$/.test(value)) error = 'Phone number is invalid'
          break
        case 'message':
          if (!value) error = 'This field is required'
          break
        default:
          break
      }
      setErrors(prev => ({ ...prev, [field]: error }))
    }

    const validateForm = () => {
      const { firstName, lastName, emailAddress, phoneNumber, message } = contactInfo
      validateField('firstName', firstName)
      validateField('lastName', lastName)
      validateField('emailAddress', emailAddress)
      validateField('phoneNumber', phoneNumber)
      validateField('message', message)
      return !Object.values(errors).some(error => error)
    }

    const HR = styled('hr')(() => ({
      height: '100%',
      width: '32px',
      border: 'none'
    }))

    const textFieldStyling = {
      backgroundColor: 'white',
      borderColor: theme.palette.secondary.dark,
    }

    const submitEmail = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      if (!validateForm()) return
      const { firstName, lastName, emailAddress, phoneNumber, message } = contactInfo
      sendEmailQuery({
        variables: {
          fullName: `${firstName} ${lastName}`,
          email: emailAddress,
          phone: phoneNumber,
          message
        }
      })
    }

    useEffect(() => {
      setReadyToSubmit(Object.values(contactInfo).every(value => !!value) && !Object.values(errors).every(error => !!(error)))
    }, [contactInfo])

    useEffect(() => {
      if (emailData?.sendEmail?.messageId) {
        setShowModal(true)
      }
    }, [emailData])

    return (
      <Box
        component={'form'}
        onSubmit={submitEmail}
        sx={{
          backgroundColor: '#c3c3c35e',
          width: '100%',
          padding: theme.spacing(5),
          borderRadius: theme.shape.borderRadius
        }}>
        <StyledDiv sx={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <TextField id='firstName' label='First Name' value={contactInfo.firstName} onChange={handleInput} fullWidth color="success" error={!!errors.firstName}
            helperText={errors.firstName}
            style={textFieldStyling} />
          <HR />
          <TextField id='lastName' label='Last Name' value={contactInfo.lastName} onChange={handleInput} fullWidth color="success" error={!!errors.lastName}
            helperText={errors.lastName}
            style={textFieldStyling} />
        </StyledDiv>
        <HR sx={{ width: '100%', height: theme.spacing(2) }} />
        <TextField id='emailAddress' label='Email Address' value={contactInfo.emailAddress} onChange={handleInput} fullWidth color="success" error={!!errors.emailAddress}
          helperText={errors.emailAddress}
          style={textFieldStyling} />
        <HR sx={{ width: '100%', height: theme.spacing(2) }} />
        <TextField id='phoneNumber' label='Phone Number' value={contactInfo.phoneNumber} onChange={handleInput} fullWidth color="success" error={!!errors.phoneNumber}
          helperText={errors.phoneNumber}
          style={textFieldStyling} />
        <HR sx={{ width: '100%', height: theme.spacing(2) }} />
        <TextField id='message' label='Message' value={contactInfo.message} onChange={handleInput} fullWidth color="success" multiline minRows={5} error={!!errors.message}
          helperText={errors.message}
          style={textFieldStyling} />
        <StyledDiv sx={{ paddingTop: '16px' }}>
          <Button disabled={!readyToSubmit} startIcon={loading ? null : <img src={nexusStarWhite} width={17} height={17} alt="Nexus Logo" />} sx={{ alignSelf: 'flex-start', color: 'white' }} variant="contained" type="submit">
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
          </Button>
        </StyledDiv>
      </Box>
    )
  }

  useEffect(() => {
    if (properties.length) {
      setCurrentProperty(properties[0])
    }
  }, [data])

  useEffect(() => {
    // TODO: Remove this when the phone number is added to the Yardi Kube
    if (!(currentProperty?.Phone as string)?.length && currentProperty.PropertyName === 'Nexus at Stuart') {
      const property = { ...currentProperty, Phone: '+17724035800' }
      setCurrentProperty(property)
    }
  }, [currentProperty])

  const gridSx = {
    // backgroundImage: `url(${(currentProperty as any)?.PropertyGallery?.[0]?.Url})`,
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',
    // backgroundRepeat: 'no-repeat',
    // opacity: 0.3,
    // '& > .MuiGrid-item': {
    //   zIndex: 1,
    // }
  } as SxProps

  return (
    <>
      <Grid container spacing={2} sx={gridSx}>
        <Grid item xs={12} md={6} sx={{ padding: theme => theme.spacing(5) }}>
          <StyledDiv>
            <Typography variant="h3"><strong>Contact Us</strong></Typography>
            <Typography variant="subtitle1" sx={{ padding: theme => theme.spacing(1.25, 0) }}>Contact Nexus now for a no-obligation quote:</Typography>
            {!!properties.length && (
              <Accordion sx={{}}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  {currentProperty?.PropertyName}
                </AccordionSummary>
                <AccordionDetails>
                  {properties?.map((property, index) => (
                    <div key={index}>
                      <Button onClick={() => setCurrentProperty(property as Property)} sx={{ cursor: 'pointer' }}>
                        {(property as Property).PropertyName}
                      </Button>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            )}
          </StyledDiv>
          {!!Object.keys(currentProperty).length && (
            <StyledDiv sx={{ paddingTop: theme => theme.spacing(2), justifyContent: 'space-between', '& > *': { ':hover': { cursor: 'pointer' } } }}>
              <StyledDiv sx={{ flexDirection: 'row', gap: '20px', height: '100%', padding: theme => theme.spacing(2) }}>
                <MailSharp sx={{ color: theme.palette.primary.main }} />
                <Typography data-testid='email' onClick={handleClick}>info@thenexusfl.com</Typography>
              </StyledDiv>
            </StyledDiv>
          )}
          <StyledDiv>
            <StyledDiv sx={{ width: '70%' }}>
              <Typography variant="h6"><strong>Nexus</strong></Typography>
              <Typography variant="subtitle2" sx={{ padding: theme => theme.spacing(2, 0, 0) }}>Whether you are looking to lease or purchase your first office, or you have an existing office and need to optimize that, Nexus is the solution!</Typography>
            </StyledDiv>
            <StyledDiv sx={{ padding: theme => theme.spacing(2.5, 0, 0) }}>
              <strong>Social Media:</strong>
              <StyledDiv sx={{ flexDirection: 'row', gap: '6px', justifyContent: 'space-between', width: '50%', }}>
                <IconButton size="large" onClick={() => window.location.assign('https://www.facebook.com/nexusworkspaces')}><Facebook sx={{ color: theme.palette.primary.main, fontSize: '3rem' }} /></IconButton>
                <IconButton size="large" onClick={() => window.location.assign('https://instagram.com/nexusworkspaces')}><Instagram sx={{ color: theme.palette.primary.main, fontSize: '3rem' }} /></IconButton>
                <IconButton size="large" onClick={() => window.location.assign('https://www.linkedin.com/company/nexusworkspaces')}><YouTube sx={{ color: theme.palette.primary.main, fontSize: '3rem' }} /></IconButton>
              </StyledDiv>
            </StyledDiv>
          </StyledDiv>
        </Grid>
        <Grid item xs={12} md={6} sx={{ flexGrow: 2 }}>
          <ContactForm />
        </Grid>
      </Grid>
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        sx={{
          '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
          },
          '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
          }
        }}
      >
        <DialogTitle id="modal-modal-title">
          <Typography id="modal-modal-title" variant="h5">
            Inquiry Sent
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setShowModal(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            We've receieved your inquiry and will get back to you as soon as possible. Thank you for reaching out to Nexus!
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  )
}
