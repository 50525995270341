/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query getAvailableSpaces($StartDatetime: AWSDateTime!, $EndDatetime: AWSDateTime!, $BookingType: String!, $PropertyId: Int, $SpaceTypeId: Int, $pageSize: Int, $pageNo: Int) {\n    getAvailableSpaces(StartDatetime: $StartDatetime, EndDatetime: $EndDatetime, BookingType: $BookingType, PropertyId: $PropertyId, SpaceTypeId: $SpaceTypeId, pageSize: $pageSize, pageNo: $pageNo) {\n      SpaceId\n      SpaceName\n      FloorId\n      FloorName\n      FloorNumber\n      Type\n      StartDatetime\n      EndDatetime\n      BookedSeats\n      MaximumSeats\n      Availability {\n        StartDatetime\n        EndDatetime\n        IsAvailable\n        Type\n      }\n    }\n  }\n": types.GetAvailableSpacesDocument,
    "\n  query getProperties($radius: Float, $latitude: Float, $longitude: Float) {\n    getProperties(radius: $radius, latitude: $latitude, longitude: $longitude) {\n      Id\n      AboutDescription\n      PropertyName\n      AddressLine1\n      AddressLine2\n      City\n      State\n      Zip\n      Phone\n      Country\n      PropertyGallery {\n        Id\n        Primary\n        Url\n      }\n      PropertyOperatingHours {\n        Day\n        PeakStartTime\n        PeakEndTime\n        OffPeakStartTime\n        OffPeakEndTime\n        Is247ORClosed\n      }\n    }\n  }\n": types.GetPropertiesDocument,
    "\n  query getVideoUrls {\n    getVideoUrls {\n      url\n      key\n    }\n  }\n": types.GetVideoUrlsDocument,
    "\n  query getAllSpaces($pageSize: Int, $pageNo: Int, $PropertyId: Int, $SpaceTypeId: Int, $SpaceTypeIds: [String], $MemberId: Int) {\n    getAllSpaces {\n      Id\n      SpaceTypeId\n      SpaceTypeName\n      PropertyName\n      SpaceGallery {\n        Id\n        Primary\n        Url\n      }\n      Pricing {\n        IsMemberCanBook\n        IsAdminCanBook\n        IsProspectCanBook\n        RetailPeakPricing\n        RetailOffPeakPricing\n        MemberPeakPricing\n        MemberOffPeakPricing\n        BookingType\n        \n      }\n    }\n  }\n": types.GetAllSpacesDocument,
    "\n  query getAmenities($PropertyId: Int!) {\n    getAmenities(PropertyId: $PropertyId) {\n      Id\n      Name\n      Type\n      PricingType\n      UnitPrice\n      TotalQuantity\n      AmenityDiscountRate\n      TotalPrice\n      TotalSetupPrice\n    }\n  }\n": types.GetAmenitiesDocument,
    "\n  query sendEmail($fullName: String!, $email: String!, $phone: String!, $message: String, $companyName: String, $location: String, $preferredTourDate: String, $preferredTourTime: String, $interestedWorkspace: String) {\n    sendEmail(fullName: $fullName, email: $email, phone: $phone, message: $message, companyName: $companyName, location: $location, preferredTourDate: $preferredTourDate, preferredTourTime: $preferredTourTime, interestedWorkspace: $interestedWorkspace) {\n      messageId\n    }\n  }\n": types.SendEmailDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAvailableSpaces($StartDatetime: AWSDateTime!, $EndDatetime: AWSDateTime!, $BookingType: String!, $PropertyId: Int, $SpaceTypeId: Int, $pageSize: Int, $pageNo: Int) {\n    getAvailableSpaces(StartDatetime: $StartDatetime, EndDatetime: $EndDatetime, BookingType: $BookingType, PropertyId: $PropertyId, SpaceTypeId: $SpaceTypeId, pageSize: $pageSize, pageNo: $pageNo) {\n      SpaceId\n      SpaceName\n      FloorId\n      FloorName\n      FloorNumber\n      Type\n      StartDatetime\n      EndDatetime\n      BookedSeats\n      MaximumSeats\n      Availability {\n        StartDatetime\n        EndDatetime\n        IsAvailable\n        Type\n      }\n    }\n  }\n"): (typeof documents)["\n  query getAvailableSpaces($StartDatetime: AWSDateTime!, $EndDatetime: AWSDateTime!, $BookingType: String!, $PropertyId: Int, $SpaceTypeId: Int, $pageSize: Int, $pageNo: Int) {\n    getAvailableSpaces(StartDatetime: $StartDatetime, EndDatetime: $EndDatetime, BookingType: $BookingType, PropertyId: $PropertyId, SpaceTypeId: $SpaceTypeId, pageSize: $pageSize, pageNo: $pageNo) {\n      SpaceId\n      SpaceName\n      FloorId\n      FloorName\n      FloorNumber\n      Type\n      StartDatetime\n      EndDatetime\n      BookedSeats\n      MaximumSeats\n      Availability {\n        StartDatetime\n        EndDatetime\n        IsAvailable\n        Type\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getProperties($radius: Float, $latitude: Float, $longitude: Float) {\n    getProperties(radius: $radius, latitude: $latitude, longitude: $longitude) {\n      Id\n      AboutDescription\n      PropertyName\n      AddressLine1\n      AddressLine2\n      City\n      State\n      Zip\n      Phone\n      Country\n      PropertyGallery {\n        Id\n        Primary\n        Url\n      }\n      PropertyOperatingHours {\n        Day\n        PeakStartTime\n        PeakEndTime\n        OffPeakStartTime\n        OffPeakEndTime\n        Is247ORClosed\n      }\n    }\n  }\n"): (typeof documents)["\n  query getProperties($radius: Float, $latitude: Float, $longitude: Float) {\n    getProperties(radius: $radius, latitude: $latitude, longitude: $longitude) {\n      Id\n      AboutDescription\n      PropertyName\n      AddressLine1\n      AddressLine2\n      City\n      State\n      Zip\n      Phone\n      Country\n      PropertyGallery {\n        Id\n        Primary\n        Url\n      }\n      PropertyOperatingHours {\n        Day\n        PeakStartTime\n        PeakEndTime\n        OffPeakStartTime\n        OffPeakEndTime\n        Is247ORClosed\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getVideoUrls {\n    getVideoUrls {\n      url\n      key\n    }\n  }\n"): (typeof documents)["\n  query getVideoUrls {\n    getVideoUrls {\n      url\n      key\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAllSpaces($pageSize: Int, $pageNo: Int, $PropertyId: Int, $SpaceTypeId: Int, $SpaceTypeIds: [String], $MemberId: Int) {\n    getAllSpaces {\n      Id\n      SpaceTypeId\n      SpaceTypeName\n      PropertyName\n      SpaceGallery {\n        Id\n        Primary\n        Url\n      }\n      Pricing {\n        IsMemberCanBook\n        IsAdminCanBook\n        IsProspectCanBook\n        RetailPeakPricing\n        RetailOffPeakPricing\n        MemberPeakPricing\n        MemberOffPeakPricing\n        BookingType\n        \n      }\n    }\n  }\n"): (typeof documents)["\n  query getAllSpaces($pageSize: Int, $pageNo: Int, $PropertyId: Int, $SpaceTypeId: Int, $SpaceTypeIds: [String], $MemberId: Int) {\n    getAllSpaces {\n      Id\n      SpaceTypeId\n      SpaceTypeName\n      PropertyName\n      SpaceGallery {\n        Id\n        Primary\n        Url\n      }\n      Pricing {\n        IsMemberCanBook\n        IsAdminCanBook\n        IsProspectCanBook\n        RetailPeakPricing\n        RetailOffPeakPricing\n        MemberPeakPricing\n        MemberOffPeakPricing\n        BookingType\n        \n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAmenities($PropertyId: Int!) {\n    getAmenities(PropertyId: $PropertyId) {\n      Id\n      Name\n      Type\n      PricingType\n      UnitPrice\n      TotalQuantity\n      AmenityDiscountRate\n      TotalPrice\n      TotalSetupPrice\n    }\n  }\n"): (typeof documents)["\n  query getAmenities($PropertyId: Int!) {\n    getAmenities(PropertyId: $PropertyId) {\n      Id\n      Name\n      Type\n      PricingType\n      UnitPrice\n      TotalQuantity\n      AmenityDiscountRate\n      TotalPrice\n      TotalSetupPrice\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query sendEmail($fullName: String!, $email: String!, $phone: String!, $message: String, $companyName: String, $location: String, $preferredTourDate: String, $preferredTourTime: String, $interestedWorkspace: String) {\n    sendEmail(fullName: $fullName, email: $email, phone: $phone, message: $message, companyName: $companyName, location: $location, preferredTourDate: $preferredTourDate, preferredTourTime: $preferredTourTime, interestedWorkspace: $interestedWorkspace) {\n      messageId\n    }\n  }\n"): (typeof documents)["\n  query sendEmail($fullName: String!, $email: String!, $phone: String!, $message: String, $companyName: String, $location: String, $preferredTourDate: String, $preferredTourTime: String, $interestedWorkspace: String) {\n    sendEmail(fullName: $fullName, email: $email, phone: $phone, message: $message, companyName: $companyName, location: $location, preferredTourDate: $preferredTourDate, preferredTourTime: $preferredTourTime, interestedWorkspace: $interestedWorkspace) {\n      messageId\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;