import { useLazyQuery, useQuery } from "@apollo/client"
import { ArrowRight, GroupsOutlined } from "@mui/icons-material"
import { Box, Button, Card, CardContent, CardMedia, CircularProgress, Grid, ImageList, ImageListItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Typography, useTheme } from "@mui/material"
import { format } from "date-fns"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useParentProperties } from "../../AppContext"
import BocaStockImg from '../../assets/images/boca-pricing-stock.jpeg'
import BoyntonStockImg from '../../assets/images/boynton-pricing-stock.jpeg'
import nexusStarBlue from '../../assets/images/logo-transparency-05.png'
import nexusStarWhite from '../../assets/images/logo-transparency-06.png'
import newsletterCTA from '../../assets/images/newslettercta-bg.jpg'
import StuartStockImg from '../../assets/images/stuart-pricing-stock.jpeg'
import VistaStockImg from '../../assets/images/vista-pricing-stock.jpeg'
import WellingtonStockImg from '../../assets/images/wellington-pricing-stock.jpeg'
import newVideo from '../../assets/videos/locationspage-hero.mp4'
import { Amenities, Property, PropertyGallery, PropertyOperatingHours } from "../../gql/graphql"
import { getAmenities, getProperties, getVideoUrls } from "../../gql/queries"
import useScreenSize from "../../react-hooks/screensize"
import { formatPhoneNumber } from '../../utils/string-util'
import { SplitButton } from "../common/SplitButton"
import { Watermark } from "../common/Watermark"
import Footer from "../footer/Footer"
import { GoogleReviewPages } from "../home/pricing/Pricing"
import { SearchBar } from "../location-search/SearchBar"
import Navigation from "../navbar/Navigation"
import { aboutDescriptions } from "./descriptions"

const Locations = () => {
  const { data: properties, loading, error } = useQuery(getProperties)
  const [georgiaProperties, setGeorgiaProperties] = useState<any>([])
  const [currentProperty, setCurrentProperty] = useState<Property>({})
  const [backgroundVideo, setBackgroundVideo] = useState<string>('')
  const [media, setMedia] = useState<any>({})
  const searchBarRef = useRef<HTMLDivElement | null>(null)
  const [queryParams] = useSearchParams()
  const { data: videos, loading: videosLoading } = useQuery(getVideoUrls)
  const [lazyGetAmenities, { data: amenitiesData, loading: amenitiesLoading }] = useLazyQuery(getAmenities)
  const [amenitiesTableData, setAmenitiesTableData] = useState<Amenities[]>([])
  let aboutDescription = ''
  const { width: currentWidth } = useScreenSize()
  const theme = useTheme() as Theme
  const { values } = theme.breakpoints
  const isMobile = currentWidth < values.md
  const [top, setTop] = useState(0)
  const { parentHeights } = useParentProperties()
  const navigate = useNavigate()
  const searchRef = useRef<HTMLDivElement | null>(null)

  const handlePropertyClicked = event => {
    const propertyName = event.currentTarget.id as string // Convert propertyName to string
    const tempProp = (properties?.getProperties as Property[])?.find(p => p.Id === Number.parseInt(propertyName.split('-')[1]))
    if (tempProp) setCurrentProperty(tempProp)
    // else if (propertyName === 'Nexus at Atlanta') setCurrentProperty(georgiaProperties[0])
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const id = queryParams.get('city')
    if (id && searchRef.current) {
      searchRef.current.scrollIntoView({ behavior: 'instant' })
    }
  }, [queryParams])

  useEffect(() => {
    if (videos?.getVideoUrls?.length) {
      setBackgroundVideo(videos.getVideoUrls.find((video: any) => video.key === 'nexus location video.mp4')?.url as string)
    }
  }, [videos])

  useEffect(() => {
    if (parentHeights['nexus-nav-mobile'] && isMobile) setTop(parentHeights['nexus-nav-mobile'] - 32)
  }, [parentHeights, isMobile])

  useEffect(() => {
    if (properties?.getProperties?.length) {
      setCurrentProperty((properties.getProperties as any[])[0])
      const transformPropperties = {
        ...properties.getProperties[0],
        AddressLine1: 'Somewhere in Atlanta',
        City: 'Atlanta',
        State: 'GA',
        Zip: '30303',
        Id: 6,
        PropertyName: 'Nexus at Atlanta'
      }
      setGeorgiaProperties([transformPropperties])
    }
  }, [properties])

  useEffect(() => {
    if (queryParams.size !== 0 && properties?.getProperties?.length) {
      const city = queryParams.get('city')
      const queryParamProperty = (properties.getProperties as any[]).find(p => p.City.toLowerCase() === city)
      setCurrentProperty(queryParamProperty)
    }
  }, [queryParams, properties])

  useEffect(() => {
    if (amenitiesData?.getAmenities?.length) {
      setAmenitiesTableData(amenitiesData.getAmenities.reduce((acc, amenity: any, index: number) => {
        if (amenity.Type === 'StandardService' && index < 10) {
          acc.push(amenity)
        }
        return acc
      }, [] as any[]))
    }
  }, [amenitiesData])

  useEffect(() => {
    if (currentProperty) {
      const propertyMedia = videos?.getVideoUrls?.find((video: any) => {
        if (video.key.includes('VISTA') && (currentProperty as any)?.City?.toLowerCase() === 'west palm beach') return true
        else if (video.key.includes('WELLINGTON') && (currentProperty as any)?.City?.toLowerCase() === 'lake worth') return true
        else if (video.key.includes('BOCA RATON') && (currentProperty as any)?.City?.toLowerCase() === '') return true
        else if (video.key.includes('STUART') && (currentProperty as any)?.City?.toLowerCase() === 'stuart') return true
        else if (video.key.includes('Boynton') && (currentProperty as any)?.City?.toLowerCase() === 'boynton beach') return true
      })
      setMedia(propertyMedia)
      if (currentProperty.Id) {
        lazyGetAmenities({ variables: { PropertyId: currentProperty.Id } })
      }
    }
  }, [currentProperty, videos])

  if (currentProperty) {
    aboutDescription = aboutDescriptions.find(desc => desc.propertyName.toLowerCase().includes((currentProperty.PropertyName as string)?.toLowerCase()))?.description as string
  }

  const Image = styled('img')(() => ({}))

  const StyledCard = styled(Card)(({ theme }) => ({
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    },
    width: 'fit-content',
    height: '405px'
  }))

  const isLoading = loading || videosLoading || amenitiesLoading
  const bottom = (searchBarRef.current as HTMLDivElement)?.clientHeight / 2 || 60

  return (
    <Box sx={{ display: 'flex', flexDirection: "column", gap: theme => theme.spacing(2), position: isMobile ? 'relative' : 'initial', top: isMobile ? `${top}px` : 0 }}>
      <Navigation />
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2, height: '100%', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 2, width: '100%' }}>
          <Box sx={{
            width: '100vw',
            height: '530px',
            position: 'relative',
            overflow: 'hidden',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '0 !important',
            margin: '0 !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <video src={newVideo} autoPlay loop muted playsInline style={{ width: '100%', height: '100%', objectFit: 'cover', maxHeight: '530px', objectPosition: '50% 50%' }} />
            <Box sx={{ position: 'absolute', top: '25%', backgroundColor: 'rgba(0, 41, 110, 0.8)', '& > *': { color: 'white' }, width: 'fit-content', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: theme => theme.spacing(2) }}>
              <img src={nexusStarWhite} alt="Nexus Logo" style={{ width: '100px', height: '50px', objectFit: 'cover' }} />
              <Typography variant="h3" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                Find a Location
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                Select a location to learn more
              </Typography>
            </Box>
          </Box>
          <Box ref={searchBarRef} sx={{
            position: 'relative', alignSelf: 'center', bottom: `${bottom}px`, width: isMobile ? currentWidth * .8 : '760px',
            maxWidth: isMobile ? 'none' : '880px'
          }}>
            <SearchBar showSearch sx={{ borderRadius: '16px', padding: 4 }} />
          </Box>
          <Grid container sx={{ alignItems: 'center', width: '100%', justifyContent: 'space-between', padding: theme.spacing(2, 0), gap: 2 }}>
            {(properties?.getProperties as any[])?.map((property, index) => {
              const { PropertyName, City, State, AvailableSpaces } = property
              const googleLink = GoogleReviewPages.find(page => page.name.toLowerCase().includes(City.toLowerCase()))?.link
              let propertyImageUrl = ''
              const { miniDescription } = aboutDescriptions.find(desc => desc.propertyName.toLowerCase().includes(PropertyName.toLowerCase())) as any

              switch (PropertyName) {
                case 'Nexus at Boca Raton':
                  propertyImageUrl = BocaStockImg
                  break
                case 'Nexus at Boynton Beach':
                  propertyImageUrl = BoyntonStockImg
                  break
                case 'Nexus at Stuart':
                  propertyImageUrl = StuartStockImg
                  break
                case 'Nexus at Vista Park':
                  propertyImageUrl = VistaStockImg
                  break
                case 'Nexus at Wellington':
                  propertyImageUrl = WellingtonStockImg
                  break
                default:
                  break
              }

              return (
                <Grid item key={index} sx={{ alignItems: 'center', gap: 2 }} xs={12} sm={2.25}>
                  <StyledCard key={index} onClick={handlePropertyClicked} id={`property-${property.Id}`}>
                    <CardMedia component="img" src={propertyImageUrl} sx={{ height: '140px', width: '100%' }} />
                    <CardContent>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 1 }}>
                        <Typography variant="h6" sx={{ paddingBottom: 1, fontSize: '1rem', alignSelf: 'center', whiteSpace: 'nowrap' }}><strong>{PropertyName}</strong></Typography>
                        <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: 1, fontSize: '0.75rem' }}>
                          {miniDescription}
                        </Typography>
                        <Button variant="outlined" sx={{ borderRadius: '22.5px', fontSize: '0.75rem' }} onClick={handlePropertyClicked} id={`property-${property.Id}`}>Location Details</Button>
                      </Box>
                    </CardContent>
                  </StyledCard>
                </Grid>
              )
            })}
          </Grid>
        </Box>
        <>
          <Box sx={{ position: 'relative' }} component={'div'} id={currentProperty.City?.toLowerCase()} ref={searchRef}>
            <Watermark sx={{ left: '-270px', top: '40%' }} />
            <Grid container spacing={8} sx={{ height: '100%' }}>
              <Grid item xs={12} sm={6} sx={{ height: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 2, height: '100%' }}>
                  <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                    {currentProperty.PropertyName}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ marginBottom: 2, display: 'flex', flexDirection: 'column' }}>
                    <span>{currentProperty.AddressLine1}</span>
                    <span>{currentProperty.AddressLine2 && `, ${currentProperty.AddressLine2}`}</span>
                    <span>{currentProperty.City && `${currentProperty.City},`} {currentProperty.State && `${currentProperty.State}`} {currentProperty.Zip && `${currentProperty.Zip}`}</span>
                    {currentProperty?.Phone && <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.25, ':hover': { cursor: 'pointer' } }} onClick={() => { window.location.href = `tel:${currentProperty?.Phone}` }}><GroupsOutlined /> <strong>{formatPhoneNumber(currentProperty.Phone)}</strong></Box>}
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <SplitButton
                      primary={
                        <Button variant="contained" sx={{ borderRadius: '22.5px', ...(isMobile && { fontSize: '0.75rem' }) }} size={isMobile ? 'small' : 'medium'} onClick={() => navigate('/contact')}>
                          Contact a Specialist
                        </Button>}
                      secondary={
                        <Button startIcon={<img src={nexusStarBlue} width={31} height={22} alt="Nexus Logo" />}
                          onClick={() => { window.open(`https://93840cgimg.yardikube.com/kubecc/content/#/app/services/workspacelist?companyid=132&proid=${currentProperty.Id}`, '_self') }}
                          variant="outlined"
                          size={isMobile ? 'small' : 'medium'}
                          sx={{ borderRadius: '22.5px', 'span': { display: 'flex', marginBottom: '3px' }, ...(isMobile && { fontSize: '0.75rem' }) }}>
                          Book Now
                        </Button>}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 2 }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                      About {currentProperty.PropertyName}
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: 2 }}>
                      {aboutDescription}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                    {currentProperty.PropertyName} Operating Hours
                  </Typography>
                  <Box>
                    <Typography variant="h6">Hours of Operation:</Typography>
                    {(currentProperty.PropertyOperatingHours as PropertyOperatingHours[])?.map((hours: any) => {
                      let opening, closing
                      if (!hours.PeakStartTime && !hours.PeakEndTime) { }
                      else opening = format(hours.PeakStartTime, 'h:mm'), closing = format(hours.PeakEndTime, 'h:mm')
                      return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }} key={hours.Day}>
                          <ArrowRight />
                          <Typography key={`${hours.Day}-hours`} variant="subtitle1">
                            {hours.Day}: {!opening && !closing ? 'Closed' : `${opening} - ${closing}`}
                          </Typography>
                        </Box>
                      )
                    })}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ height: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 2, height: '100%' }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                    Take a sneak peek into our {currentProperty.PropertyName} location
                  </Typography>
                  <ImageList sx={{ height: '100%' }} cols={3}>
                    <ImageListItem sx={{ height: '100%' }}>
                      <video src={media?.url} preload="" style={{ height: '100%', width: '100%', objectFit: 'cover' }} autoPlay loop playsInline muted />
                    </ImageListItem>
                    {(currentProperty.PropertyGallery as PropertyGallery[])?.map((image, index) => (
                      <ImageListItem key={index}>
                        <img src={image.Url as string} alt="" />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {isLoading && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center', justifyContent: 'space-around', height: '100vh' }}>
              <CircularProgress variant="indeterminate" size={150} color="primary" />
            </Box>
          )}
          {!isLoading && (
            <Box sx={{ padding: theme.spacing(5, 0), display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center' }}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                Explore Our Amenities at {currentProperty.PropertyName}
              </Typography>
              <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
                <Table aria-label="amenities table" sx={{ 'thead > tr > th': { padding: '8px' }, 'tbody > tr > th': { padding: '8px' }, 'tbody > tr > td': { padding: '8px' } }}>
                  <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Amenity</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Pricing Type</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {amenitiesTableData?.map((amenity, index) => (
                      <TableRow key={amenity.Id} sx={{ backgroundColor: `${index % 2 === 0 ? '' : '#f5f5f5'}`, height: '45px !important' }}>
                        <TableCell component="th" scope="row">
                          {amenity.Name}
                        </TableCell>
                        <TableCell>
                          {amenity.PricingType === 'Quantity' ? 'Hourly Rate' : 'Flat Rate'}
                        </TableCell>
                        <TableCell>
                          ${amenity.PricingType === 'Quantity' ? amenity.UnitPrice : amenity.TotalPrice}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100vw',
        gap: 3,
        background: `url(${newsletterCTA}) 75% center no-repeat`,
        backgroundSize: 'cover',
        position: 'relative',
        left: ' 50%',
        transform: 'translateX(-50%)',
        padding: theme.spacing(4, 4, 8),
      }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          For all things Nexus, subscribe to our newsletter
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'center', maxWidth: '80%', marginBottom: 2 }}>
          Stay updated with the latest news, events, and special offers from Nexus. Enter your email below to subscribe.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, height: '100%' }}>
          <TextField label="Email" variant="outlined" />
          <Button variant="contained" color="primary">Subscribe</Button>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default Locations