import { useTheme } from "@emotion/react"
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, Divider, Drawer, Grid, IconButton, Link, List, ListItem, ListItemButton, ListItemText, Popover, Theme, Toolbar, Menu, Typography, styled, MenuItem, Icon, ListItemIcon } from "@mui/material"
import { ExpandMore, Phone, Menu as MenuIcon, PhoneInTalk, LocationCity, ContactPage, Lightbulb, Place, Info } from "@mui/icons-material"
import { motion } from 'framer-motion'
import { Link as RouterLink, useNavigate } from "react-router-dom"
import '../../assets/css/navigation.css'
import starLogo from '../../assets/images/nexus-star-logo.png'
import NexusStar from '../../assets/images/logo-transparency-07.png'
import useScreenSize from "../../react-hooks/screensize"
import { ChevronLeft } from "@mui/icons-material"
import { getProperties } from "../../gql/queries"
import { useQuery } from "@apollo/client"
import { Property, PropertyGallery } from "../../gql/graphql"
import { formatPhoneNumber } from "../../utils/string-util"
import { SplitButton } from "../common/SplitButton"

const Navigation = props => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [currentProperty, setCurrentProperty] = useState<{}>({})
  const theme = useTheme() as Theme
  const id = Boolean(anchorEl) ? 'phone-popover' : undefined
  const navRef = useRef<HTMLDivElement | null>(null)
  const phoneRef = useRef(null)
  const { xs, sm, md, lg, xl } = theme.breakpoints.values
  const navigate = useNavigate()
  const { data, loading, error } = useQuery(getProperties)
  const isOpen = Boolean(anchorEl)
  const backdropFilterClass = 'backdrop-filter'
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 })

  // Detect current screen width
  const screenSize = useScreenSize()

  // variables for breakpoint conditions
  const isXs = screenSize.width <= xs, isSm = screenSize.width <= sm, isMd = screenSize.width <= md, isLg = screenSize.width <= lg, isXl = screenSize.width <= xl
  const isMobile = isXs || isSm || isMd, isNonMobile = isLg || isXl

  const hoverOptions = {
    scale: 1.35
  }

  const LINKS = [
    { path: '/locations', title: 'Locations', icon: <Place sx={{ fontSize: '20px' }}  />},
    { path: '/solutions', title: 'Solutions', icon: <Lightbulb sx={{ fontSize: '20px' }}  />},
    { path: '/contact', title: 'Contact', icon: <ContactPage sx={{ fontSize: '20px' }}  />},
    { path: '/about', title: 'About', icon: <Info  sx={{ fontSize: '20px' }} />}
  ]

  const StyledDiv = styled(Box)(({ theme }) => ({
    display: 'flex',
  }))

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
    setAnchorPosition({ top: event.currentTarget.getBoundingClientRect().top, left: event.currentTarget.getBoundingClientRect().left })
    setPopoverOpen(true)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setPopoverOpen(false)
  }

  const onPropertyChange = (property: Property) => {
    let newPhone
    if (!(property?.Phone as string).length) newPhone = '+17724035800'
    setCurrentProperty({ ...property, tel: `tel:${newPhone || property.Phone}`, Phone: newPhone || property.Phone })
  }

  const signUp = () => window.location.assign('https://93840cgimg.yardikube.com/kubecc/content/#/app/registration?companyid=132&proid=4')
  const memberLogin = () => window.location.assign('https://93840cgimg.yardikube.com/kubecc/content/#/app/login?companyid=132&proid=1&fromLeftMenu=1')
  const motionLinks = (icon: any, linkTitle: string, linkAddress: string, index?: number) => (
    <StyledDiv key={`link-${index}`}>
      <motion.div whileHover={hoverOptions} key={index}>
        <RouterLink to={linkAddress}>
          <Typography variant="body2" sx={{ color: '#0000008a', textDecoration: 'none', display: 'flex', alignItems: 'center', }}>
            {/* <span style={{ display: 'flex', alignItems: 'center', color: theme.palette.secondary.main }}>{icon}</span> */}
            <strong>{linkTitle}</strong>
          </Typography>
        </RouterLink>
      </motion.div>
    </StyledDiv>
  )

  const DrawerMenu = () => (
    <Box id='nexus-nav-mobile'>
      <AppBar position="fixed" sx={{ backgroundColor: theme => theme.palette.secondary.main }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            <RouterLink to='/' style={{ display: 'flex' }}>
              <img src={starLogo} alt="Nexus Star Logo" style={{ alignSelf: 'center' }} height={32.5} width={35} />
            </RouterLink>
          </Typography>
          <IconButton
            aria-label="open drawer"
            size="large"
            edge="end"
            onClick={
              () => {
                setOpen(!open)
              }
            }
            sx={{ ...(open && { display: 'none' }), ':hover': { '& > *': { color: 'white' } }, backgroundColor: 'white' }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box role='presentation'>
        <Drawer variant="persistent" anchor="right" open={open} onClose={() => setOpen(false)} sx={{ [`& > *, button`]: { ":hover": { cursor: 'pointer' } } }}>
          <StyledDiv>
            <IconButton onClick={() => setOpen(false)}>
              <ChevronLeft />
            </IconButton>
          </StyledDiv>
          <Divider />
          <List>
            {LINKS.map((link, index) => (
              <ListItem key={index}>
                <ListItemButton LinkComponent={RouterLink} onClick={() => navigate(link.path)}>
                  <ListItemText primary={link.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            <ListItem key='Sign Up'>
              <ListItemButton onClick={signUp}>
                <ListItemText primary="Sign Up" />
              </ListItemButton>
            </ListItem>
            <ListItem key='Member Login'>
              <ListItemButton onClick={memberLogin}>
                <ListItemText primary="Member Login" />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </Box>
    </Box>
  )

  useEffect(() => {
    if (popoverOpen) {
      document.body.classList.add(backdropFilterClass)
    } else {
      document.body.classList.remove(backdropFilterClass)
    }
  }, [popoverOpen])

  useEffect(() => {
    if (data?.getProperties?.length) {
      const phoneNumbers = data.getProperties.map(property => {
        if (!(property?.Phone as string).length) return '+17724035800'
        else return property?.Phone
      })
      const tel = `tel:${phoneNumbers[0]}`
      setCurrentProperty({ ...data.getProperties[0], tel, Phone: phoneNumbers[0] })
    }
    setAnchorPosition({ top: anchorEl?.getBoundingClientRect().top || 0, left: anchorEl?.getBoundingClientRect().left || 0 })
  }, [data])

  if (isMobile) return <DrawerMenu />

  return (
    <StyledDiv sx={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '12px', ...props.sx }} ref={navRef}>
      <StyledDiv sx={{ alignItems: 'center' }} className="logo">
        <Link component={RouterLink} to='/' style={{ width: 'fit-content', height: 'fit-content' }}>
          <img src={NexusStar} alt="Nexus Home Page Link" style={{ height: '3rem', width: '10.75rem' }} />
        </Link>
      </StyledDiv>
      <StyledDiv sx={{ alignItems: 'center', justifyContent: 'flex-end', width: '100%', gap: '32px', marginRight: '24px' }}>
        {LINKS.map((link, index) => motionLinks(link.icon, link.title, link.path, index))}
      </StyledDiv>
      <StyledDiv sx={{ justifyContent: 'space-between', flexGrow: .25, alignContent: 'center', 'button': { borderRadius: '22.5px' } }} className="nav-signin-links">
        <StyledDiv sx={{ alignItems: 'center', gap: '1rem', width: 'max-content' }}>
          <Box>
            <div ref={phoneRef}>
              <IconButton
                aria-describedby={id}
                onClick={handlePopoverOpen}
                size={screenSize.width <= md ? 'small' : 'large'}
                aria-haspopup="true"
                aria-controls={isOpen ? id : undefined}
              >
                <Phone />
              </IconButton>
            </div>
            <Popover
              id={id}
              open={isOpen}
              onClose={handlePopoverClose}
              anchorEl={anchorEl}
              anchorReference="anchorPosition"
              anchorPosition={anchorPosition}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              elevation={1}
              sx={{
                marginTop: anchorEl ? `${(navRef.current as HTMLElement)?.clientHeight}px` : 0,
                'div.MuiPaper-root': {
                  boxShadow: '0px 0px 24px rgb(57 57 57 / 25%)',
                  borderRadius: '16px',
                  zIndex: 1,
                }
              }}
            >
              <Grid container spacing={4} sx={{ maxWidth: '800px', padding: '1rem', height: '100%', marginTop: 0 }}>
                <Grid item md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', borderRight: '1px solid #D7D7D7', position: 'relative', zIndex: 2 }}> {/* Set z-index to 2 */}
                  <Box sx={{ alignSelf: 'center', padding: theme => theme.spacing(1, 0) }}>
                    <img src={starLogo} alt="Nexus Star Logo" width={48} height={48} />
                  </Box>
                  <Typography variant="h5" noWrap>
                    <strong>Talk to a Nexus Specialist</strong>
                  </Typography>
                  <Typography variant="subtitle1" sx={{ lineHeight: '24px' }}>
                    We have a team of specialists available 24/7 to help you find the best solution for your business.
                  </Typography>
                  <RouterLink to={(currentProperty as any)?.tel} style={{ display: 'flex', gap: '4px', alignItems: 'center', textDecoration: 'none' }}>
                    <PhoneInTalk sx={{ color: 'black' }} />
                    <Typography variant="subtitle1" sx={{ color: 'black', fontWeight: 300, letterSpacing: '-0.4px' }}>
                      {(currentProperty as Property)?.Phone && formatPhoneNumber((currentProperty as Property)?.Phone as string)}
                    </Typography>
                  </RouterLink>
                </Grid>
                <Grid item md={6} sx={{ position: 'relative', zIndex: 2 }}> {/* Set z-index to 2 */}
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      {(currentProperty as Property)?.PropertyName}
                    </AccordionSummary>
                    <AccordionDetails>
                      {data?.getProperties?.map((property, index) => (
                        <div key={index}>
                          <Button onClick={() => onPropertyChange(property as Property)} sx={{ cursor: 'pointer' }}>
                            {(property as Property).PropertyName}
                          </Button>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                  <Box sx={{ padding: theme => theme.spacing(1.5, 0, 0) }}>
                    {(currentProperty as Property)?.PropertyGallery?.length && <img src={(currentProperty as Property)?.PropertyGallery?.[0]?.Url as string} width='100%' height='100%' />}
                  </Box>
                </Grid>
              </Grid>
            </Popover>
          </Box>
          <SplitButton primary={<Button variant="outlined" onClick={signUp}>Sign Up</Button>} secondary={<Button variant="contained" onClick={memberLogin}>Member Login</Button>} />
          {isMobile && (
            <Box>
              <IconButton
                aria-label="open drawer"
                edge="end"
                onClick={
                  () => {
                    setOpen(!open)
                  }
                }
                sx={{ ...(open && { display: 'none' }), ':hover': { '& > *': { color: theme.palette.secondary.main }, backgroundColor: theme.palette.primary.main }, backgroundColor: 'white', padding: theme.spacing(1) }}
              >
                <MenuIcon sx={{ fontSize: '2.25rem' }} />
              </IconButton>
              <Drawer variant="persistent" anchor="right" open={open} onClose={() => setOpen(false)} sx={{ [`& > *, button`]: { ":hover": { cursor: 'pointer' } } }}>
                <StyledDiv>
                  <IconButton onClick={() => setOpen(false)}>
                    <ChevronLeft />
                  </IconButton>
                </StyledDiv>
                <Divider />
                <List>
                  {LINKS.map((link, index) => (
                    <ListItem key={index}>
                      <ListItemButton LinkComponent={RouterLink} onClick={() => navigate(link.path)}>
                        <ListItemIcon>{link.icon}</ListItemIcon>
                        <ListItemText primary={link.title} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            </Box>
          )}
        </StyledDiv>
      </StyledDiv>
    </StyledDiv>
  )
}

export default Navigation