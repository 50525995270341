import { AccessTime, Apartment, Close, ExpandMore, Facebook, Instagram, LinkedIn, Phone, Twitter, YouTube } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, Input, InputLabel, Link, MenuItem, Select, SelectChangeEvent, TextField, Theme, Typography, useTheme } from '@mui/material'
import React, { FormEvent, useState } from 'react'
import { useParentProperties } from '../../AppContext'
import useScreenSize from '../../react-hooks/screensize'
import Layout from '../Layout'

import bgVideo from '../../assets/videos/contactuspage-hero.mp4'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { useParams, useSearchParams } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Property } from '../../gql/graphql'
import { getProperties, sendEmail } from '../../gql/queries'
import { formatPhoneNumber } from '../../utils/string-util'
import nexusStarWhite from '../../assets/images/logo-transparency-06.png'
import nexusStarBlue from '../../assets/images/logo-transparency-05.png'

const ContactUs = () => {
  const { width: currentWidth } = useScreenSize()
  const theme = useTheme() as Theme
  const { values } = theme.breakpoints
  const isMobile = currentWidth < values.md
  const [top, setTop] = useState(0)
  const [selected, setSelected] = useState('contact-form')
  const { parentHeights } = useParentProperties()
  const [params] = useSearchParams()
  const { data, error } = useQuery(getProperties)
  const properties = data?.getProperties as Property[] || []
  const [currentProperty, setCurrentProperty] = useState<Property>({})
  const [expanded, setExpanded] = useState<boolean>(false)
  const [showModal, setShowModal] = useState(false)

  const containerStyles = {
    marginTop: theme => theme.spacing(3),
    marginBottom: theme => theme.spacing(4),
  }
  const titleStyles = {
    marginBottom: theme => theme.spacing(2),
    color: 'white'
  }
  const formStyles = {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    padding: theme => theme.spacing(3, 0),
  }
  const textFieldStyles = {
    marginBottom: theme => theme.spacing(2),
  }
  const submitButtonStyles = {
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.primary.light,
    color: 'white'
  }
  const layoutStyles = {
    height: '100%',
    position: isMobile ? 'relative' : 'initial', 
    top: isMobile ? `${top}px` : 0
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (properties.length) {
      setCurrentProperty(properties[0])
    }
  }, [data])

  useEffect(() => {
    if (parentHeights['nexus-nav-mobile'] && isMobile) setTop(parentHeights['nexus-nav-mobile'])
  }, [])

  useEffect(() => {
    // TODO: Remove this when the phone number is added to the Yardi Kube
    if (!(currentProperty?.Phone as string)?.length && currentProperty.PropertyName === 'Nexus at Stuart') {
      const property = { ...currentProperty, Phone: '+17724035800' }
      setCurrentProperty(property)
    }
  }, [currentProperty])

  useEffect(() => {
    if (params.size && params.get('form')) setSelected(params.get('form') as string)
  }, [params])

  const divStyles = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  } as any

  const overlayStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.7)',
  } as any

  const ContactForm = () => {
    const [formData, setFormData] = useState({
      fullName: '',
      companyName: '',
      phoneNumber: '',
      emailAddress: '',
      location: '',
      interestedWorkSpace: '',
      preferredTourDate: '',
      preferredTourTime: '',
    })
    const [errors, setErrors] = useState({
      fullName: '',
      companyName: '',
      phoneNumber: '',
      emailAddress: '',
      interestedWorkSpace: '',
      preferredTourDate: '',
      preferredTourTime: '',
    })
    const [sendEmailQuery, { data: emailData, loading, error: emailError }] = useLazyQuery(sendEmail)

    const handleSubmit = (event: FormEvent) => {
      event.preventDefault()
      if (Object.values(errors).some(error => !!error)) return
      const { fullName, companyName, phoneNumber, emailAddress, location, interestedWorkSpace, preferredTourDate, preferredTourTime } = formData
      sendEmailQuery({
        variables: {
          fullName,
          email: emailAddress,
          phone: phoneNumber,
          companyName,
          location,
          interestedWorkspace: interestedWorkSpace || '',
          preferredTourDate: preferredTourDate || '',
          preferredTourTime: preferredTourTime || '',
        }
      })
    }

    const validateField = (name, value) => {
      let error = ''
      switch (name) {
        case 'fullName':
          if (!value) error = 'Full Name is required'
          break
        case 'phoneNumber':
          if (!value) 'Phone Number is required'
          else if (!/^\d{10}$/.test(value)) error = 'Phone Number must be 10 digits'
          break
        case 'emailAddress':
          if (!value) 'Email Address is required'
          else if (!/^\S+@\S+\.\S+$/.test(value)) error = 'Email Address is invalid'
          break
        default:
          return ''
      }
      setErrors(prev => ({ ...prev, [name]: error }))
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }))
      validateField(name, value)
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
      const { name, value } = event.target
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name as string]: value,
      }))
    }

    useEffect(() => {
      if (emailData) {
        setShowModal(true)
        setFormData({
          fullName: '',
          companyName: '',
          phoneNumber: '',
          emailAddress: '',
          location: '',
          interestedWorkSpace: '',
          preferredTourDate: '',
          preferredTourTime: '',
        })
      }
    }, [emailData])

    return (
      <>
        <Typography variant="h5" sx={{ display: 'flex', justifyContent: 'space-between', padding: theme.spacing(2, 0), alignItems: 'center' }}>
          Interested in Nexus?
          <FormControl sx={{ '& > *': { color: 'white !important' }, '.MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, 'svg': { color: 'white' } }}>
            <InputLabel id='contact-forms'>Nexus Forms</InputLabel>
            <Select
              labelId='contact-forms'
              id='select-contact-forms'
              value={selected}
              label='Nexus Forms'
              onChange={(event) => setSelected(event.target.value as string)}
              sx={{ borderColor: 'white' }}
            >
              <MenuItem value={'contact-form'}>Contact Us Form</MenuItem>
              <MenuItem value={'schedule-tour-form'}>Schedule a Tour Form</MenuItem>
            </Select>
          </FormControl>
        </Typography>
        <Typography variant="subtitle2">
          Choose between our Contact Us form or Schedule a Tour form. <br />
          Afterward, fill out the form below and a member of our team will reach out to learn more about your workspace needs.
        </Typography>
        <Box component='form' sx={formStyles} onSubmit={handleSubmit}>
          <TextField
            label="Full Name"
            variant="outlined"
            sx={textFieldStyles}
            required
            name="fullName"
            value={formData.fullName}
            onChange={handleInputChange}
            fullWidth
            error={!!errors.fullName}
            helperText={errors.fullName}
          />
          <TextField
            label="Email Address"
            variant="outlined"
            sx={textFieldStyles}
            required
            name="emailAddress"
            value={formData.emailAddress}
            onChange={handleInputChange}
            fullWidth
            error={!!errors.emailAddress}
            helperText={errors.emailAddress}
          />
          <TextField
            label="Company Name"
            variant="outlined"
            sx={textFieldStyles}
            name="companyName"
            value={formData.companyName}
            onChange={handleInputChange}
            fullWidth
            error={!!errors.companyName}
            helperText={errors.companyName}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            sx={textFieldStyles}
            required
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            fullWidth
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
          />
          <FormControl sx={{ '& > *': { color: 'white !important' }, '.MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, 'svg': { color: 'white' } }}>
            <InputLabel id='location'>Location</InputLabel>
            <Select
              label="Location"
              name="location"
              variant="outlined"
              sx={{ borderColor: 'white', ...textFieldStyles }}
              required
              fullWidth
              value={formData.location}
              onChange={handleSelectChange}
            >
              {properties.map((property, index) => (
                <MenuItem key={index} value={property.PropertyName as string}>{property.PropertyName}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {
            selected === 'contact-form' &&
            <FormControl sx={{ '& > *': { color: 'white !important' }, '.MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, 'svg': { color: 'white' } }}>
              <InputLabel id='interested-workspace'>Interested in Workspace</InputLabel>
              <Select
                label="Interested in Workspace"
                variant="outlined"
                sx={{ borderColor: 'white', ...textFieldStyles }}
                required
                name="interestedWorkSpace"
                value={formData.interestedWorkSpace}
                onChange={handleSelectChange}
                fullWidth
                placeholder='Interested in Workspace'
              >
                <MenuItem value="Private Office">Private Office</MenuItem>
                <MenuItem value="Dedicated Desk">Dedicated Desk</MenuItem>
                <MenuItem value="Meeting Space">Meeting Room</MenuItem>
                <MenuItem value="Event Space">Event Space</MenuItem>
                <MenuItem value="Virtual Office">Virtual Office</MenuItem>
              </Select></FormControl>

          }
          {selected === 'schedule-tour-form' &&
            <>
              <DatePicker
                label="Preferred Tour Date"
                sx={textFieldStyles}
                onAccept={(date: any) => setFormData({ ...formData, preferredTourDate: date })}
              />
              <TimePicker
                label="Preferred Tour Time"
                sx={textFieldStyles}
                onAccept={(date: any) => setFormData({ ...formData, preferredTourTime: date })}
              />
            </>}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={submitButtonStyles}
            disabled={Object.values(errors).some(error => !!error) || !(formData.fullName !== '' && formData.emailAddress !== '' && formData.phoneNumber !== '')}
            endIcon={!loading && <img src={nexusStarWhite} alt="Nexus Star" width={35} height={25} />}
          >
            {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Submit'}
          </Button>
        </Box>
      </>
    )
  }

  return (
    <Layout sx={{ ...layoutStyles }}>
      <Box sx={{
        width: '100vw',
        height: '530px',
        position: 'relative',
        overflow: 'hidden',
        alignItems: 'center',
        left: '50%',
        transform: 'translateX(-50%)',
      }}>
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <video
            src={bgVideo}
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '530px',
              objectFit: 'cover',
            }}
          />
          <Box sx={{
            backgroundColor: 'rgba(0, 41, 110, 0.8)', '& > *': { color: 'white' }, padding: theme => theme.spacing(2), display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: '32.5%', width: 'fit-content'
          }}>
            <img src={nexusStarWhite} alt="Nexus Star" style={{ width: '120px', height: 'auto', margin: '0 auto' }} />
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>Get in Touch</Typography>
            <Typography variant="h6"></Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{
        // // backgroundImage: `url(${backgroundImg})`,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        // backgroundRepeat: 'no-repeat',
        backgroundColor: '#A0A0A8',
        width: '100vw',
        maxHeight: 'max-content',
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
        paddingBottom: isMobile ? '150px' : '150px',
        paddingRight: isMobile ? '32px' : '65.5px',
        paddingLeft: isMobile ? '32px' : '65.5px',
      }}>
        {/* <div style={divStyles}>
          <div style={overlayStyles} />
        </div> */}
        <Box sx={{ paddingTop: 1, position: 'relative', zIndex: 2 }}>
          <Grid container spacing={6} sx={containerStyles}>
            <Grid item xs={12} sm={6} sx={{ padding: theme => theme.spacing(0, 4, 4, 0), '& > *': { color: 'white' }, 'a': { color: 'white' } }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.75 }}>
                  <Typography variant="h4">
                    Have questions?
                  </Typography>
                  <Typography variant="h6">
                    Questions about Nexus? We're here to help. Just have your people reach out to our people.
                  </Typography>
                  {!!properties.length && (
                    <Accordion expanded={expanded} sx={{ width: isMobile ? '100%' : '60%' }}>
                      <AccordionSummary expandIcon={<ExpandMore />} onClick={() => setExpanded(!expanded)}>
                        {currentProperty?.PropertyName}
                      </AccordionSummary>
                      <AccordionDetails>
                        {properties?.map((property, index) => (
                          <div key={index}>
                            <Button onClick={() => { setCurrentProperty(property as Property); setExpanded(!expanded) }} sx={{ cursor: 'pointer' }}>
                              {(property as Property).PropertyName}
                            </Button>
                          </div>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {!!Object.keys(currentProperty).length && (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ display: 'flex', gap: '1rem', paddingTop: '0.5rem' }}>
                        <Apartment />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography variant='subtitle1'><span>{currentProperty?.AddressLine1}</span></Typography>
                          {currentProperty?.AddressLine2 && <Typography variant='subtitle1'><span>{currentProperty?.AddressLine2}</span></Typography>}
                          <Typography variant='subtitle1'><span>{currentProperty?.City}, {currentProperty?.State} {currentProperty?.Zip}</span></Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', gap: '1rem', paddingTop: '0.5rem' }}>
                        <Phone />
                        <Typography data-testid='phone'>{formatPhoneNumber(currentProperty?.Phone as string)}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', gap: '1rem', paddingTop: '0.5rem' }}>
                        <AccessTime />
                        <Typography variant='subtitle1'>9am - 5pm EST</Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box>
                  <Typography variant='subtitle1'>
                    Already a member?
                  </Typography>
                  <Typography variant='subtitle2'>
                    If you already have a Nexus member, you can submit billing, account, and support requests <Link style={{ textDecoration: 'underline' }} href="/">here</Link>.
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='subtitle1'>
                    More questions?
                  </Typography>
                  <Typography variant='subtitle2'>
                    Email us at <Link style={{ textDecoration: 'underline' }} href="mailto:info@thenexusfl.com">info@nexusworkspaces.com</Link>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: isMobile ? 2 : 4, '& > button': { color: 'white', ':hover': { color: theme.palette.primary.light } }, 'svg': { fontSize: '2.5rem' } }}>
                  <IconButton onClick={() => window.location.assign('https://www.facebook.com/nexusworkspaces')}>
                    <Facebook />
                  </IconButton>
                  <IconButton onClick={() => window.location.assign('https://instagram.com/nexusworkspaces')}>
                    <Instagram />
                  </IconButton>
                  <IconButton onClick={() => window.location.assign('https://www.linkedin.com/company/nexusworkspaces')}>
                    <LinkedIn />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ padding: theme => theme.spacing(4), backgroundColor: '#c3c3c35e', '& > *': { color: 'white !important' }, 'label': { color: 'white' }, borderRadius: '22.5px' }}>
              <ContactForm />
            </Grid>
          </Grid>
          <Dialog
            open={showModal}
            onClose={() => setShowModal(false)}
            sx={{
              '& .MuiDialogContent-root': {
                padding: theme.spacing(2),
              },
              '& .MuiDialogActions-root': {
                padding: theme.spacing(1),
              }
            }}
          >
            <DialogTitle id="modal-modal-title">
              <Typography id="modal-modal-title" variant="h5">
                Inquiry Sent
              </Typography>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setShowModal(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
            <DialogContent>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                We've receieved your inquiry and will get back to you as soon as possible. Thank you for reaching out to Nexus!
              </Typography>
            </DialogContent>
          </Dialog>
        </Box>
      </Box>
    </Layout>
  )
}

export default ContactUs