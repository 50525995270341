import { Box } from '@mui/material'
import starLogoPng from '../../assets/images/logo-transparency-05.png'

export const Watermark = ({ sx = {} }) => {
  const Watermark = styled(Box)(() => ({
    position: 'absolute',
    top: '20%',
    right: '-200px', // adjust this value as needed
    transform: 'translateY(-50%)',
    width: '500px',
    height: '500px',
    opacity: 0.15, // adjust the opacity for desired transparency
    zIndex: -10,
    pointerEvents: 'none', // ensure the watermark doesn't interfere with content
    backgroundImage: `url(${starLogoPng})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    ...sx
  }))

  return <Watermark />
}